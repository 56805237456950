import React, {lazy, Suspense} from 'react'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import AppErrorBoundary from './AppErrorBoundary'
import Alert from './Alert'
import Dashboard from './Dashboard'
import Mypage from './Mypage'
import NoMatch from './NoMatch'
import Report from './Report'
import Plant from './Plant'
import Simulation from './Simulation'
import Theme from './Theme'
import UserGroup from './UserGroup'
import AlertCodeList from './alerts/codes/List'
import ForgotPassword from './auth/ForgotPassword'
import SignIn from './auth/SignIn'
import SignUp from './auth/SignUp'
import Verify from './auth/Verify'
import PrivateRoute from './auth/PrivateRoute'
import Loading from './ui/Loading'
import TrackableRoute from './ui/TrackableRoute'
import styles from '../styles/App.less'

const App = () => {
  return (
    <Router>
      <Switch>
        <TrackableRoute exact path="/forgot-password">
          <ForgotPassword/>
        </TrackableRoute>
        <TrackableRoute exact path="/signin">
          <SignIn/>
        </TrackableRoute>
        <TrackableRoute exact path="/signup">
          <SignUp/>
        </TrackableRoute>
        <TrackableRoute exact path="/thanks">
          <SignIn/>
        </TrackableRoute>
        <TrackableRoute exact path="/verify">
          <Verify/>
        </TrackableRoute>
        <PrivateRoute exact path="/">
          <Dashboard/>
        </PrivateRoute>
        <PrivateRoute exact path="/reports">
          <Report/>
        </PrivateRoute>
        <PrivateRoute path="/alert*">
          <Alert/>
        </PrivateRoute>
        <PrivateRoute path="/plants">
          <Plant/>
        </PrivateRoute>
        <PrivateRoute path="/user-groups">
          <UserGroup/>
        </PrivateRoute>
        <PrivateRoute exact path="/mypage">
          <Mypage/>
        </PrivateRoute>
        {/*<PrivateRoute path="/simulations">
          <Simulation/>
        </PrivateRoute>*/}
        <PrivateRoute exact path="/admin/alert-codes">
          <AlertCodeList/>
        </PrivateRoute>
        <TrackableRoute path="*">
          <NoMatch />
        </TrackableRoute>
      </Switch>
    </Router>
  )
}

export default () => {
  return (
    <AppErrorBoundary>
      <Suspense fallback={<Loading/>}>
        <Theme>
          <App/>
        </Theme>
      </Suspense>
    </AppErrorBoundary>
  )
}
