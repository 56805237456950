import React from 'react'
import {Link} from 'react-router-dom'
import styled, {useTheme} from 'styled-components'

const ButtonLink = styled(Link)`
  background-color: ${props => props.color ? props.color : props.theme.palette.primary.main};
  border: none;
  border-radius: ${props => props.theme.radius[2]};
  color: ${props => props.color ? props.color : '#FFFFFF'};
  cursor: pointer;
  display: block;
  font-size: ${props => props.fontSize ? props.theme.fontSize[props.fontSize] : props.theme.fontSize.md};
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  &:active, &:focus {
    outline: none;
  }
  // &:disabled {
  //   background-color: lighten(@bgBlue, 30%);
  // }
`

const _ButtonLink = ({children, ...props}) => {
  const theme = useTheme()

  return (
    <ButtonLink theme={theme} {...props}>{children}</ButtonLink>
  )
}

export default _ButtonLink
