import React from 'react'
import {Helmet} from 'react-helmet'

const Head = ({children, title}) => {
  return (
    <Helmet
      defaultTitle="まとめてソーラー（β ver.）"
      titleTemplate="%s - まとめてソーラー（β ver.）"
    >
      {title && <title>{title}</title>}    
      {children}
    </Helmet>
  )
}

export default Head
      