import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SignInWithApple from "./SignInWithApple";
import SignInWithFacebook from "./SignInWithFacebook";
import SignInWithGoogle from "./SignInWithGoogle";
import styled from "styled-components";
import SignInWithYahoo from "./SignInWithYahoo";
import SignInWithEmailOpened from "./SignInWithEmailOpened";

const PAGE_TYPE_SIGNIN = "signin";
const PAGE_TYPE_THANKS = "thanks";

const SignIn = () => {
  const history = useHistory();
  const { pathname, state } = useLocation();

  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSocialLoginOpen, setIsSocialLoginOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [pageType, setPageType] = useState();
  const [title, setTitle] = useState();

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage();
    setIsLoading(true);

    try {
      const r = await API.post("solsell", `v1/users/migrate`, {
        body: formValues,
        headers: { "Content-Type": "application/json" },
        response: true,
      });

      const user = await Auth.signIn(formValues.email, formValues.password);

      const next =
        state && state.hasOwnProperty("next") ? state.next : { pathname: "/" };
      return history.replace(next);
    } catch (err) {
      if (err.code == "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        setErrorMessage(
          "ログインできませんでした。パスワードをご確認ください。"
        );
      } else if (err.code == "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        setErrorMessage(
          "ログインできませんでした。ユーザ名とパスワードをご確認ください。"
        );
      } else if (err.code == "UserNotConfirmedException") {
        history.replace("/verify");
        return;
      } else {
        const msg = err.hasOwnProperty("message") ? err.message : err;
        setErrorMessage(
          `エラーが発生しました。システム担当にお問い合わせください。Error: ${msg}`
        );
      }
    }

    setIsLoading(false);
  };

  const handleToggleSocialLoggin = (e) => {
    setIsSocialLoginOpen(!isSocialLoginOpen);
  };

  useEffect(() => {
    if (state && state.hasOwnProperty("error")) {
      setErrorMessage(state.error);
    }

    if (
      window.location.search.indexOf(
        "error_description=Already+found+an+entry+for+username"
      ) != -1
    ) {
      setErrorMessage("初回の Google は再度ログインボタンを押下くださいませ。");
    }
  }, []);

  useEffect(() => {
    if (pathname == "/thanks") {
      setPageType(PAGE_TYPE_THANKS);
      setTitle("ご登録ありがとうございます！！");
    } else {
      setPageType(PAGE_TYPE_SIGNIN);
      setTitle("サインイン");
    }
  }, [pathname]);

  return (
    <Container>
      <LoginForm>
        <Heading>ログイン</Heading>
        <SignInWithEmailOpened />
        <BottomCtn>
          <LeftItem>
            <Link to="/signup">新規のご登録はこちら</Link>
          </LeftItem>
          <RightItem>
            <Link to="/forgot-password">パスワードをお忘れの方</Link>
          </RightItem>
        </BottomCtn>
        <SocialLoginToggler
          $isOpen={isSocialLoginOpen}
          onClick={handleToggleSocialLoggin}
        >
          <SocialLoginTogglerHeadline>
            その他のサービスでログイン
          </SocialLoginTogglerHeadline>
          {isSocialLoginOpen && (
            <>
              <SignInWithGoogle />
              <SignInWithApple />
            </>
          )}
        </SocialLoginToggler>
        {/*
          <SignInWithFacebook />
          <SignInWithYahoo />
        */}
      </LoginForm>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const LoginForm = styled.div`
  margin: 125px auto;
  width: 350px;
`;

const Heading = styled.h2`
  font-size; 16px;
  text-align: center;
  margin-bottom: 12px;
`;

const SectionHeading = styled.h3`
  color: #858585;
  margin-top: 25px;
`;

const BottomCtn = styled.div`
  display: table;
  margin-bottom: 48px;
  margin-top: 24px;
  width: 100%;
`;

const LeftItem = styled.p`
  display: table-cell;
  text-align: center;
`;

const RightItem = styled.p`
  display: table-cell;
  text-align: center;
`;

const socialLoginTogglerWidth = 16;
const SocialLoginToggler = styled.div`
  border: solid 2px #d2d2d7;
  border-radius: 3px;
  cursor: pointer;
  padding: 20px;
  position: relative;
  &:after,
  &:before {
    content: "";
    display: block;
    height: ${socialLoginTogglerWidth}px;
    position: absolute;
    top: 28px;
    width: ${socialLoginTogglerWidth}px;
  }
  &:after {
    ${(props) =>
      props.$isOpen === true
        ? `
      `
        : `
        border-top: solid 2px #000000;
        right: ${socialLoginTogglerWidth * 1.5}px;
        transform: rotate(90deg);
        margin-top: -${socialLoginTogglerWidth / 2}px;
      `}
  }
  &:before {
    border-top: solid 2px #000000;
    right: ${socialLoginTogglerWidth}px;
  }
`;

const SocialLoginTogglerHeadline = styled.h3`
  color: #858585;
  margin: 0;
`;

export default SignIn;
