// import {API, Auth} from 'aws-amplify'
import React, {Component} from 'react'
import CenterizedText from './ui/CenterizedText'


class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <CenterizedText>エラーが発生しました。</CenterizedText>
    }

    return this.props.children 
  }
}

export default AppErrorBoundary
