import {API, Auth} from 'aws-amplify'
import React, {Fragment, useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import NoMatch from './NoMatch'
import FilterMenuUserGroup from './ui/FilterMenuUserGroup'
import ContentContainer from './ui/ContentContainer'
import Head from './ui/Head'
import Create from './user-groups/Create'
import Detail from './user-groups/Detail'
import {STATUS_ACCEPTED} from '../constants/user-groups'

const UserGroup = () => {
  const [id, setId] = useState()
  const [userGroups, setUserGroups] = useState()
  const [usersMap, setUsersMap] = useState()
  const [userSub, setUserSub] = useState()

  const listUserGroups = async () => {
    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()
      const sub = payload.sub
      const username = payload['cognito:username']
      setUserSub(username)

      const headers = {Authorization: jwtToken}
      const queryStringParameters = {
        fields: ['id', 'user_sub_from', 'user_sub_to'],
        limit: -1,
        status: STATUS_ACCEPTED
      }
      const r = await API.get('solsell', 'v0/user-groups', {headers, queryStringParameters})
      setUserGroups(r.data)

      let added = []
      let promises = []
      r.data.map(item => {
        ['user_sub_from', 'user_sub_to'].map(k => {
          if(added.indexOf(item[k]) === -1) {
            added.push(item[k])
            promises.push(API.get('solsell', `v1/users/${item[k]}`, {headers}))
          }
        })
      })

      const users = await Promise.all(promises)
      let um = {}
      users.map(item => {
        um[item.user_sub] = item
      })
      setUsersMap(um)
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    listUserGroups()
  }, [])

  return (
    <Fragment>
      <Head>
        <title>アカウント連携</title>
      </Head>
      <FilterMenuUserGroup
        id={id}
        userGroups={userGroups}
        usersMap={usersMap}
        userSub={userSub}
      />
      <ContentContainer isUserGroup>
        <Switch>
          <Route exact path="/user-groups">
            <Redirect to="/user-groups/create"/>
          </Route>
          <Route exact path="/user-groups/create">
            <Create setId={setId}/>
          </Route>
          <Route exact path="/user-groups/:id">
            <Detail
              setId={setId}
              userGroups={userGroups}
              usersMap={usersMap}
              userSub={userSub}
            />
          </Route>
          <Route path="*">
            <NoMatch/>
          </Route>
        </Switch>
      </ContentContainer>
    </Fragment>
  )
}

export default UserGroup
