import React, {Fragment, useEffect, useState} from 'react'
import {Link, Route, Switch, useLocation} from 'react-router-dom'
import NoMatch from './NoMatch'
import ConfirmLine from './alert-notification-destinations/ConfirmLine'
import List from './alert-notification-destinations/List'
import AlertList from './alerts/List'
import ContentContainer from './ui/ContentContainer'
import FilterMenuPlant from './ui/FilterMenuPlant'
import Head from './ui/Head'
import Panel from './ui/Panel'
import styles from '../styles/Alert.less'

const Alert = ({}) => {
  const {pathname, search} = useLocation()

  const [plantId, setPlantId] = useState()
  const [plants, setPlants] = useState()
  const [plantsMap, setPlantsMap] = useState()
  const [tabIndex, setTabIndex] = useState(
    pathname == '/alerts/notification-destinations' ? 1 : 0
  )

  const handleClickTabIndex = index => e => {
    if(index == 1) {
      setPlantId(undefined)
    }
    setTabIndex(index)
  }

  const handleLoadPlantList = items => {
    setPlants(items)

    let pm = {}
    items.map(item => {
      pm[item.id.toString()] = item
    })

    setPlantsMap(pm)
  }

  return (
    <Fragment>
      <FilterMenuPlant
        id={plantId}
        isAlert
        onLoadList={handleLoadPlantList}
      />
      <ContentContainer>
        <Panel>
          <ul className={styles.tab}>
            <li className={tabIndex == 0 ? styles.active : ''}>
              <Link
                onClick={handleClickTabIndex(0)}
                to="/alerts"
              >
                アラート検索
              </Link>
            </li>
            <li className={tabIndex == 1 ? styles.active : ''}>
              <Link
                onClick={handleClickTabIndex(1)}
                to="/alerts/notification-destinations"
              >
                アラート通知設定
              </Link>
            </li>
          </ul>
          <Switch>
            <Route exact path="/alerts">
              <AlertList plantsMap={plantsMap} onChangePlantId={plantId => setPlantId(plantId)}/>
            </Route>
            <Route exact path="/alerts/notification-destinations/confirm-line">
              <ConfirmLine/>
            </Route>
            <Route exact path="/alerts/notification-destinations">
              <List/>
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Panel>
      </ContentContainer>
    </Fragment>
  )
}

export default Alert
