import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: #FFFFFF;
  border: ${props => `solid ${1 / props.theme.vw}vw ${props.theme.palette.default.main}}`};
  border-radius: 50%;
  cursor: pointer;
  height: ${props => `${50 / props.theme.vw}vw`};
  position: relative;
  width: ${props => `${50 / props.theme.vw}vw`};
`

const Inner = styled.div`
  border-right: ${props => `solid ${3 / props.theme.vw}vw ${props.theme.palette.default.dark}}`};
  border-bottom: ${props => `solid ${3 / props.theme.vw}vw ${props.theme.palette.default.dark}}`};
  height: ${props => `${20 / props.theme.vw}vw`};
  left: 50%;
  margin-left: ${props => `${-11.5 / props.theme.vw}vw`};
  margin-top: ${props => `${-14 / props.theme.vw}vw`};
  position: absolute;
  transform: rotate(45deg);
  top: 50%;
  width: ${props => `${20 / props.theme.vw}vw`};
`

const LoadMore = ({onClick}) => {
  return (
    <Container onClick={onClick}>
      <Inner/>
      <Inner/>
    </Container>
  )
}

export default LoadMore
