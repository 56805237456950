export const getUnitPriceModalClosed = () => {
  if(!storageAvailable('localStorage')) {
    return false
  }
  
  return localStorage.getItem('unitPriceClosed') != null
}

const storageAvailable = type => {
  let storage
  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  }
  catch(e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0
    )
  }
}

export const updateUnitPriceModalClosed = closed => {
  const isStorageAvailable = storageAvailable('localStorage')

  if(isStorageAvailable) {
    if(closed === true) {
      localStorage.setItem('unitPriceClosed', 'true')
    }
    else {
      localStorage.removeItem('unitPriceClosed')
    }
  }
}
