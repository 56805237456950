import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import React, {useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import Wrapper from './Wrapper'
import Message from '../ui/Message'
import styles from '../../styles/auth/Verify.less'

const Verify = () => {
  const history = useHistory()
  const {pathname, state} = useLocation()

  const [errorMessage, setErrorMessage] = useState(
    'ご登録のメールアドレスに送信されたコードをご入力ください。'
  )
  const [errorMessageResend, setErrorMessageResend] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    email: (state && state.email) ? state.email : '', code: ''
  })
  const [successMessageResend, setSuccessMessageResend] = useState()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setErrorMessage()
    setIsLoading(true)
    
    try {
      // await Auth.confirmSignUp(formValues.email, formValues.code);

      const headers = {'Content-Type': 'application/json'}
      const r = await API.post('solsell', 'v1/users/verify', {
        body: formValues,
        headers,
        response: true,
      })

      history.push({
        pathname: '/thanks', 
        // state: {error: 'お手数ですが、再度ログインしてください。'}
      })
      return 
    }
    catch (err) {
      if(err.hasOwnProperty('response')) {
        const {response: {data: {error}}} = err

        if(error.hasOwnProperty('Code')) {
          const {Code, Message} = error

          if(Code == 'NotAuthorizedException') {
            setErrorMessage('権限がありません。')
          }
          else if(Code == 'UserNotFoundException') {
            setErrorMessage('ユーザ名が存在しません。')
          }
          else if(Code == 'CodeMismatchException') {
            setErrorMessage('不正な認証コードです。メールをご確認ください。')
          }
          else if(Code == 'ExpiredCodeException') {
            setErrorMessage('認証コードが期限切れです。システム担当にお問い合わせください。')
          }
          else {
            setErrorMessage(`エラーが発生しました。システム担当にお問い合わせください。Error: ${Message}`)
          }
        }
        else {
          setErrorMessage(error)
        }
      }
      else {
        const msg = err.hasOwnProperty('message') ? err.message : err
        setErrorMessage(`エラーが発生しました。システム担当にお問い合わせください。Error: ${msg}`)
      }
    }

    setIsLoading(false)
  }

  const handleSubmitResend = async e => {
    e.preventDefault()

    setErrorMessage()
    setErrorMessageResend()
    setSuccessMessageResend()
    setIsLoading(true)
    
    try {
      // await Auth.resendSignUp(formValues.email)
      const headers = {'Content-Type': 'application/json'}
      const r = await API.post('solsell', 'v1/users/resend-code', {
        body: formValues,
        headers,
        response: true,
      })
      setSuccessMessageResend('コードを再送しました。メールをご確認いただき、上部フォームからご送信ください。')
    }
    catch (err) {
      if(err.hasOwnProperty('response')) {
        const {response: {data: {error}}} = err

        if(error.hasOwnProperty('Code')) {
          const {Code, Message} = error

          if(Code == 'NotAuthorizedException') {
            setErrorMessage('権限がありません。')
          }
          else if(Code == 'UserNotFoundException') {
            setErrorMessage('ユーザ名が存在しません。')
          }
          else if(Code == 'CodeMismatchException') {
            setErrorMessage('不正な認証コードです。メールをご確認ください。')
          }
          else if(Code == 'ExpiredCodeException') {
            setErrorMessage('認証コードが期限切れです。システム担当にお問い合わせください。')
          }
          else {
            setErrorMessage(`エラーが発生しました。システム担当にお問い合わせください。Error: ${Message}`)
          }
        }
        else {
          setErrorMessage(error)
        }
      }
      else {
        const msg = err.hasOwnProperty('message') ? err.message : err
        setErrorMessage(`エラーが発生しました。システム担当にお問い合わせください。Error: ${msg}`)
      }
    }

    setIsLoading(false)
  }


  return (
    <Wrapper errorMessage={errorMessage} title="メールアドレス確認">
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label}>メールアドレス</label>
          <div className={styles.inputCtn}>
            <input
              disabled={isLoading}
              name="email"
              onChange={handleChange}
              type="email"
              value={formValues.email}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>コード</label>
          <div className={styles.inputCtn}>
            <input
              disabled={isLoading}
              name="code"
              onChange={handleChange}
              type="text"
              value={formValues.code}
            />
          </div>
        </div>
        <button className={styles.btn} disabled={isLoading} type="submit">確認</button>
        <Link className={styles.link} to="/signup">ご登録はこちら</Link>
      </form>

      <form onSubmit={handleSubmitResend}>
        <div className={styles.formGroup}>
          <label className={styles.label}>コードが届かない場合や、期限切れしている場合は、以下から再送できます。</label>
        </div>
        {errorMessageResend &&
          <Message message={errorMessageResend}/>
        }
        {successMessageResend &&
          <Message message={successMessageResend} typ="success"/>
        }
        <div className={styles.formGroup}>
          <label className={styles.label}>メールアドレス</label>
          <div className={styles.inputCtn}>
            <input
              disabled={isLoading}
              name="email"
              onChange={handleChange}
              type="email"
              value={formValues.email}
            />
          </div>
        </div>
        <button className={styles.btn} disabled={isLoading} type="submit">コードを再送する</button>
      </form>
    </Wrapper>
  )
}

export default Verify
