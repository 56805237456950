import { API } from "aws-amplify";
import { getHeaders } from "../";

export const getPlant = async ({ id, fields }) => {
  const headers = await getHeaders();
  return await API.get("solsell", `v0/plants/${id}`, {
    headers,
    queryStringParameters: {
      fields,
    },
  });
};

export const listPlantData = async ({ fields, limit, page, plantIds }) => {
  const headers = await getHeaders();

  let queryStringParameters = {};

  if (typeof fields != "undefined") {
    queryStringParameters["fields"] = fields;
  }

  if (typeof limit != "undefined") {
    queryStringParameters["limit"] = limit;
  }

  if (typeof page != "undefined") {
    queryStringParameters["page"] = page;
  }

  if (typeof plantIds != "undefined") {
    queryStringParameters["plant_ids"] = plantIds;
  }

  return await API.get("solsell", "v0/plant-data", {
    headers,
    queryStringParameters,
  });
};

export const listPlants = async ({ fields, limit, page }) => {
  const headers = await getHeaders();

  let queryStringParameters = {};

  if (typeof fields != "undefined") {
    queryStringParameters["fields"] = fields;
  }

  if (typeof limit != "undefined") {
    queryStringParameters["limit"] = limit;
  }

  if (typeof page != "undefined") {
    queryStringParameters["page"] = page;
  }

  return await API.get("solsell", "v0/plants", {
    headers,
    queryStringParameters,
  });
};

export const listPlantStatuses = async ({ fields, limit, page, plantIds }) => {
  const headers = await getHeaders();

  let queryStringParameters = {};

  if (typeof fields != "undefined") {
    queryStringParameters["fields"] = fields;
  }

  if (typeof limit != "undefined") {
    queryStringParameters["limit"] = limit;
  }

  if (typeof page != "undefined") {
    queryStringParameters["page"] = page;
  }

  if (typeof plantIds != "undefined") {
    queryStringParameters["plant_ids"] = plantIds;
  }

  return await API.get("solsell", "v0/plant-statuses", {
    headers,
    queryStringParameters,
  });
};

export const readPlantDataSources = async (plantId, params) => {
  let headers = await getHeaders(params.headers || {});
  let queryStringParameters = params.queryStringParameters || {};
  return await API.get("matomete.solar", `v0/plants/${plantId}/data-sources`, {
    headers,
    queryStringParameters,
  });
};
