import React from 'react'
import styled, {useTheme} from 'styled-components'


const Headline = styled.div`
  color: ${props => props.color ? props.theme.palette.primary[props.color] : props.theme.palette.primary.main};
  font-size: ${props => props.fontSize ? props.theme.fontSize[props.fontSize] : props.theme.fontSize.md};
`

const _Headline = ({children, ...props}) => {
  const theme = useTheme()

  return (
    <Headline theme={theme} {...props}>{children}</Headline>
  )
}

export default _Headline
