import {API, Auth} from 'aws-amplify'
import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import NoMatch from '../NoMatch'
import FullHeightContent from '../ui/FullHeightContent'
import Loading from '../ui/Loading'
import Message from '../ui/Message'
import styles from '../../styles/user-groups/Detail.less'

const TYPE_FROM = 'from'
const TYPE_TO = 'to'

const Detail = ({setId, userGroups, usersMap, userSub}) => {
  const history = useHistory()
  const {id} = useParams()

  const [errorMessage, setErrorMessage] = useState(null) 
  const [isAlertDisplayed, setIsAlertDisplayed] = useState(false)
  const [isNoMatch, setIsNoMatch] = useState(false)
  const [plantsFrom, setPlantsFrom] = useState(null)
  const [plantsMap, setPlantsMap] = useState(null)
  const [plantsTo, setPlantsTo] = useState(null)
  const [selectedPlantsFrom, setSelectedPlantsFrom] = useState([])
  const [selectedPlantsTo, setSelectedPlantsTo] = useState([])
  const [successMessage, setSuccessMessage] = useState(null) 
  const [tabIndex, setTabIndex] = useState(0)
  const [typ, setTyp] = useState()
  const [userFrom, setUserFrom] = useState()
  const [userTo, setUserTo] = useState()

  const handleChangePlantFromItem = e => {
    const v = e.target.value

    let newSelectedPlantsFrom
    if(e.target.checked) {
      newSelectedPlantsFrom = selectedPlantsFrom.concat([v])
    }
    else {
      newSelectedPlantsFrom = selectedPlantsFrom.filter(id => id != v)
    }
    setSelectedPlantsFrom(newSelectedPlantsFrom)
  }

  const handleChangePlantToItem = e => {
    const v = e.target.value

    let newSelectedPlantsTo
    if(e.target.checked) {
      newSelectedPlantsTo = selectedPlantsTo.concat([v])
    }
    else {
      newSelectedPlantsTo = selectedPlantsTo.filter(id => id != v)
    }
    setSelectedPlantsTo(newSelectedPlantsTo)
  }

  const handleClickDeleteUserGroup = async e => {
    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken}

      const r = await API.del('solsell', `v0/user-groups/${id}`, {headers})

      // とりあえずリロード
      history.push('/user-groups/create')
    }
    catch(err) {
      console.log(err)
    }
  }

  const handleClickMoveSelectedPlantsFrom = e => {
    if(selectedPlantsFrom.length == 0) {
      return
    }
    // setPlantsFrom(plantsFrom.filter(id => !selectedPlantsFrom.includes(id)))
    setPlantsTo(plantsTo.concat(selectedPlantsFrom))
    setSelectedPlantsFrom([])
  }

  const handleClickMoveSelectedPlantsTo = e => {
    if(selectedPlantsTo.length == 0) {
      return
    }
    setPlantsTo(plantsTo.filter(id => !selectedPlantsTo.includes(id)))
    setSelectedPlantsTo([])
  }

  const handleEdit = async e => {
    setErrorMessage(null)
    setSuccessMessage(null)
    
    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const r = await API.post('solsell', 'v0/plant-users', {
        body: {
          plant_ids: plantsTo,
          user_sub: userTo.user_sub,
          user_group_id: id,
        },
        headers,
      })

      setSuccessMessage('編集が完了しました。')
    }
    catch(err) {
      setErrorMessage(err)
      console.log(err)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setErrorMessage(null)
    setSuccessMessage(null)
    
    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const r = await API.post('solsell', 'v0/user-groups', {
        body: {
          user_sub_from: payload['cognito:username'],
          user_sub_to: userSub,
        },
        headers,
      })

      setUserSub('')
      setSuccessMessage('申請を送信しました。')
    }
    catch(err) {
      setErrorMessage(err)
      console.log(err)
    }
  }

  const initPlants = async () => {
    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken}

      const r = await API.get('solsell', 'v0/plants', {
        headers,
        queryStringParameters: {
          fields: ['id', 'service_id', 'name'],
          limit: -1,
        }
      })

      let pf = []
      let pm = {}
      r.data.map(item => {
        const idString = item.id.toString()
        pf.push(idString)
        pm[idString] = item
      })
      setPlantsFrom(pf)
      setPlantsMap(pm)
    }
    catch(err) {
      console.log(err)
    }
  }

  const initPlantsTo = async userSub => {
    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken}

      const r = await API.get('solsell', 'v0/plant-users', {
        headers,
        queryStringParameters: {
          fields: ['plant_id'],
          limit: -1,
          user_sub: userSub,
          user_group_id: id,
        }
      })

      const pt = r.data.map(item => item.plant_id.toString())
      setPlantsTo(pt)
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setId(id)
    setIsAlertDisplayed(false)

    if(!userGroups || !usersMap) {
      return
    }

    let userGroup
    for(const item of userGroups) {
      if(item.id.toString() == id) {
        userGroup = item
      }
    }

    if(!userGroup) {
      setIsNoMatch(true)
      return
    }

    setTyp(userGroup.user_sub_from == userSub ? TYPE_FROM : TYPE_TO)

    setUserFrom(usersMap[userGroup.user_sub_from])
    setUserTo(usersMap[userGroup.user_sub_to])

    if(!plantsMap) {
      return
    }

    initPlantsTo(userGroup.user_sub_to)

  }, [id, plantsMap, userGroups, usersMap])

  useEffect(() => {
    initPlants()
  }, [])

  if(isNoMatch) {
    return <NoMatch/>
  }

  return (
    <FullHeightContent>
      {isAlertDisplayed &&
        <Alert
          name={userTo ? userTo.family_name : ''}
          onClick={handleClickDeleteUserGroup}
          setIsAlertDisplayed={setIsAlertDisplayed}
        />
      }
      <ul className={styles.tab}>
        <li className={tabIndex == 0 ? styles.active : ''} onClick={e => setTabIndex(0)}>物件連携</li>
        <li className={tabIndex == 1 ? styles.active : ''} onClick={e => setTabIndex(1)}>連絡先情報</li>
      </ul>
      {errorMessage &&
        <Message message={errorMessage}/>
      }
      {successMessage &&
        <Message message={successMessage} typ="success"/>
      }
      {(tabIndex == 0 && userFrom && userTo) &&
        <div>
          {/*<div className={styles.userGroupNote}>ドラッグ&ドロップすることで、物件の追加と削除ができます。</div>*/}
          {typ == TYPE_FROM &&
            <div className={styles.userGroupNote}>
              動かしたいプラントを選択して中央の右矢印、左矢印を押下することで移動させられます。<br/>
              その後、右の「編集完了」ボタンを押下することで編集内容が保存されます。
            </div>
          }
          <div className={styles.userGroupFromTo}>
            <div className={styles.userGroupFormItem}>
              <div className={styles.userGroupFormItemWithIcon}>
                <div>
                  <img src={`/matomete-solar/img/icon-user-group-approved.png`}/>
                </div>
                <div>
                  <div className={styles.userGroupFormItemWithIconTextUpper}>{userFrom.family_name}&nbsp;様</div>
                  <div>{userTo.family_name}&nbsp;様</div>
                </div>
              </div>
            </div>
            {typ == TYPE_FROM &&
              <Fragment>
                <div className={styles.userGroupFormBetween}></div>
                <div className={styles.userGroupFormItem}>{userFrom.family_name}&nbsp;様管理物件一覧</div>
              </Fragment>
            }
          </div>
          <div className={styles.userGroupForm}>
            <div className={styles.userGroupFormItem}>
              {plantsTo &&
                <ul className={styles.userGroupFormList}>
                  {plantsTo.map(id => {
                    const plant = plantsMap[id.toString()]
                    if(typeof plant == 'undefined') {
                      return null
                    }
                    return (
                      <li key={id}>
                        <div className={styles.userGroupFormListItemCheckbox}>
                          <input id={`checkboxTo${id}`} onChange={handleChangePlantToItem} type="checkbox" value={id}/>
                        </div>
                        <label
                          className={styles.userGroupFormListItemLabel}
                          htmlFor={`checkboxTo${id}`}
                        >
                          <div className={styles.userGroupFormListItemText}>
                            <div>No.{id}</div>
                            <div>{plant.name}</div>
                          </div>
                          <div className={styles.userGroupFormListItemArrowIcon}>
                          </div>
                        </label>
                      </li>
                    )
                  })}
                </ul>
              }
            </div>
            {typ == TYPE_FROM &&
              <Fragment>
                <div className={styles.userGroupFormBetween}>
                  <div className={styles.userGroupFormBetweenIcon} onClick={handleClickMoveSelectedPlantsTo}>
                    <img src={`/matomete-solar/img/icon-arrow-right-grey.png`}/>
                  </div>
                  <div className={styles.userGroupFormBetweenIcon} onClick={handleClickMoveSelectedPlantsFrom}>
                    <img src={`/matomete-solar/img/icon-arrow-left-blue.png`}/>
                  </div>
                </div>
                <div className={styles.userGroupFormItem}>
                  {(plantsFrom && plantsTo) &&
                    <ul className={styles.userGroupFormList}>
                      {plantsFrom.map(id => {
                        const disabled = plantsTo.includes(id)
                        const plant = plantsMap[id.toString()]
                        return (
                          <li className={disabled ? styles.userGroupFormListItemDisabled : ''} key={id}>
                            <div className={styles.userGroupFormListItemCheckbox}>
                              <input
                                disabled={disabled}
                                id={`checkboxFrom${id}`}
                                onChange={handleChangePlantFromItem}
                                type="checkbox"
                                value={id}
                              />
                            </div>
                            <label
                              className={styles.userGroupFormListItemLabel}
                              htmlFor={`checkboxFrom${id}`}
                            >
                              <div className={styles.userGroupFormListItemText}>
                                <div>No.{id}</div>
                                <div>{plant.name}</div>
                              </div>
                              <div className={styles.userGroupFormListItemArrowIcon}>
                              </div>
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  }
                </div>
                <div className={styles.userBtns}>
                  <img src={`/matomete-solar/img/btn-edit-user-group.png`} onClick={handleEdit}/>
                  <img src={`/matomete-solar/img/btn-delete-user-group.png`} onClick={e => setIsAlertDisplayed(true)}/>
                </div>
              </Fragment>
            }
          </div>
        </div>
      }
      {tabIndex == 1 &&
        <Profile typ={typ} userFrom={userFrom} userTo={userTo}/>
      }
    </FullHeightContent>
  )
}

const Alert = ({name, onClick, setIsAlertDisplayed}) => {
  const handleClickClose = e => {
    if(e.target.className != styles.alertOverlay) {
      return
    }
    setIsAlertDisplayed(false)
  }

  return (
    <div className={styles.alertOverlay} onClick={handleClickClose}>
      <div className={styles.alertContent}>
        <img className={styles.alertIcon} src={`/matomete-solar/img/icon-alert.png`}/>
        <div className={styles.alertText}>
          {name}さま<br/>
          との連携を解除しても<br/>
          よろしいでしょうか？
        </div>
        <div className={styles.alertBtns}>
          <img src={`/matomete-solar/img/btn-yes-grey.png`} onClick={onClick}/>
          <img src={`/matomete-solar/img/btn-no-blue.png`} onClick={e => setIsAlertDisplayed(false)}/>
        </div>
      </div>
    </div>
  )
}

const Profile = ({typ, userFrom, userTo}) => {
  if(!typ || !userFrom || !userTo) {
    return
  }

  const user = typ == TYPE_FROM ? userTo : userFrom
  return (
    <div className={styles.userCtn}>
      <div className={styles.userForm}>
        <div className={styles.userFormGroup}>
          <label>アカウントID</label>
          <div className={styles.userFormGroupInput}>
            {user.user_sub}
          </div>
        </div>
        <div className={styles.userFormGroup}>
          <label>お名前</label>
          <div className={styles.userFormGroupInputList}>
            <div className={`${styles.userFormGroupInputListItem} ${styles.userFormGroupInputListItemW160}`}>
              {user.family_name}
            </div>
            <div className={`${styles.userFormGroupInputListItem} ${styles.userFormGroupInputListItemW160}`}>
              {user.given_name}
            </div>
          </div>
        </div>
        <div className={styles.userFormGroup}>
          <label>お名前（カナ）</label>
          <div className={styles.userFormGroupInputList}>
            <div className={`${styles.userFormGroupInputListItem} ${styles.userFormGroupInputListItemW240}`}>
              {user.family_name_kana}
            </div>
            <div className={`${styles.userFormGroupInputListItem} ${styles.userFormGroupInputListItemW240}`}>
              {user.given_name_kana}
            </div>
          </div>
        </div>
        <div className={styles.userFormGroup}>
          <label>電話番号</label>
          <div className={styles.userFormGroupInput}>
            {user.phone_number.replace('+81', '0')}
          </div>
        </div>
        <div className={styles.userFormGroup}>
          <label>アドレス</label>
          <div className={styles.userFormGroupInput}>
            {user.email}
          </div>
        </div>
      </div>
      <div className={styles.userBtns}>
        <img src={`/matomete-solar/img/btn-delete-user-group.png`} onClick={e => setIsAlertDisplayed(true)}/>
      </div>
    </div>
  )
}

export default Detail
