import React from 'react'
import {Route} from 'react-router-dom'
import {useTracking} from '../../hooks/gtag'

const TrackableRoute = ({children, ...rest}) => {
  useTracking(process.env.GTAG_ID)

  return (
    <Route {...rest}>{children}</Route>
  )

}

export default TrackableRoute
