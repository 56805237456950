import React from 'react'

const PasswordInput = ({onChange, service, styles, value}) => {
  if(!service.password_in_use) {
    return null
  }

  return (
    <div className={styles.formGroupRowItem}>
      <label className={styles.inlineLabel}>{service.password_label}</label>
      <input
        className={`${styles.inputText} ${styles.inputTextInline}`}
        name="password"
        onChange={onChange}
        placeholder={`${service.password_label} を入力`}
        type="password"
        value={value}
      />
    </div>
  )
}

export default PasswordInput
