import { API } from "aws-amplify";
import { format } from "date-fns";
import { getHeaders } from "../";
import { DATE_FORMAT } from "../../constants/reports";

const formatQueryStringParameters = (params) => {
  return {
    ...params,
    end: format(params.end, DATE_FORMAT),
    start: format(params.start, DATE_FORMAT),
  };
};

export const downloadData = async (params, version = "v0") => {
  const headers = await getHeaders();
  const r = await API.get("matomete.solar", `${version}/reports`, {
    headers,
    queryStringParameters: formatQueryStringParameters(params),
    responseType: "arraybuffer",
  });
  return r;
};

export const listData = async (params, version = "v0") => {
  const headers = await getHeaders();
  const r = await API.get("matomete.solar", `${version}/reports`, {
    headers,
    queryStringParameters: formatQueryStringParameters(params),
  });
  return r.data;
};
