import { API, Auth } from "aws-amplify";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FilterMenuContainer from "./FilterMenuContainer";
import StarIcon from "./StarIcon";
import CreateUserModal from "../users/CreateUserModal";
import styles from "../../styles/ui/FilterMenuPlant.less";

const ROUTE_CREATE_PLANT = "/plants/create";
const ROUTE_ALERT_NOTIFICATION_DESTINATIONS =
  "/alerts/notification-destinations";
const ROUTES_NO_MODAL = [
  "/plants/create",
  // '/mypage',
  "/user-groups/create",
];

const FilterMenuPlant = ({
  hideUserModal,
  id: defaultId,
  isAlert,
  isPlant,
  onClickBtnCreatePlant,
  onClickListItem,
  onClickSort,
  onClickStar,
  onLoadList,
}) => {
  const { pathname } = useLocation();

  const [btnIndex, setBtnIndex] = useState(0);
  const [filteredPlants, setFilteredPlants] = useState([]);
  // const [isReady, setIsReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [id, setId] = useState(defaultId);
  const [plants, setPlants] = useState([]);
  const [starredPlants, setStarredPlants] = useState([]);
  const [filterText, setFilterText] = useState("");

  const handleChangeFilterText = (e) => {
    const {
      target: { value },
    } = e;

    setFilterText(value);
    setFilteredPlants(plants.filter((item) => item.name.indexOf(value) !== -1));
  };

  const handleClickSort = (index) => async (e) => {
    setBtnIndex(index);
    // onClickSort()
  };

  const handleClickSwitch = (e) => {
    e.preventDefault();
    setIsSwitchOn(!isSwitchOn);
  };

  const listPlants = async () => {
    setIsLoading(true);

    try {
      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = { Authorization: jwtToken };

      const r = await API.get("solsell", "v0/plants", {
        headers,
        queryStringParameters: {
          fields: ["id", "service_id", "name"],
          limit: -1,
        },
      });
      if (isSubscribed) {
        setIsReady(true);
        setPlants(r.data);
        setFilteredPlants(r.data);
      }
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  const listStarredPlants = async () => {
    // const items = [1234, 1237, 1240, 1243, 1246, 1249, 1252, 1255, 1258, 1261, 1264, 1267, 1270, 1273, 1276, 1279, 1282, 1285, 1288, 1291, 1294, 1297]
    // setStarredPlants(items)
  };

  const init = async () => {
    await Promise.all([listPlants(), listStarredPlants()]);
    // setIsReady(true)
  };

  useEffect(() => {
    init();
    return () => {
      return setIsSubscribed(false);
    };
  }, []);

  useEffect(() => {
    setId(defaultId);
  }, [defaultId]);

  useEffect(() => {
    if (filteredPlants.length && onLoadList) {
      onLoadList(filteredPlants);
    }
  }, [filteredPlants]);

  useEffect(() => {
    if (
      pathname == ROUTE_CREATE_PLANT ||
      pathname == ROUTE_ALERT_NOTIFICATION_DESTINATIONS
    ) {
      setId(null);
    }
  }, [pathname]);

  return (
    <FilterMenuContainer>
      {hideUserModal !== true &&
        isReady &&
        plants.length == 0 &&
        !ROUTES_NO_MODAL.includes(pathname) && <CreateUserModal />}
      <div className={styles.upper}>
        <div className={styles.upperItem}>
          {isPlant ? (
            <Link className={styles.btnCreatePlant} to={ROUTE_CREATE_PLANT}>
              <img src={`/matomete-solar/img/btn-create-plant.png`} />
            </Link>
          ) : (
            <Fragment>
              {false && ( //!isAlert &&
                <div className={styles.switchCtn}>
                  <div className={styles.switchTxt}>グループで表示</div>
                  <label
                    className={`${styles.switch} ${
                      isSwitchOn ? styles.on : ""
                    }`}
                    onClick={handleClickSwitch}
                  >
                    <input type="checkbox" />
                    <span className={styles.slider}></span>
                  </label>
                  {isSwitchOn && (
                    <Link className={styles.btnCreateGroup} to="/">
                      <img src={`/matomete-solar/img/btn-create-group.png`} />
                    </Link>
                  )}
                </div>
              )}
              <div
                className={`${styles.inputCtn} ${
                  /*isAlert*/ true ? styles.inputCtnAlert : ""
                }`}
              >
                <input
                  onChange={handleChangeFilterText}
                  type="text"
                  value={filterText}
                />
              </div>
            </Fragment>
          )}
        </div>
        {false && ( //!isAlert &&
          <div className={styles.upperItem}>
            <div className={styles.btnGroup}>
              <button
                className={btnIndex == 0 ? styles.isBtnActive : ""}
                onClick={handleClickSort(0)}
                type="button"
              >
                物件名
              </button>
              <button
                className={btnIndex == 1 ? styles.isBtnActive : ""}
                onClick={handleClickSort(1)}
                type="button"
              >
                収入
              </button>
              <button
                className={btnIndex == 2 ? styles.isBtnActive : ""}
                onClick={handleClickSort(2)}
                type="button"
              >
                発電量
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={styles.listCtn}>
        {isLoading ? (
          <div className={styles.isLoading}>読み込み中…</div>
        ) : (
          <Fragment>
            {filteredPlants && (
              <ul className={styles.list}>
                {!isPlant && (
                  <li
                    className={`${styles.listItem} ${
                      !id ? styles.listItemIsActive : ""
                    }`}
                  >
                    {/*<div className={styles.listItemIcon}>
                      <StarIcon active={active}/>
                    </div>*/}
                    <Link
                      className={styles.listItemText}
                      onClick={(e) => setId()}
                      replace
                      to={
                        isPlant
                          ? `/plants`
                          : `${pathname == "/alerts" ? "/alerts" : "/reports"}`
                      }
                    >
                      <div>全ての物件</div>
                    </Link>
                  </li>
                )}
                {filteredPlants.map((item) => {
                  const active = starredPlants.indexOf(item.id) !== -1;
                  return (
                    <li
                      className={[
                        styles.listItem,
                        id == item.id ? styles.listItemIsActive : "",
                      ].join(" ")}
                      key={item.id}
                    >
                      {/*<div className={styles.listItemIcon}>
                        <StarIcon active={active}/>
                      </div>*/}
                      <Link
                        className={styles.listItemText}
                        onClick={(e) => setId(item.id)}
                        replace
                        to={
                          isPlant
                            ? `/plants/${item.id}`
                            : `${
                                pathname == "/alerts" ? "/alerts" : "/reports"
                              }?plant_id=${item.id}`
                        }
                      >
                        <div>No.{item.id}</div>
                        <div>{item.name}</div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </Fragment>
        )}
      </div>
    </FilterMenuContainer>
  );
};

export default FilterMenuPlant;
