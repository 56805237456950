import React from 'react'
import styles from '../../styles/ui/Content.less'

const Content = ({children, isPlant, isReport, isUserGroup}) => {
  let classNames = [styles.content]
  if(isPlant) {
    classNames.push(styles.isPlant)
  }
  else if(isReport) {
    classNames.push(styles.isReport)
  }
  else if(isUserGroup) {
    classNames.push(styles.isUserGroup)
  }

  return (
    <div className={classNames.join(' ')}>{children}</div>
  )
}

export default Content
