export const CHART_COLORS = [
  "fc5c65",
  "fd9644",
  "fed330",
  "26de81",
  "2bcbba",
  "eb3b5a",
  "fa8231",
  "f7b731",
  "20bf6b",
  "0fb9b1",
  "45aaf2",
  "4b7bec",
  "a55eea",
  "d1d8e0",
  "778ca3",
  "2d98da",
  "3867d6",
  "8854d0",
  "a5b1c2",
  "4b6584",
];

export const CHART_DATA_TYPE_POWER = "power";
export const CHART_DATA_TYPE_REVENUE = "revenue";
export const CHART_DATA_TYPE_INSOLATION = "insolation";

export const DATA_TYPE_PLANTS = "plants";
export const DATA_TYPE_PCS = "pcs";
export const DATA_TYPE_CENSOR = "censor";

export const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS000XXXX";

export const POWER_UNIT_A = "A";
export const POWER_UNIT_KW = "kW";
export const POWER_UNIT_KWH = "kWh";

export const FIELD_TYPE_POWER_A = "power_a";
export const FIELD_TYPE_POWER_KW = "power_kw";
export const FIELD_TYPE_POWER_KWH = "power_kwh";
export const FIELD_TYPE_REVENUE = "revenue";
export const FIELD_TYPE_THEORETICAL_REVENUE = "theoretical_revenue";
export const FIELD_TYPE_INSOLATION = "insolation";
export const FIELD_TYPE_CHARGED_POWER = "charged_power";
export const FIELD_TYPE_SOC = "soc";
export const FIELD_TYPES_JA = {
  [FIELD_TYPE_POWER_A]: "発電量（A）",
  [FIELD_TYPE_POWER_KW]: "発電量（kW）",
  [FIELD_TYPE_POWER_KWH]: "発電量（kWh）",
  [FIELD_TYPE_REVENUE]: "売電収入",
  [FIELD_TYPE_THEORETICAL_REVENUE]: "売電収入理論値",
  [FIELD_TYPE_INSOLATION]: "日射量",
  [FIELD_TYPE_CHARGED_POWER]: "充放電電力",
  [FIELD_TYPE_SOC]: "SOC",
};

export const RANGE_TYPE_RAW = "raw";
export const RANGE_TYPE_HOURLY = "hourly";
export const RANGE_TYPE_DAILY = "daily";
export const RANGE_TYPE_MONTHLY = "monthly";
export const RANGE_TYPE_YEARLY = "yearly";

export const SEARCH_PARAM_KEYS = [
  "data_type",
  "end",
  "plant_id",
  "range_type",
  "start",
];
