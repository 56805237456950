import React from 'react'
import Content from './Content'
import HeaderMenu from './HeaderMenu'
import styles from '../../styles/ui/ContentContainer.less'

const ContentContainer = ({children, isFullWidth, isPlant, isReport, isUserGroup}) => {
  return (
    <div className={`${styles.ctn} ${isFullWidth === true ? styles.fullWidth : ''}`}>
      <HeaderMenu/>
      <Content
        isPlant={isPlant}
        isReport={isReport}
        isUserGroup={isUserGroup}
      >
        {children}
      </Content>
    </div>
  )
}

export default ContentContainer
