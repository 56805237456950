import React, {useEffect, useState} from 'react'
import Panel from './Panel'
import styles from '../../styles/ui/Modal.less'

const Modal = ({children, isOpen: defaultIsOpen, leftPadding = true, onClick}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = e => {
    const {target: {classList}} = e
    if([
      styles.modalOverlayWithLeftPadding,
      styles.modalOverlay,
      styles.modalButtonClose
    ].some(k => classList.contains(k))) {
      setIsOpen(false)
    }

    return
  }

  useEffect(() => {
    setIsOpen(defaultIsOpen)
  }, [defaultIsOpen])

  if(!isOpen) {
    return null
  }

  return (
    <div className={`${leftPadding ? styles.modalOverlayWithLeftPadding : styles.modalOverlay}`} onClick={handleClick}>
      <div className={styles.modal}>
        <button className={styles.modalButtonClose} onClick={handleClick}>&times;</button>
        <div className={styles.modalCtn}>{children}</div>
      </div>
    </div>
  )
}

export default Modal
