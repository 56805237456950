import React from "react";
import Datetime from "../ui/Datetime";
import Panel from "../ui/Panel";
import { readNotifications } from "../../api/notifications";

export default function NotificationPanel({ styles }) {
  const [data, setData] = React.useState([]);

  const init = async () => {
    try {
      const r = await readNotifications();
      setData(r.data);
    } catch (err) {
      console.log(r);
    }
  };

  React.useEffect(() => {
    init();
  }, []);
  return (
    <div className={styles.flexItem}>
      <div className={styles.flexHeadline}>サービスに関するお知らせ</div>
      <ul className={styles.notifications}>
        {data.length > 0 && (
          <>
            {data.map((item) => (
              <li key={item.id}>
                <Panel>
                  <div className={styles.togglerContent}>
                    <div className={styles.togglerSummary}>
                      <span className={styles.date}>
                        <Datetime date={new Date(item.published_at)} />
                      </span>
                      {item.title}
                    </div>
                  </div>
                </Panel>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
}
