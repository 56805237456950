import React, {Fragment} from 'react'

const toTwoDigits = n => {
  return n >= 10 ? n.toString() : `0${n}`
}

const Datetime = ({date, format}) => {
  if(format) {
    throw new Error('Not implemented.')
  }

  return <Fragment>{`${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())} ${toTwoDigits(date.getHours())}:${toTwoDigits(date.getMinutes())}:${toTwoDigits(date.getSeconds())}`}</Fragment>
}

export default Datetime
