let rootDomain
let authDomain
if(process.env.STAGE == 'prod') {
  rootDomain = 'https://matomete.solar'
  authDomain = 'oauth.matomete.solar'  
}
else {
  rootDomain = process.env.IS_LOCAL ? 'http://localhost:9000' : 'https://dev.matomete.solar'  
  authDomain = 'auth.matomete.solar'  
}

export default {
  API: {
    endpoints: [
      {
        name: 'v0',
        endpoint: process.env.API_ENDPOINT_V0,
      },
      {
        name: 'matomete.solar',
        endpoint: process.env.API_ENDPOINT_MATOMETE_SOLAR,
      },
      {
        name: 'solsell',
        endpoint: process.env.API_ENDPOINT_SOLSELL,
      },
      {
        name: 'local',
        endpoint: 'http://localhost:8000/api/',
      },
    ],
  },
  Auth: {
    oauth: {
      domain: authDomain,
      scope: [
        'aws.cognito.signin.user.admin',
        'email',
        'openid',
        'profile'
      ],
      redirectSignIn: `${rootDomain}/matomete-solar/`,
      redirectSignOut: `${rootDomain}/matomete-solar/`,
      responseType: 'code'
    },
    region: 'ap-northeast-1', 
    userPoolId: process.env.COGNITO_USER_POOL_ID_SOLSEL,
    userPoolWebClientId: process.env.COGNITO_CLIENT_ID_SOLSEL, 
  }
}
