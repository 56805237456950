import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Wrapper from '../auth//Wrapper'
import Message from '../ui/Message'
import {
  REGISTRATION_TYPE_MATOMETE_SOLAR,
  USER_TYPE_CORPORATION,
  USER_TYPE_INDIVIDUAL
} from '../../constants/auth'
import styles from '../../styles/auth/SignUp.less'

const Create = () => {
  const history = useHistory()

  const [errorMessage, setErrorMessage] = useState()
  const [isFormValid, setIsFormValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    family_name: '',
    given_name: '',
    family_name_kana: '',
    given_name_kana: '',
    phone_number_0: '',
    phone_number_1: '',
    phone_number_2: '',
    user_type: USER_TYPE_INDIVIDUAL,
  })
  const [formErrors, setFormErrors] = useState()

  const handleChange = e => {
    const {target: {name, value}} = e
    if(name.indexOf('phone_number') != -1 && (value && !/^\d+$/.test(value))) {
      return
    }
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setErrorMessage()
    setFormErrors()

    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const r1 = await API.post('solsell', 'v1/users', {
        body: {
          ...formValues,
          phone_number: [
            formValues.phone_number_0,
            formValues.phone_number_1,
            formValues.phone_number_2
          ].join('-'),
          registration_type: REGISTRATION_TYPE_MATOMETE_SOLAR,
          registration_platform: REGISTRATION_PLATFORM_PC,
        },
        headers,
        response: true,
      })
      
      location.reload()
      return
    }
    catch (err) {
      if(err.hasOwnProperty('response')) {
        const {data: {error}} = err.response
        
        if(error) {
          if(typeof error == 'string') {
            setErrorMessage(error)
          }
          else {
            setFormErrors(error)  
          }
        }
        else {
          setErrorMessage(err.response)
        }
      }
      else {
        setErrorMessage(err)  
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const isFilled = !Object.keys(formValues).some(k => formValues[k].length == 0)
    if(!isFilled) {
      setIsFormValid(false)
      return
    }

    setIsFormValid(formValues.password == formValues.password_1)
  }, [formValues])

  return (
    <Wrapper errorMessage={errorMessage} title="ユーザ情報登録">
      <div className={styles.thanksMessage}>はじめにユーザ情報を<br/>ご登録ください。</div>

      <form onSubmit={handleSubmit}>
        <div className={styles.formGroupFlex}>
          <div className={styles.formGroup}>
            <label className={styles.label}>姓</label>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="family_name"
                onChange={handleChange}
                type="text"
                value={formValues.family_name}
              />
            </div>
            {(formErrors && formErrors.family_name) &&
              <Message message={formErrors.family_name}/>
            }
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>名</label>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="given_name"
                onChange={handleChange}
                type="text"
                value={formValues.given_name}
              />
            </div>
            {(formErrors && formErrors.given_name) &&
              <Message message={formErrors.given_name}/>
            }
          </div>
        </div>

        <div className={styles.formGroupFlex}>
          <div className={styles.formGroup}>
            <label className={styles.label}>姓<span className={styles.helpText}>（カナ）</span></label>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="family_name_kana"
                onChange={handleChange}
                type="text"
                value={formValues.family_name_kana}
              />
            </div>
            {(formErrors && formErrors.family_name_kana) &&
              <Message message={formErrors.family_name_kana}/>
            }
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>名<span className={styles.helpText}>（カナ）</span></label>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="given_name_kana"
                onChange={handleChange}
                type="text"
                value={formValues.given_name_kana}
              />
            </div>
            {(formErrors && formErrors.given_name_kana) &&
              <Message message={formErrors.given_name_kana}/>
            }
          </div>
        </div>
        
        <label className={styles.label}>電話番号<span className={styles.helpText}>（ハイフン無しの数字）</span></label>
        <div className={styles.formGroupFlex}>
          <div className={`${styles.formGroup} ${styles.formGroupSm}`}>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="phone_number_0"
                onChange={handleChange}
                type="text"
                value={formValues.phone_number_0}
              />
            </div>
          </div>
          <div className={`${styles.formGroup} ${styles.formGroupSm}`}>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="phone_number_1"
                onChange={handleChange}
                type="text"
                value={formValues.phone_number_1}
              />
            </div>
          </div>
          <div className={`${styles.formGroup} ${styles.formGroupSm}`}>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="phone_number_2"
                onChange={handleChange}
                type="text"
                value={formValues.phone_number_2}
              />
            </div>
          </div>
        </div>
        {(formErrors && formErrors.phone_number) &&
          <Message message={formErrors.phone_number}/>
        }
        <div className={styles.formGroupFlex}>
          <div className={styles.formGroup}>
            <label className={styles.label}>個人・法人</label>
            <div
              className={`${styles.tab} ${formValues.user_type == USER_TYPE_INDIVIDUAL ? styles.active : ''}`}
              onClick={e => handleChange({target: {name: 'user_type', value: USER_TYPE_INDIVIDUAL}})}
            >
              個人
            </div>
            {(formErrors && formErrors.user_type) &&
              <Message message={formErrors.user_type}/>
            }
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}></label>
            <div
              className={`${styles.tab} ${formValues.user_type == USER_TYPE_CORPORATION ? styles.active : ''}`}
              onClick={e => handleChange({target: {name: 'user_type', value: USER_TYPE_CORPORATION}})}
            >
              法人
            </div>
          </div>
        </div>
        <button className={styles.btn} disabled={isLoading || !isFormValid} type="submit">登録する</button>
      </form>
    </Wrapper>
  )
}

export default Create
