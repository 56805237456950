import React from 'react'
import styles from '../../styles/ui/Main.less'

const Main = ({children}) => {
  return (
    <div className={styles.main}>{children}</div>
  )
}

export default Main
