import {API, Auth} from 'aws-amplify'
import React, {useEffect, useState} from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import Loading from '../ui/Loading'
import {TYPE_LINE} from '../../constants/alert-notification-destinations'

const ConfirmLine = ({}) => {
  const {search} = useLocation()

  const [redirectState, setRedirectState] = useState()

  const submit = async () => {
    const id = new URLSearchParams(search).get('id')
    if(!id) {
      setRedirectState({success: false, message: '不正なコードです。'})
      return
    }

    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const r = await API.post('solsell', 'v0/alert-notification-destinations', {
        body: {destination: id, typ: TYPE_LINE},
        headers,
        response: true,
      })

      setRedirectState({success: true, data: r, message: 'LINE の連携が完了しました。'})
    }
    catch(err) {
      setRedirectState({success: false, message: err})
    }
  }
  
  useEffect(() => {
    submit()
  }, [])

  if(redirectState) {
    return <Redirect to={{
      pathname: '/alerts/notification-destinations',
      state: redirectState
    }}/>
  }

  return (
    <Loading/>
  )
}

export default ConfirmLine
