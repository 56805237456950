import React from 'react'
import styled, {useTheme} from 'styled-components'

const ExLink = styled.a`
  color: ${props => props.theme.palette.primary.light};
  display: block;
  margin-top: 14px;
  padding-left: 16px;
  position: relative;
`

const Svg = styled.svg`
  left: 0;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 11px;
`

const HelpText = styled.div`
  font-size: ${props => 14 / props.theme.vw}vw;
  margin-top: ${props => 20 / props.theme.vw}vw;
  white-space: pre;
`

const ServiceHelpText = ({service}) => {
  if(!service.help_text) {
    return null
  }

  const theme = useTheme()

  if(service.help_link) {
    return (
      <ExLink href={service.help_link} target="_blank">
        <Svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 122.6 122.88"
          style={{enableBackground: 'new 0 0 122.6 122.88'}}
          xmlSpace="preserve"
        >
          <g>
            <path
              d="M110.6,72.58c0-3.19,2.59-5.78,5.78-5.78c3.19,0,5.78,2.59,5.78,5.78v33.19c0,4.71-1.92,8.99-5.02,12.09 c-3.1,3.1-7.38,5.02-12.09,5.02H17.11c-4.71,0-8.99-1.92-12.09-5.02c-3.1-3.1-5.02-7.38-5.02-12.09V17.19 C0,12.48,1.92,8.2,5.02,5.1C8.12,2,12.4,0.08,17.11,0.08h32.98c3.19,0,5.78,2.59,5.78,5.78c0,3.19-2.59,5.78-5.78,5.78H17.11 c-1.52,0-2.9,0.63-3.91,1.63c-1.01,1.01-1.63,2.39-1.63,3.91v88.58c0,1.52,0.63,2.9,1.63,3.91c1.01,1.01,2.39,1.63,3.91,1.63h87.95 c1.52,0,2.9-0.63,3.91-1.63s1.63-2.39,1.63-3.91V72.58L110.6,72.58z M112.42,17.46L54.01,76.6c-2.23,2.27-5.89,2.3-8.16,0.07 c-2.27-2.23-2.3-5.89-0.07-8.16l56.16-56.87H78.56c-3.19,0-5.78-2.59-5.78-5.78c0-3.19,2.59-5.78,5.78-5.78h26.5 c5.12,0,11.72-0.87,15.65,3.1c2.48,2.51,1.93,22.52,1.61,34.11c-0.08,3-0.15,5.29-0.15,6.93c0,3.19-2.59,5.78-5.78,5.78 c-3.19,0-5.78-2.59-5.78-5.78c0-0.31,0.08-3.32,0.19-7.24C110.96,30.94,111.93,22.94,112.42,17.46L112.42,17.46z"
              fill={theme.palette.primary.light}
            />
          </g>
        </Svg>
        {service.help_text}
      </ExLink>
    )
  }
  return <HelpText>{service.help_text}</HelpText>
}

export default ServiceHelpText
