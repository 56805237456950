import {API, Auth} from 'aws-amplify'
import React, {Fragment, useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import Select from '../code-categories/Select'
import AdminContainer from '../../ui/AdminContainer'
import Datetime from '../../ui/Datetime'
import {SERVICES_IDS_JA} from '../../../constants/services'
import styles from '../../../styles/alerts/codes/List.less'

const List = () => {
  const history = useHistory()
  const {pathname, search} = useLocation()

  const [alertCodeCategoriesMap, setAlertCodeCategoriesMap] = useState({})
  const [items, setItems] = useState()
  const [pagination, setPagination] = useState()
  const [searchFormValues, setSearchFormValues] = useState({
    alert_code_category_id: '',
    page: '1'
  })
  const [updateFormValues, setUpdateFormValues] = useState({
    ids: [],
    alert_code_category_id: ''
  })

  const handleChangeSearchFormAlertCodeCategory = value => {
    setSearchFormValues({...searchFormValues, alert_code_category_id: value})
  }

  const handleChangeUpdateFormAlertCodeCategory = value => {
    setUpdateFormValues({...updateFormValues, alert_code_category_id: value})
  }

  const handleClickAlertCodeListItem = e => {
    const {target: {value}} = e
    const {ids} = updateFormValues

    let newIds
    if(ids.indexOf(value) === -1) {
      newIds = ids.concat([value])
    }
    else {
      newIds = ids.filter(id => id != value)
    }
    setUpdateFormValues({...updateFormValues, ids: newIds})
  }

  const handleClickSearch = e => {
    const params = new URLSearchParams(search)
    params.set('alert_code_category_id', searchFormValues.alert_code_category_id)
    
    history.push({pathname, search: `?${params.toString()}`})
  }

  const handleClickSubmit = async e => {
    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const r = await API.post('solsell', 'v0/alert-codes', {
        body: updateFormValues, headers, response: true,
      })

      location.reload()
    }
    catch(err) {
      console.log(r)
    }
  }

  const listCodes = async params => {
    // setErrorMessage(null)
    // setLoading('データを取得しています…')

    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken}

      const promises = [
        API.get('solsell', `v0/alert-code-categories`, {
          headers, queryStringParameters: {fields: ['id', 'name'], limit: -1}
        }),
        API.get('solsell', `v0/alert-codes`, {
          headers,
          queryStringParameters: {
            alert_code_category_id: params.alert_code_category_id,
            fields: ['id', 'alert_code_category_id', 'name', 'service_id', 'created_at', 'updated_at'],
            page: params.page,
          }
        })
      ]
      const rs = await Promise.all(promises)

      // const rs = [
      //   {"data": [{"id": 1, "name": "test"}], "count": 1, "limit": -1, "pages": -1},
      //   {"data": [{"id": 5, "alert_code_category_id": null, "name": "E4-3", "service_id": 2, "created_at": "2021-05-17T05:17:11.000000Z", "updated_at": "2021-05-18T02:54:36.000000Z"}, {"id": 6, "alert_code_category_id": null, "name": "E3-3", "service_id": 2, "created_at": "2021-05-17T05:17:11.000000Z", "updated_at": "2021-05-18T02:53:28.000000Z"}], "count": 8, "limit": 2, "pages": 4, "page": 1},
      // ]

      let accm = {}
      rs[0].data.map(item => {
        accm[item.id.toString()] = item
      })

      setAlertCodeCategoriesMap(accm)

      setItems(rs[1].data)
      setPagination(rs[1])
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(search)

    const newFormValues = {...searchFormValues, ...{
      'alert_code_category_id': params.get('alert_code_category_id') || '',
      'page': params.get('page') || '1',
    }}

    setSearchFormValues(newFormValues)

    setItems(undefined)
    setPagination(undefined)
    listCodes(newFormValues)

  }, [search])

  return (
    <AdminContainer>
      <div className={styles.flexWrapper}>
        <div className={styles.form}>
          <div className={styles.flex}>
            <div className={styles.flexItem}>
              <Select
                onChange={handleChangeSearchFormAlertCodeCategory}
                value={searchFormValues.alert_code_category_id}
              />
            </div>
            <div className={styles.flexItem}>
              <button
                className={styles.btn}
                onClick={handleClickSearch}
              >
                絞り込む
              </button>
            </div>
          </div>
        </div>
        {updateFormValues.ids.length > 0 &&
          <div className={styles.form}>
            <div className={styles.flex}>
              <div className={styles.flexItem}>選択した項目を</div>
              <div className={styles.flexItem}>
                <Select
                  onChange={handleChangeUpdateFormAlertCodeCategory}
                  value={updateFormValues.alert_code_category_id}
                />
              </div>
              <div className={styles.flexItem}>
                <button
                  className={styles.btn}
                  disabled={updateFormValues.alert_code_category_id.length == 0}
                  onClick={handleClickSubmit}
                >
                  分類する
                </button>
              </div>
            </div>
          </div>
        }
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>分類</th>
            <th>アラートコード</th>
            <th>監視ツール</th>
            <th>作成日時</th>
            <th>更新日時</th>
          </tr>
        </thead>
        <tbody>
          {typeof items == 'undefined' ? (
            <tr>
              <td colSpan="7">読み込み中…</td>
            </tr>
          ) : (
            <Fragment>
              {items.map(item => {
                const acc = alertCodeCategoriesMap[item.alert_code_category_id]
                return (
                  <tr key={item.id}>
                    <td>
                      <input onClick={handleClickAlertCodeListItem} type="checkbox" value={item.id}/>
                    </td>
                    <td>{item.id}</td>
                    <td>{acc ? acc.name : ''}</td>
                    <td>{item.name}</td>
                    <td>{SERVICES_IDS_JA[item.service_id.toString()]}</td>
                    <td>{<Datetime date={new Date(item.created_at)}/>}</td>
                    <td>{<Datetime date={new Date(item.updated_at)}/>}</td>
                  </tr>
                )
              })}
            </Fragment>
          )}  
        </tbody>
      </table>
      {(pagination && pagination.pages > 1) &&
        <ul className={styles.pagination}>
          {pagination.page > 1 &&
            <li>
              <Link
                className={styles.paginationLink}
                to={{
                  pathname,
                  search: new URLSearchParams({
                    alert_code_category_id: searchFormValues.alert_code_category_id,
                    page: pagination.page - 1
                  }).toString()
                }}
              >
                &laquo;
              </Link>
            </li>
          }
          {[...Array(pagination.pages)].map((x, i) => {
            const page = i + 1
            return (
              <li key={page}>
                {searchFormValues.page == page ? (
                  <span className={styles.paginationLink}>{page}</span>
                ) : (
                  <Link className={styles.paginationLink} to={{
                    pathname,
                    search: new URLSearchParams({
                      alert_code_category_id: searchFormValues.alert_code_category_id,
                      page
                    }).toString()
                  }}>
                    {page}
                  </Link>
                )}
              </li>
            )
          })}
          {pagination.page < pagination.pages &&
            <li>
              <Link
                className={styles.paginationLink}
                to={{
                  pathname,
                  search: new URLSearchParams({
                    alert_code_category_id: searchFormValues.alert_code_category_id,
                    page: pagination.page + 1
                  }).toString()
                }}
              >
                &raquo;
              </Link>
            </li>
          }
        </ul>
      }
        
    </AdminContainer>
  )
}

export default List
