import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useTheme } from "styled-components";
import DatasetPanel from "./dashboard/DatasetPanel";
import NotificationPanel from "./dashboard/NotificationPanel";
import PlantListTable from "./dashboard/PlantListTable";
import ContentContainer from "./ui/ContentContainer";
import FilterMenuPlant from "./ui/FilterMenuPlant";
import Head from "./ui/Head";
import Message from "./ui/Message";
import Panel from "./ui/Panel";
import {
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_PENDING,
} from "../constants/user-groups";
import styles from "../styles/Dashboard.less";

const Dashboard = ({}) => {
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [userGroups, setUserGroups] = useState(null);
  const [usersMap, setUsersMap] = useState(null);

  const listUserGroups = async () => {
    try {
      const {
        idToken: {
          jwtToken,
          payload: { sub },
        },
      } = await Auth.currentSession();

      const headers = { Authorization: jwtToken };
      const queryStringParameters = {
        fields: ["created_at", "id", "user_sub_from"],
        limit: -1,
        status: STATUS_PENDING,
        user_sub: sub,
        user_sub_type: "user_sub_to",
      };
      const r = await API.get("solsell", "v0/user-groups", {
        headers,
        queryStringParameters,
      });

      const promises = r.data.map((item) => {
        return API.get("solsell", `v1/users/${item.user_sub_from}`, {
          headers,
        });
      });

      const users = await Promise.all(promises);

      let um = {};
      users.map((item) => {
        um[item.user_sub] = item;
      });

      if (isSubscribed) {
        setUsersMap(um);
        setUserGroups(r.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // getReport()

    listUserGroups();
    // listNotifications();

    return () => {
      return setIsSubscribed(false);
    };
  }, []);

  return (
    <Fragment>
      <Head />
      <FilterMenuPlant />
      <ContentContainer>
        <DatasetPanel />
        <PlantListTable />
        <div className={styles.flex}>
          <div className={styles.flexItem}>
            <div className={styles.flexHeadline}>通知</div>
            <ul className={styles.notifications}>
              {userGroups && userGroups.length > 0 ? (
                <Fragment>
                  {userGroups.map((item) => {
                    const user = usersMap[item.user_sub_from];
                    return (
                      <li key={item.id}>
                        <Panel>
                          <Toggler
                            createdAt={item.created_at}
                            familyName={user.family_name}
                            givenName={user.given_name}
                            id={item.id}
                          />
                        </Panel>
                      </li>
                    );
                  })}
                </Fragment>
              ) : (
                <li>通知はありません。</li>
              )}
            </ul>
          </div>
          <NotificationPanel styles={styles} />
        </div>
      </ContentContainer>
    </Fragment>
  );
};

const fill = (n) => {
  const s = n.toString();
  return s.length > 1 ? s : `0${s}`;
};

const Toggler = ({ createdAt, familyName, givenName, id }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);

  const history = useHistory();

  const handleClick = async (id, status) => {
    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      };

      const r = await API.put("solsell", `v0/user-groups/${id}`, {
        body: { status },
        headers,
        response: true,
      });
      setSuccessMessage(
        `申請を${status == STATUS_DECLINED ? "拒否" : "承認"}しました。`
      );

      // とりあえず
      history.push("/user-groups");
    } catch (err) {
      setErrorMessage(err);
    }

    setIsLoading(false);
  };

  const handleClickToggle = () => {
    setIsOpen(!isOpen);
  };

  const d = new Date(createdAt);

  return (
    <div className={[styles.toggler, isOpen ? styles.isOpen : ""].join(" ")}>
      <div className={styles.togglerContent}>
        <div className={styles.togglerSummary}>
          <span className={styles.date}>
            {d.getFullYear()}.{fill(d.getMonth() + 1)}.{fill(d.getDate())}
          </span>
          {familyName}
          {givenName}様からアカウント連携の申請が来ています。
        </div>
        <div className={styles.togglerDetail}>
          {errorMessage && <Message message={errorMessage} />}
          {successMessage && <Message message={successMessage} typ="success" />}
          <div className={styles.togglerBtns}>
            <button
              className={styles.btnOutlined}
              onClick={(e) => handleClick(id, STATUS_DECLINED)}
            >
              承諾しない
            </button>
            <button
              className={styles.btnBg}
              onClick={(e) => handleClick(id, STATUS_ACCEPTED)}
            >
              承諾する
            </button>
          </div>
        </div>
      </div>
      <button
        className={styles.togglerTogglable}
        onClick={handleClickToggle}
      ></button>
    </div>
  );
};

export default Dashboard;
