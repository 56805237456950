import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Wrapper from './Wrapper'
import Message from '../ui/Message'
import Policy from '../ui/Policy'
import {GROUP_CORPORATION, GROUP_INDIVIDUAL} from '../../constants/auth'
import {REGISTRATION_TYPE_MATOMETE_SOLAR, REGISTRATION_PLATFORM_PC} from '../../constants/users'
import styles from '../../styles/auth/SignUp.less'

const SignUp = () => {
  const history = useHistory()

  const [errorMessage, setErrorMessage] = useState()
  const [isFormValid, setIsFormValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPolicyChecked, setIsPolicyChecked] = useState(false)
  const [formValues, setFormValues] = useState({
    email: '',
    family_name: '',
    given_name: '',
    // family_name_kana: '',
    // given_name_kana: '',
    phone_number_0: '',
    phone_number_1: '',
    phone_number_2: '',
    password: '',
    password_1: '',
    // group: GROUP_INDIVIDUAL,
  })
  const [formErrors, setFormErrors] = useState()

  const handleChange = e => {
    const {target: {name, value}} = e
    if(name == 'phone_number' && (value && !/^\d+$/.test(value))) {
      return
    }
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setErrorMessage()
    setFormErrors()

    try {
      const headers = {'Content-Type': 'application/json'}

      let userParams = {
        registration_type: REGISTRATION_TYPE_MATOMETE_SOLAR,
        registration_platform: REGISTRATION_PLATFORM_PC,
      }

      const userIdCookie = document.cookie.split('; ').find(item => item.indexOf('user_id') === 0)
      if(userIdCookie) {
        userParams['user_id'] = userIdCookie.split('=')[1]
      }

      const r = await API.post('solsell', 'v1/users/signup', {
        body: {
          ...formValues, 
          phone_number: [
            formValues.phone_number_0,
            formValues.phone_number_1,
            formValues.phone_number_2
          ].join(''),
          q: new URLSearchParams(userParams).toString()
        },
        headers,
        response: true,
      })

      history.push({
        pathname: '/verify', 
        state: {email: formValues.email}
      })
      return
    }
    catch (err) {
      if(err.hasOwnProperty('response')) {
        const {data: {error}} = err.response
        
        if(error) {
          if(typeof error == 'string') {
            setErrorMessage(error)
          }
          else {
            setFormErrors(error)  
          }
        }
        else {
          setErrorMessage(err.response)
        }
      }
      else {
        setErrorMessage(err)  
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const isFilled = !Object.keys(formValues).some(k => formValues[k].length == 0)
    if(!isFilled || !isPolicyChecked) {
      setIsFormValid(false)
      return
    }

    setIsFormValid(formValues.password == formValues.password_1)
  }, [formValues, isPolicyChecked])

  return (
    <Wrapper errorMessage={errorMessage} title="ユーザ登録">
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label}>お名前</label>
          <div className={styles.inputGroup}>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="family_name"
                onChange={handleChange}
                placeholder="山田"
                type="text"
                value={formValues.family_name}
              />
            </div>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="given_name"
                onChange={handleChange}
                placeholder="太郎"
                type="text"
                value={formValues.given_name}
              />
            </div>
          </div>
          {(formErrors?.family_name) &&
            <Message message={formErrors.family_name}/>
          }
          {(formErrors?.given_name) &&
            <Message message={formErrors.given_name}/>
          }
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>電話番号</label>
          <div className={styles.inputGroup}>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="phone_number_0"
                onChange={handleChange}
                placeholder="090"
                type="number"
                value={formValues.phone_number_0}
              />
            </div>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="phone_number_1"
                onChange={handleChange}
                placeholder="1234"
                type="number"
                value={formValues.phone_number_1}
              />
            </div>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="phone_number_2"
                onChange={handleChange}
                placeholder="5678"
                type="number"
                value={formValues.phone_number_2}
              />
            </div>
          </div>
          {(formErrors?.phone_number) &&
            <Message message={formErrors.phone_number}/>
          }
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>メールアドレス</label>
          <div className={styles.inputCtn}>
            <input
              disabled={isLoading}
              name="email"
              onChange={handleChange}
              placeholder="info@example.com"
              type="email"
              value={formValues.email}
            />
          </div>
          {(formErrors?.email) &&
            <Message message={formErrors.email}/>
          }
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>パスワード<span className={styles.helpText}>（英大文字、小文字、数字を全て含む8文字以上）</span></label>
          <div className={styles.inputCtn}>
            <input
              disabled={isLoading}
              name="password"
              onChange={handleChange}
              type="password"
              value={formValues.password}
            />
          </div>
          {(formErrors?.password) &&
              <Message message={formErrors.password}/>
            }
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>パスワード<span className={styles.helpText}>（確認用）</span></label>
          <div className={styles.inputCtn}>
            <input
              disabled={isLoading}
              name="password_1"
              onChange={handleChange}
              type="password"
              value={formValues.password_1}
            />
          </div>
        </div>
        {/*
        <div className={styles.formGroupFlex}>
          <div className={styles.formGroup}>
            <label className={styles.label}>個人・法人</label>
            <div
              className={`${styles.tab} ${formValues.group == GROUP_INDIVIDUAL ? styles.active : ''}`}
              onClick={e => handleChange({target: {name: 'group', value: GROUP_INDIVIDUAL}})}
            >
              個人
            </div>
            {(formErrors?.group) &&
              <Message message={formErrors.group}/>
            }
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}></label>
            <div
              className={`${styles.tab} ${formValues.group == GROUP_CORPORATION ? styles.active : ''}`}
              onClick={e => handleChange({target: {name: 'group', value: GROUP_CORPORATION}})}
            >
              法人
            </div>
          </div>
        </div>
        */}
        <div className={styles.formGroup}>
          <Policy/>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            <input onClick={e => setIsPolicyChecked(!isPolicyChecked)} type="checkbox"/>&nbsp;規約に同意
          </label>
        </div>
        <button className={styles.btn} disabled={isLoading || !isFormValid} type="submit">登録する</button>
        <Link className={styles.link} to="/signin">ログインはこちら</Link>
      </form>
    </Wrapper>
  )
}

export default SignUp
