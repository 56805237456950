import React from 'react'
import Message from './Message'

const FormErrorMessage = ({message}) => {
  return (
    <Message message={message} typ="formError"/>
  )
}

export default FormErrorMessage
