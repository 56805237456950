import React from "react";
import Chart from "./Chart";
import Summary from "./Summary";
import Table from "./Table";
import UnitPriceModal from "./UnitPriceModal";

export default function ReportContent({
  chartDataType,
  data,
  formValues,
  plantDataSources,
  styles,
}) {
  const [isUnitPriceModalClosed, setIsUnitPriceModalClosed] =
    React.useState(true);

  const handleClickToggleUnitPriceModal = (e) => {
    setIsUnitPriceModalClosed(!isUnitPriceModalClosed);
  };

  return (
    <>
      <Chart
        chartDataType={chartDataType}
        data={data}
        formValues={formValues}
        styles={styles}
      />
      <Table
        data={data}
        formValues={formValues}
        onClickToggleUnitPriceModal={handleClickToggleUnitPriceModal}
        plantDataSources={plantDataSources}
        styles={styles}
      />
      <Summary data={data} styles={styles} />
      <UnitPriceModal
        isClosed={isUnitPriceModalClosed}
        onClick={handleClickToggleUnitPriceModal}
        plantId={formValues.plant_id}
      />
    </>
  );
}
