import {API, Auth} from 'aws-amplify'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import {STATUS_PENDING} from '../../constants/user-groups'
import styles from '../../styles/ui/HeaderMenu.less'
import {updateUnitPriceModalClosed} from '../../utils/reports'

const HeaderMenu = () => {
  const history = useHistory()

  const [notificationCount, setNotificationCount] = useState(0)
  const [isPoppedUp, setIsPoppedUp] = useState(false)
  const [name, setName] = useState(null)

  const getUser = async () => {
    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()

      const headers = {Authorization: jwtToken}
      const queryStringParameters = {
        fields: ['family_name', 'given_name'],
      }
      const r = await API.get(
        'solsell', `v1/users/${payload['cognito:username']}`,
        {headers, queryStringParameters}
      )

      // const r = {"family_name": "\u30a8\u30ec", "given_name": "\u592a\u90ce"}
      setName(`${r.family_name} ${r.given_name}`)
    }
    catch(err) {
      console.log(err)
    }
  }

  const handleClickLogout = async e => {
    await Auth.signOut({global: true})

    updateUnitPriceModalClosed(false)

    history.replace('/signin')
    return
  }

  const handleClickMypage = async e => {
    history.push({pathname: '/mypage'})
  }

  const handleClickPopup = e => {
    setIsPoppedUp(!isPoppedUp)
  }

  const listUserGroups = async () => {
    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()

      const headers = {Authorization: jwtToken}
      const queryStringParameters = {
        fields: ['id'],
        limit: -1,
        status: STATUS_PENDING,
        user_sub: payload['cognito:username'],
        user_sub_type: 'user_sub_to'
      }
      const r = await API.get('solsell', 'v0/user-groups', {headers, queryStringParameters})
      // const r = {"data": [], "count": 0, "limit": -1, "pages": 1}
      setNotificationCount(notificationCount + r.count)
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUser()
    listUserGroups()
  }, [])

  return (
    <div className={styles.header}>
      {notificationCount > 0 &&
        <HashLink className={styles.notification} to={{hash: '#notification', pathname: '/', state: {hideUserModal: true}}}>
          <img className={styles.notificationIcon} src={`/matomete-solar/img/icon-notification.png`}/>
          {notificationCount &&
            <div className={styles.notificationCount}>{notificationCount}</div>
          }
        </HashLink>
      }
      {name &&
        <div className={styles.greeting} onClick={handleClickPopup}>
          ようこそ　{name} 様
          <span className={`${styles.toggler} ${isPoppedUp ? styles.open : ''}`}/>
          <div
            className={`${styles.popup} ${isPoppedUp ? styles.poppedUp : ''}`}
            onMouseLeave={e => setIsPoppedUp(false)}
          >
            <div className={styles.popupItem} onClick={handleClickMypage}>
              <img className={styles.popupIcon} src={`/matomete-solar/img/icon-mypage.png`}/>
              マイページ
            </div>
            <div className={styles.popupItem} onClick={handleClickLogout}>
              <img className={styles.popupIcon} src={`/matomete-solar/img/icon-logout.png`}/>
              ログアウト
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default HeaderMenu
