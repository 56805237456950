import React from "react";
import {
  DATA_TYPE_CENSOR,
  DATA_TYPE_PCS,
  DATA_TYPE_PLANTS,
} from "../../constants/reports";

export default function SubFilterDataType({
  dataType,
  onClick,
  onClickDownload,
  plantDataSources,
  styles,
}) {
  const [hasCensor, setHasCensor] = React.useState(false);
  const [hasPcs, setHasPcs] = React.useState(false);
  const [hasPlants, setHasPlants] = React.useState(false);

  React.useEffect(() => {
    let newHasCensor = false;
    let newHasPcs = false;
    let newHasPlants = false;
    plantDataSources.forEach((item) => {
      if (item.data_type == DATA_TYPE_CENSOR) {
        newHasCensor = true;
      } else if (item.data_type == DATA_TYPE_PCS) {
        newHasPcs = true;
      } else if (item.data_type == DATA_TYPE_PLANTS) {
        newHasPlants = true;
      }
    });
    setHasCensor(newHasCensor);
    setHasPcs(newHasPcs);
    setHasPlants(newHasPlants);
  }, [plantDataSources]);

  return (
    <div className={styles.subFilters}>
      {hasPlants === true && (
        <DataTypeItem
          active={dataType == DATA_TYPE_PLANTS}
          dataType={DATA_TYPE_PLANTS}
          label="物件全体"
          onClick={(e) => onClick(DATA_TYPE_PLANTS)}
          styles={styles}
        />
      )}
      {hasPcs === true && (
        <DataTypeItem
          active={dataType == DATA_TYPE_PCS}
          dataType={DATA_TYPE_PCS}
          label="パワコンごと"
          onClick={(e) => onClick(DATA_TYPE_PCS)}
          styles={styles}
        />
      )}
      {hasCensor === true && (
        <DataTypeItem
          active={dataType == DATA_TYPE_CENSOR}
          dataType={DATA_TYPE_CENSOR}
          label="センサーごと"
          onClick={(e) => onClick(DATA_TYPE_CENSOR)}
          styles={styles}
        />
      )}
      {[hasCensor, hasPcs, hasPlants].some((item) => item === true) && (
        <button className={styles.btnExport} onClick={onClickDownload}>
          <img src={`/matomete-solar/img/btn-export.png`} />
        </button>
      )}
    </div>
  );
}

const DataTypeItem = ({ active, label, onClick, styles }) => {
  return (
    <div
      className={`${styles.subFilterRadio} ${active ? styles.checked : ""}`}
      onClick={onClick}
    >
      <div className={styles.subFilterRadioInput}>
        <div className={styles.subFilterRadioInputInner} />
      </div>
      <div>{label}</div>
    </div>
  );
};
