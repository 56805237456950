import React from 'react'
import styles from '../../styles/ui/AppContainer.less'

const AppContainer = ({children}) => {
  return (
    <div className={styles.ctn}>{children}</div>
  )
}

export default AppContainer
