import {Auth} from 'aws-amplify'

export const getHeaders = async headers => {
  const {idToken: {jwtToken}} = await Auth.currentSession()
  const h = {Authorization: jwtToken, 'Content-Type': 'application/json'}

  if(!headers) {
    return h
  }

  return {...h, ...headers}
}
