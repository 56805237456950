import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

export const useTracking = gtagId => {
  const {listen} = useHistory()

  useEffect(() => {
    const unlisten = listen((location) => {

      if (!window.gtag) {
        return
      }

      if (gtagId === '') {
        return
      }

      window.gtag('config', gtagId, { page_path: location.pathname + location.search })
    })

    return unlisten

  }, [gtagId, listen])
}
