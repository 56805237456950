import React from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

export default function DateRangePickerModal({
  end,
  onClickClose,
  onClick,
  start,
  styles,
}) {
  const [formValues, setFormValues] = React.useState({
    end,
    start,
  });

  const handleChange = (name) => (value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleClick = (e) => {
    onClick(formValues);
  };

  return (
    <div>
      <div className={styles.datePickerOuter} onClick={onClickClose}></div>
      <div className={styles.datePickerInner}>
        <div className={styles.datePickerItemList}>
          <div>期間</div>
          {/*<div className={styles.datePickerSelect}>
            <select>
              <option>カスタム</option>
              <option>今日</option>
              <option>昨日</option>
            </select>
          </div>*/}
          <div style={{ marginLeft: "20px" }} />
          {/* tmp */}
          <div className={styles.datePickerItem}>
            <DatePicker
              autoFocus={true}
              dateFormat="yyyy.MM.dd"
              endDate={formValues.end}
              name="start"
              onChange={handleChange("start")}
              selected={formValues.start}
              shouldCloseOnSelect={false}
              startDate={formValues.start}
              wrapperClassName={styles.datePickerWrapper}
            />
          </div>
          <div className={styles.datePickerBetween}>〜</div>
          <div className={styles.datePickerItem}>
            <DatePicker
              autoFocus={false}
              dateFormat="yyyy.MM.dd"
              endDate={formValues.end}
              name="end"
              onChange={handleChange("end")}
              selected={formValues.end}
              shouldCloseOnSelect={false}
              startDate={formValues.start}
              wrapperClassName={styles.datePickerWrapper}
            />
          </div>
          <button className={styles.btnBlue} onClick={handleClick}>
            適用
          </button>
        </div>
        <div className={styles.datePickerInnerClose} onClick={onClickClose}>
          <img src={`/matomete-solar/img/icon-x.png`} />
        </div>
      </div>
    </div>
  );
}
