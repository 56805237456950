import {API, Auth} from 'aws-amplify'
import React, {Fragment, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import FilterMenuContainer from './FilterMenuContainer'
import styles from '../../styles/ui/FilterMenuUserGroup.less'

const FilterMenuUserGroup = ({id, userGroups, usersMap, userSub}) => {
  return (
    <FilterMenuContainer>
      <Link className={styles.btnCreateUserGroup} to="/user-groups/create">
        <img src={`/matomete-solar/img/btn-create-user-group.png`}/>
      </Link>
      <div className={styles.listCtn}>
        {(userGroups && usersMap) &&
          <ul className={styles.list}>
            {userGroups.map((item, i) => {
              const user = usersMap[item.user_sub_from == userSub ? item.user_sub_to : item.user_sub_from]
            
              return (
                <li
                  className={`${styles.listItem} ${id == item.id ? styles.listItemIsActive : ''}`}
                  key={item.id}
                >
                  {/*<div className={styles.listItemIcon}>
                    <StarIcon active={active}/>
                  </div>*/}
                  <Link
                    className={styles.listItemText}
                    // onClick={e => setId(item.id)}
                    replace
                    to={`/user-groups/${item.id}`}
                  >
                    <div>No.{item.id}</div>
                    <div>{user.family_name} {user.given_name}</div>
                  </Link>
                </li>
              )
            })}
          </ul>
        }
      </div>
    </FilterMenuContainer>
  )
}

export default FilterMenuUserGroup
