import React, {useEffect, useState} from 'react'
import {readServices} from '../../api/services'

const ServiceCheckbox = ({onClick, serviceId, styles}) => {
  const [services, setServices] = useState([])

  const initServices = async () => {
    const r = await readServices({
      fields: ['id', 'name', 'is_available'],
      queryStringParameters: {
        orderby: 'id',
        order: 'ASC',
        limit: -1,
        is_available: true,
      }
    })
    setServices(r.data.filter(item => item.is_available === true))
  }

  useEffect(() => {
    if(services.length > 0) {
      return
    }
    initServices()
  }, [services])

  return (
    <div className={styles.formGroup}>
      <div className={styles.tool}>
        <div className={styles.blockLabel}>ご利用中の監視ツール</div>
        <div style={{marginTop: '1%' /* tmp */}}>
          {services.map(item => 
            <div
              className={styles.checkboxCtn}
              key={item.id}
              onClick={onClick(item)}
              style={{justifyContent: 'flex-start'}}
            >
              <div className={`${styles.checkbox} ${serviceId == item.id ? styles.checked : ''}`}/>
              <div>{item.name}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ServiceCheckbox
