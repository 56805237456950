import { API } from "aws-amplify";
import { getHeaders } from "../";
import {
  DATA_TYPE_CENSOR,
  DATA_TYPE_PCS,
  DATA_TYPE_PLANTS,
  POWER_UNIT_A,
  POWER_UNIT_KW,
  POWER_UNIT_KWH,
  RANGE_TYPE_DAILY,
  RANGE_TYPE_HOURLY,
  RANGE_TYPE_RAW,
} from "../../constants/reports";
import {
  SERVICE_ID_MIELOOOP,
  SERVICE_ID_ECO_MEGANE,
  SERVICE_ID_ENAVISION,
  SERVICE_ID_LEYE,
  SERVICE_ID_SOLAR_MONITOR,
  SERVICE_ID_B_NUSS,
  SERVICE_ID_SOLAMAME,
  SERVICE_ID_ECO_MEGANE_INDIVIDUAL,
  SERVICE_ID_SMARTPV,
  SERVICE_ID_TERU2,
  SERVICE_ID_SUNNY_PORTAL,
  SERVICE_ID_KINKEI,
  SERVICE_ID_FISTER,
  SERVICE_ID_FUSIONSOLAR,
  SERVICE_ID_SOLARVIEW,
  SERVICE_ID_LUFY,
  SERVICE_ID_SANIX_EYE,
  SERVICE_ID_ITSUDOKO_MONITOR,
  SERVICE_ID_PVSAFETY,
  SERVICE_ID_OZUCLOUD,
  SERVICE_ID_TABUCHI_CLOUD,
  SERVICE_ID_SOLAR_EDGE,
  SERVICE_ID_SOLAR_REMON,
  SERVICE_ID_HAWKSYSTEM,
  SERVICE_ID_SOLAR_POWER_MONITOR,
  SERVICE_ID_HIDAMARI_EYES,
  SERVICE_ID_MYDELTASOLAR,
  SERVICE_ID_MIHARIBAN,
  SERVICE_ID_MIELOOOP_ADMIN,
} from "../../constants/services";

const minRangeTypes = {
  [SERVICE_ID_B_NUSS]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_ECO_MEGANE]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_ECO_MEGANE_INDIVIDUAL]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_ENAVISION]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_FISTER]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_FUSIONSOLAR]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_HAWKSYSTEM]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_HIDAMARI_EYES]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_ITSUDOKO_MONITOR]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_KINKEI]: RANGE_TYPE_RAW,
  [SERVICE_ID_LEYE]: RANGE_TYPE_RAW,
  [SERVICE_ID_LUFY]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_MIELOOOP]: RANGE_TYPE_RAW,
  [SERVICE_ID_MIELOOOP_ADMIN]: RANGE_TYPE_RAW,
  [SERVICE_ID_MIHARIBAN]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_MYDELTASOLAR]: RANGE_TYPE_RAW,
  [SERVICE_ID_OZUCLOUD]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_PVSAFETY]: RANGE_TYPE_DAILY,
  [SERVICE_ID_SANIX_EYE]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_SMARTPV]: RANGE_TYPE_DAILY,
  // [SERVICE_ID_SMARTPV]:  RANGE_TYPE_RAW,
  [SERVICE_ID_SOLAMAME]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_SOLAR_EDGE]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_SOLAR_MONITOR]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_SOLAR_POWER_MONITOR]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_SOLAR_REMON]: RANGE_TYPE_RAW,
  [SERVICE_ID_SOLARVIEW]: RANGE_TYPE_HOURLY,
  [SERVICE_ID_TABUCHI_CLOUD]: RANGE_TYPE_RAW,
};

export const readService = async (id) => {
  let headers = await getHeaders();
  const r = await API.get("solsell", `v0/services/${id}`, {
    headers,
  });
  r.min_range_type = minRangeTypes[r.id.toString()];
  return r;
};

export const readServices = async (params) => {
  let headers = await getHeaders(params.headers);
  let queryStringParameters = params.queryStringParameters || {};

  return await API.get("solsell", "v0/services", {
    headers,
    queryStringParameters,
  });
};

export const readServiceDataSources = async ({ serviceId }) => {
  const dataset = [
    {
      service_id: SERVICE_ID_B_NUSS,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_B_NUSS,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ECO_MEGANE,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ECO_MEGANE,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ECO_MEGANE,
      data_type: DATA_TYPE_CENSOR,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },

    {
      service_id: SERVICE_ID_ECO_MEGANE_INDIVIDUAL,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ECO_MEGANE_INDIVIDUAL,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ECO_MEGANE_INDIVIDUAL,
      data_type: DATA_TYPE_CENSOR,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ENAVISION,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ENAVISION,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_FISTER,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_FISTER,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_FUSIONSOLAR,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_FUSIONSOLAR,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_DAILY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_HAWKSYSTEM,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_HAWKSYSTEM,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_HIDAMARI_EYES,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_HIDAMARI_EYES,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ITSUDOKO_MONITOR,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_ITSUDOKO_MONITOR,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_KINKEI,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_KINKEI,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_LEYE,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_LEYE,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_LUFY,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_LUFY,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_MIELOOOP,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_MIELOOOP,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_MIELOOOP_ADMIN,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_MIELOOOP_ADMIN,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_MIHARIBAN,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_MIHARIBAN,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_MYDELTASOLAR,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KW,
    },
    {
      service_id: SERVICE_ID_MYDELTASOLAR,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KW,
    },
    {
      service_id: SERVICE_ID_OZUCLOUD,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_PVSAFETY,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_DAILY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_PVSAFETY,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_DAILY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SANIX_EYE,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SMARTPV,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_DAILY,
      power_unit: POWER_UNIT_KWH,
    },
    // {
    //   service_id: SERVICE_ID_SMARTPV,
    //   data_type: DATA_TYPE_PLANTS,
    //   range_type: RANGE_TYPE_RAW,
    //   power_unit: POWER_UNIT_KW,
    // },
    {
      service_id: SERVICE_ID_SOLAMAME,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SOLAR_EDGE,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SOLAR_MONITOR,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SOLAR_MONITOR,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SOLAR_POWER_MONITOR,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SOLAR_POWER_MONITOR,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SOLAR_REMON,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_A,
    },
    {
      service_id: SERVICE_ID_SOLAR_REMON,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_A,
    },
    {
      service_id: SERVICE_ID_SOLARVIEW,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_SOLARVIEW,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_HOURLY,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_TABUCHI_CLOUD,
      data_type: DATA_TYPE_PLANTS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
    {
      service_id: SERVICE_ID_TABUCHI_CLOUD,
      data_type: DATA_TYPE_PCS,
      range_type: RANGE_TYPE_RAW,
      power_unit: POWER_UNIT_KWH,
    },
  ];
  const n = Number(serviceId);
  return {
    data: dataset.filter((item) => item.service_id == n),
  };
};
