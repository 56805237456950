import React from 'react'
import styles from '../../styles/ui/FullHeightContent.less'


const FullHeightContent = ({children}) => {
  return (
    <div className={styles.ctn}>{children}</div>
  )
}

export default FullHeightContent
