import format from "date-fns/format";
import parse from "date-fns/parse";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReportContent from "./reports/ReportContent";
import DateRangePickerDisplay from "./reports/DateRangePickerDisplay";
import DateRangePickerModal from "./reports/DateRangePickerModal";
import PlantList from "./reports/PlantList";
import SubFilterChartDataType from "./reports/SubFilterChartDataType";
import SubFilterDataType from "./reports/SubFilterDataType";
import RangeTypeButtonGroup from "./reports/RangeTypeButtonGroup";
import ContentContainer from "./ui/ContentContainer";
import Panel from "./ui/Panel";
import Head from "./ui/Head";
import Loading from "./ui/Loading";
import Message from "./ui/Message";
import { downloadData, listData } from "../api/reports";
import { getPlant, readPlantDataSources } from "../api/plants";
import {
  DATA_TYPE_CENSOR,
  DATA_TYPE_PCS,
  DATA_TYPE_PLANTS,
  DATE_FORMAT,
  FIELD_TYPE_POWER_A,
  FIELD_TYPE_POWER_KW,
  FIELD_TYPE_POWER_KWH,
  POWER_UNIT_A,
  POWER_UNIT_KW,
  POWER_UNIT_KWH,
  RANGE_TYPE_RAW,
  RANGE_TYPE_HOURLY,
  RANGE_TYPE_DAILY,
  RANGE_TYPE_MONTHLY,
  FIELD_TYPE_REVENUE,
  RANGE_TYPE_YEARLY,
} from "../constants/reports";
import { SERVICE_ID_SOLAMAME } from "../constants/services";
import styles from "../styles/Report.less";

/**
 * 1. 物件を決定
 * 2. 利用可能なデータを決定
 * 3. レポートの取得
 * @returns
 */

const floor = (n, d = 2) => {
  const _d = 10 ** d;
  return Math.floor(n * _d) / _d;
};

export default function Report() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [chartDataType, setChartDataType] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [formValues, setFormValues] = React.useState({
    data_type: null,
    end: null,
    min_range_type: null,
    plant_id: null,
    range_type: null,
    start: null,
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [plantDataSources, setPlantDataSources] = React.useState([]);

  const downloadReport = async () => {
    setIsLoading(true);

    try {
      let fields = ["date_time", "power", "revenue", "theoretical_revenue"];
      if (formValues.plant_id) {
        fields.push("plant_id");
      }
      if (formValues.data_type == DATA_TYPE_PCS) {
        fields.push("pcs_id");
      } else if (formValues.data_type == DATA_TYPE_CENSOR) {
        fields.push("censor_id");
      }
      // if (plant?.service_id == SERVICE_ID_SOLAMAME) {
      //   fields.push("insolation");
      // }

      let q = {
        content_type: "csv",
        data_type: formValues.data_type,
        end: formValues.end,
        fields,
        range_type: formValues.range_type,
        start: formValues.start,
        plant_id: formValues.plant_id,
        power_unit: formValues.power_unit,
      };
      if (formValues.plant_id) {
        q["plant_id"] = formValues.plant_id;
      }

      const r = await downloadData(q, "v1");

      // 日本語対応
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, r], { type: "text/csv" });

      // ダウンロードさせる
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const link = document.createElement("a");

      document.body.appendChild(link);

      // link.download = `${plant ? plant.name : "全ての物件"}.csv`;
      link.download = `ダウンロード.csv`;
      link.href = url;
      link.click();

      document.body.removeChild(link);
    } catch (err) {
      setErrorMessage(err);
    } finally {
      setIsLoading(false);
    }

    //
    //       let queryStringParameters = {
    //         data_type: formValues.data_type,
    //         end: formValues.end,
    //         fields,
    //         range_type: formValues.range_type,
    //         // service_id: plant.service_id,
    //         start: formValues.start,
    //         power_unit: powerUnit,
    //         content_type: "csv",
    //       };
    //
    //       if (formValues.plant_id) {
    //         queryStringParameters["plant_id"] = formValues.plant_id;
    //       } else {
    //         queryStringParameters["fields"] = queryStringParameters[
    //           "fields"
    //         ].filter((item) => item != "plant_id");
    //       }
    //
    //       const apiPath = "v0/reports"; // search.indexOf('reports=reports') === -1 ? 'v1/matomete-solar' : 'v0/reports'
    //       const apiType = "matomete.solar";
    //
    //       const r = await API.get(apiType, apiPath, {
    //         headers,
    //         queryStringParameters,
    //         responseType: "arraybuffer",
    //       });
    //
    //       // 日本語対応
    //       const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    //       const blob = new Blob([bom, r], { type: "text/csv" });
    //
    //       // ダウンロードさせる
    //       const url = (window.URL || window.webkitURL).createObjectURL(blob);
    //       const link = document.createElement("a");
    //
    //       document.body.appendChild(link);
    //
    //       link.download = `${_plant ? _plant.name : "全ての物件"}.csv`;
    //       link.href = url;
    //       link.click();
    //
    //       document.body.removeChild(link);
    //     } catch (err) {
    //       if (err.response) {
    //         const error = JSON.parse(
    //           String.fromCharCode.apply(null, new Uint8Array(err.response.data))
    //         );
    //         setErrorMessage(error);
    //       } else {
    //         setErrorMessage(err);
    //       }
    //     }
  };

  const handleChangeParams = (newParams) => {
    const sp = new URLSearchParams(search);
    Object.keys(newParams).forEach((k) => {
      let v = newParams[k];
      if (k == "start" || k == "end") {
        v = format(v, DATE_FORMAT);
      }
      sp.set(k, v);
    });
    return history.push({
      pathname,
      search: `?${new URLSearchParams(sp).toString()}`,
    });
  };

  const updateReport = async (newFormValues, newPlandDataSources) => {
    try {
      let fields = [
        "date_time",
        "power",
        "power_unit",
        "revenue",
        "plant_id",
        "theoretical_revenue",
        "insolation",
        "charged_power",
        "soc",
      ];
      if (newFormValues.data_type == DATA_TYPE_PCS) {
        fields.push("pcs_id");
      } else if (newFormValues.data_type == DATA_TYPE_CENSOR) {
        fields.push("censor_id");
      }

      const ds = newPlandDataSources.filter((item) => {
        return (
          item.range_type == newFormValues.range_type &&
          item.data_type == newFormValues.data_type &&
          item.field_type.includes("power_")
        );
      });

      let pu;
      if (ds.length == 0) {
        pu = POWER_UNIT_KWH;
      } else {
        if (ds[0].field_type == FIELD_TYPE_POWER_A) {
          pu = POWER_UNIT_A;
        } else if (ds[0].field_type == FIELD_TYPE_POWER_KW) {
          pu = POWER_UNIT_KW;
        } else {
          pu = POWER_UNIT_KWH;
        }
      }

      let q = {
        data_type: newFormValues.data_type,
        end: newFormValues.end,
        fields,
        range_type: newFormValues.range_type,
        start: newFormValues.start,
        plant_id: newFormValues.plant_id,
        power_unit: pu,
      };

      const r = await listData(q, "v1");

      // 整形
      setData(
        r.map((item) => {
          let digit;
          if (
            newFormValues.range_type == RANGE_TYPE_RAW ||
            newFormValues.range_type == RANGE_TYPE_HOURLY
          ) {
            digit = 16;
          } else if (newFormValues.range_type == RANGE_TYPE_DAILY) {
            digit = 10;
          } else if (newFormValues.range_type == RANGE_TYPE_MONTHLY) {
            digit = 7;
          } else if (newFormValues.range_type == RANGE_TYPE_YEARLY) {
            digit = 4;
          }
          const date = item.date_time.slice(0, digit).replace("T", " ");

          let name;
          if (item.hasOwnProperty("pcs_id")) {
            let pcsId = item.pcs_id.toString();
            name = /\d+/.test(pcsId) ? `PCS ${pcsId}` : pcsId;
          } else if (item.hasOwnProperty("censor_id")) {
            let censorId = item.censor_id.toString();
            name = /\d+/.test(censorId) ? `センサー ${censorId}` : censorId;
          } else {
            name = "物件全体";
          }

          let d = { date_time: date, name };
          [
            ["charged_power", 2],
            ["insolation", 2],
            ["power_a", 2],
            ["power_kw", 2],
            ["power_kwh", 2],
            ["revenue", 0],
            ["soc", 2],
          ].forEach((_k) => {
            const [_name, _digit] = _k;

            let v = item[_name];
            if (_name == "revenue" && v == null) {
              // 売電収入か理論値か、存在する方を採用する
              v == item.theoretical_revenue;
            }
            d[_name] = v == null ? null : floor(v, _digit);
          });
          return d;
        })
      );

      if (r.length == 0) {
        setErrorMessage("データがありません。");
      }
    } catch (err) {
      setErrorMessage(err);
    }
  };

  const update = async (searchParams) => {
    setErrorMessage(null);
    setIsLoading(true);

    try {
      // QS が最優先で、無ければデフォルトを当てはめる
      const spo = searchParamsToObject(searchParams);

      let newFormValues = {
        data_type: spo.data_type || DATA_TYPE_PLANTS,
        plant_id: spo.plant_id || null,
        range_type: spo.range_type || RANGE_TYPE_HOURLY,
      };

      const now = new Date();

      // パラメータに end があるか
      let end = parse(spo.end, DATE_FORMAT, now);
      if (isNaN(end.getTime())) {
        // 無し
        end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        if (formValues.end) {
          // 前の値があればそれにする
          end = new Date(formValues.end);
        } else {
          // 前の値がなければ当日
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        }
      }
      newFormValues.end = end;

      // パラメータに start があるか
      let start = parse(spo.start, DATE_FORMAT, now);
      if (isNaN(start.getTime())) {
        // 無し
        if (formValues.start) {
          // 前の値があればそれにする
          start = new Date(formValues.start);
        } else {
          let y = now.getFullYear();
          let m = now.getMonth();
          let d = now.getDate();

          if (newFormValues.range_type == RANGE_TYPE_DAILY) {
            d -= 7;
          } else if (newFormValues.range_type == RANGE_TYPE_MONTHLY) {
            m -= 3;
            d = 1;
          } else if (newFormValues.range_type == RANGE_TYPE_YEARLY) {
            m = 1;
            d = 1;
          }
          start = new Date(y, m, d);
        }
      }
      newFormValues.start = start;

      let newPds;
      if (
        newFormValues.plant_id != formValues.plant_id ||
        newFormValues.plant_id == null
      ) {
        // 物件が変わった
        if (!newFormValues.plant_id) {
          // 全体
          // newPlant = null;
          newPds = [
            {
              data_type: DATA_TYPE_PLANTS,
              range_type: RANGE_TYPE_HOURLY,
              field_type: FIELD_TYPE_POWER_KWH,
            },
            {
              data_type: DATA_TYPE_PLANTS,
              range_type: RANGE_TYPE_HOURLY,
              field_type: FIELD_TYPE_REVENUE,
            },
          ];
        } else {
          // 個別
          newPds = (
            await readPlantDataSources(newFormValues.plant_id, {
              queryStringParameters: {
                fields: ["range_type", "data_type", "field_type"],
                limit: 999,
                order: "ASC",
              },
            })
          ).data;
        }
        // setPlant(newPlant);
        setPlantDataSources(newPds);
      } else {
        newPds = plantDataSources;
      }

      let newChartDataType = chartDataType;
      for (let pds of newPds) {
        if (pds.data_type != newFormValues.data_type) continue;

        let matched = false;
        if (pds.range_type == RANGE_TYPE_RAW) {
          matched = true;
        } else if (pds.range_type == RANGE_TYPE_HOURLY) {
          matched = newFormValues.range_type != RANGE_TYPE_RAW;
        } else if (pds.range_type == RANGE_TYPE_DAILY) {
          matched =
            newFormValues.range_type != RANGE_TYPE_RAW &&
            newFormValues.range_type != RANGE_TYPE_HOURLY;
        }
        // monthly, yearly が plant_data_source なことはない
        if (matched === true) {
          newChartDataType = pds.field_type;
          break;
        }
      }

      setChartDataType(newChartDataType);
      setFormValues(newFormValues);
      updateReport(newFormValues, newPds);
    } catch (err) {
      setErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    update(new URLSearchParams(search));
  }, [search]);

  return (
    <>
      <Head>
        <title>発電レポート</title>
      </Head>
      <PlantList
        id={formValues.plant_id}
        onClick={(id) =>
          handleChangeParams({
            plant_id: id || "",
            data_type: "",
            range_type: "",
          })
        }
      />
      <ContentContainer isReport>
        <div className={styles.filter}>
          {formValues.range_type && (
            <RangeTypeButtonGroup
              onClick={(rt) => handleChangeParams({ range_type: rt })}
              plantDataSources={plantDataSources}
              rangeType={formValues.range_type}
              styles={styles}
            />
          )}
          {formValues.end && formValues.start && (
            <DateRangePickerDisplay
              end={formValues.end}
              onClick={(e) => setIsDatePickerOpen(true)}
              start={formValues.start}
              styles={styles}
            />
          )}
        </div>
        <div className={styles.headline}>発電レポート</div>
        <Panel>
          <div className={styles.ctn}>
            <div className={styles.subFiltersList}>
              {plantDataSources && (
                <>
                  <SubFilterChartDataType
                    chartDataType={chartDataType}
                    formValues={formValues}
                    onClick={(cdt) => (e) => setChartDataType(cdt)}
                    plantDataSources={plantDataSources}
                    styles={styles}
                  />
                  <SubFilterDataType
                    dataType={formValues.data_type}
                    onClick={(dt) => handleChangeParams({ data_type: dt })}
                    onClickDownload={downloadReport}
                    plantDataSources={plantDataSources}
                    styles={styles}
                  />
                </>
              )}
            </div>
            {isLoading && <Loading />}
            {errorMessage && <Message message={errorMessage} />}
            {data && data.length > 0 && (
              <ReportContent
                chartDataType={chartDataType}
                data={data}
                formValues={formValues}
                plantDataSources={plantDataSources}
                styles={styles}
              />
            )}
          </div>
        </Panel>
      </ContentContainer>
      {isDatePickerOpen && (
        <DateRangePickerModal
          end={formValues.end}
          onClick={(params) => {
            handleChangeParams(params);
            setIsDatePickerOpen(false);
          }}
          onClickClose={(e) => setIsDatePickerOpen(false)}
          start={formValues.start}
          styles={styles}
        />
      )}
    </>
  );
}

// plant_id: null,
// power_unit: null,

//   console.log(newFv, formValues);

//   let newPlant;

//   // range_type を決める

//   let newRangeType =

//   console.log(newPlant);
//   throw new Error("tmp");

//   let dataTypes = [];
//   let rangeTypes = [];
//   for (let sds of newSds) {
//     if (!dataTypes.includes(sds.data_type)) {
//       dataTypes.push(sds.data_type);
//     }
//     if (!rangeTypes.includes(sds.range_type)) {
//       rangeTypes.push(sds.range_type);
//     }
//   }

//   let dataType = newFv.data_type;
//   if (!dataTypes.includes(dataType)) {
//     dataType = DATA_TYPE_PLANTS;
//   }

//   let rangeType = newFv.range_type;

//   let minRangeType;
//   if (rangeTypes.includes(RANGE_TYPE_RAW)) {
//     minRangeType = RANGE_TYPE_RAW;
//   } else if (rangeTypes.includes(RANGE_TYPE_HOURLY)) {
//     minRangeType = RANGE_TYPE_HOURLY;
//   } else {
//     minRangeType = RANGE_TYPE_DAILY;
//   }

//   // 最小粒度より小さくはできない
//   if (!rangeType) {
//     // 基本は hourly
//     rangeType =
//       minRangeType == RANGE_TYPE_DAILY
//         ? RANGE_TYPE_DAILY
//         : RANGE_TYPE_HOURLY;
//   } else if (
//     rangeType == RANGE_TYPE_RAW &&
//     minRangeType == RANGE_TYPE_HOURLY
//   ) {
//     rangeType = RANGE_TYPE_HOURLY;
//   } else if (
//     rangeType == RANGE_TYPE_RAW &&
//     minRangeType == RANGE_TYPE_DAILY
//   ) {
//     rangeType = RANGE_TYPE_DAILY;
//   } else if (
//     rangeType == RANGE_TYPE_HOURLY &&
//     minRangeType == RANGE_TYPE_DAILY
//   ) {
//     rangeType = RANGE_TYPE_DAILY;
//   }

//   // 日付設定なければ当日
//   const now = new Date();
//   let end = parse(newFv.end, DATE_FORMAT, now);
//   if (isNaN(end.getTime())) {
//     if (formValues.end) {
//       end = new Date(formValues.end);
//     } else {
//       end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
//     }
//   }
//   let start = parse(newFv.start, DATE_FORMAT, now);
//   if (isNaN(start.getTime())) {
//     if (formValues.start) {
//       start = new Date(formValues.start);
//     } else {
//       let dates;
//       if (rangeType == RANGE_TYPE_RAW || rangeType == RANGE_TYPE_HOURLY) {
//         dates = 0;
//       } else {
//         dates = 7;
//       }
//       start = new Date(
//         now.getFullYear(),
//         now.getMonth(),
//         now.getDate() - dates
//       );
//     }
//   }

//   let powerUnit;
//   for (let pds of newPds) {
//     if (
//       pds.data_type == dataType &&
//       (pds.range_type == rangeType || pds.range_type == newMinRangeType)
//     ) {
//       powerUnit = pds.power_unit;
//       break;
//     }
//   }
//   for (let sds of newSds) {
//     if (
//       sds.data_type == dataType &&
//       (sds.range_type == rangeType || sds.range_type == newMinRangeType)
//     ) {
//       powerUnit = sds.power_unit;
//       break;
//     }
//   }

//   const newFormValues = {
//     ...formValues,
//     ...{
//       data_type: dataType,
//       end,
//       min_range_type: newMinRangeType,
//       plant_id: newPlant?.id,
//       power_unit: powerUnit,
//       range_type: rangeType,
//       start,
//     },
//   };
//

// const update = async (searchParams) => {
//   setErrorMessage(null);
//   setIsLoading(true);

//   try {
//     const newFv = searchParamsToObject(searchParams);

//     let newPlant = plant;
//     let newPds;
//     let newSds = serviceDataSources;

//     if (newFv.plant_id != formValues.plant_id || newFv.plant_id == null) {
//       // plant changed

//       if (!newFv.plant_id) {
//         // 全体
//         newPlant = null;
//         newPds = [
//           {
//             data_type: DATA_TYPE_PLANTS,
//             range_type: RANGE_TYPE_HOURLY,
//             field_type: FIELD_TYPE_POWER_KWH,
//           },
//           {
//             data_type: DATA_TYPE_PLANTS,
//             range_type: RANGE_TYPE_HOURLY,
//             field_type: FIELD_TYPE_REVENUE,
//           },
//         ];
//         newSds = [
//           {
//             service_id: null,
//             data_type: DATA_TYPE_PLANTS,
//             range_type: RANGE_TYPE_HOURLY,
//             power_unit: POWER_UNIT_KWH,
//           },
//         ];
//       } else {
//         // 個別
//         newPlant = await getPlant({
//           id: newFv.plant_id,
//           fields: ["id", "name", "pcs", "censor", "service_id"],
//         });
//         newSds = (
//           await readServiceDataSources({
//             serviceId: newPlant.service_id,
//           })
//         ).data;
//         newPds = (
//           await readPlantDataSources(newFv.plant_id, {
//             queryStringParameters: {
//               fields: ["range_type", "data_type", "field_type"],
//               limit: 999,
//             },
//           })
//         ).data;
//       }
//       setPlant(newPlant);
//       setPlantDataSources(newPds);
//       setServiceDataSources(newSds);
//     }

//     let dataTypes = [];
//     let rangeTypes = [];
//     for (let sds of newSds) {
//       if (!dataTypes.includes(sds.data_type)) {
//         dataTypes.push(sds.data_type);
//       }
//       if (!rangeTypes.includes(sds.range_type)) {
//         rangeTypes.push(sds.range_type);
//       }
//     }

//     let dataType = newFv.data_type;
//     if (!dataTypes.includes(dataType)) {
//       dataType = DATA_TYPE_PLANTS;
//     }

//     let rangeType = newFv.range_type;

//     let minRangeType;
//     if (rangeTypes.includes(RANGE_TYPE_RAW)) {
//       minRangeType = RANGE_TYPE_RAW;
//     } else if (rangeTypes.includes(RANGE_TYPE_HOURLY)) {
//       minRangeType = RANGE_TYPE_HOURLY;
//     } else {
//       minRangeType = RANGE_TYPE_DAILY;
//     }

//     // 最小粒度より小さくはできない
//     if (!rangeType) {
//       // 基本は hourly
//       rangeType =
//         minRangeType == RANGE_TYPE_DAILY
//           ? RANGE_TYPE_DAILY
//           : RANGE_TYPE_HOURLY;
//     } else if (
//       rangeType == RANGE_TYPE_RAW &&
//       minRangeType == RANGE_TYPE_HOURLY
//     ) {
//       rangeType = RANGE_TYPE_HOURLY;
//     } else if (
//       rangeType == RANGE_TYPE_RAW &&
//       minRangeType == RANGE_TYPE_DAILY
//     ) {
//       rangeType = RANGE_TYPE_DAILY;
//     } else if (
//       rangeType == RANGE_TYPE_HOURLY &&
//       minRangeType == RANGE_TYPE_DAILY
//     ) {
//       rangeType = RANGE_TYPE_DAILY;
//     }

//     // 日付設定なければ当日
//     const now = new Date();
//     let end = parse(newFv.end, DATE_FORMAT, now);
//     if (isNaN(end.getTime())) {
//       if (formValues.end) {
//         end = new Date(formValues.end);
//       } else {
//         end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
//       }
//     }
//     let start = parse(newFv.start, DATE_FORMAT, now);
//     if (isNaN(start.getTime())) {
//       if (formValues.start) {
//         start = new Date(formValues.start);
//       } else {
//         let dates;
//         if (rangeType == RANGE_TYPE_RAW || rangeType == RANGE_TYPE_HOURLY) {
//           dates = 0;
//         } else {
//           dates = 7;
//         }
//         start = new Date(
//           now.getFullYear(),
//           now.getMonth(),
//           now.getDate() - dates
//         );
//       }
//     }

//     let powerUnit;
//     for (let pds of newPds) {
//       if (
//         pds.data_type == dataType &&
//         (pds.range_type == rangeType || pds.range_type == newMinRangeType)
//       ) {
//         powerUnit = pds.power_unit;
//         break;
//       }
//     }
//     for (let sds of newSds) {
//       if (
//         sds.data_type == dataType &&
//         (sds.range_type == rangeType || sds.range_type == newMinRangeType)
//       ) {
//         powerUnit = sds.power_unit;
//         break;
//       }
//     }

//     const newFormValues = {
//       ...formValues,
//       ...{
//         data_type: dataType,
//         end,
//         min_range_type: newMinRangeType,
//         plant_id: newPlant?.id,
//         power_unit: powerUnit,
//         range_type: rangeType,
//         start,
//       },
//     };
//     setFormValues(newFormValues);
//     updateReport(newFormValues);
//   } catch (err) {
//     setErrorMessage(err);
//   } finally {
//     setIsLoading(false);
//   }
// };

// import { API, Auth } from "aws-amplify";

// import React, { Fragment, useEffect, useRef, useState } from "react";
// import {
//   CartesianGrid,
//   Line,
//   LineChart,
//   Tooltip,
//   XAxis,
//   YAxis,
// } from "recharts";
//
//
//
// import UnitPriceModal from "./reports/UnitPriceModal";
//
//
// import Loading from "./ui/Loading";
// import Message from "./ui/Message";
// import Panel from "./ui/Panel";
//
// import {
//   SERVICE_ID_KINKEI,
//   SERVICE_ID_LEYE,
//   SERVICE_ID_MIELOOOP,
//   SERVICE_ID_MIHARIBAN,
//   SERVICE_ID_MYDELTASOLAR,
//   SERVICE_ID_PVSAFETY,
//   SERVICE_ID_SMARTPV,
//   SERVICE_ID_SOLAMAME,
//   SERVICE_ID_SOLAR_REMON,
//   SERVICE_ID_TABUCHI_CLOUD,
// } from "../constants/services";
// import { getUnitPriceModalClosed } from "../utils/reports";
// import "react-datepicker/dist/react-datepicker-cssmodules.css";
//
// const CHART_COLORS = [
//   "fc5c65",
//   "fd9644",
//   "fed330",
//   "26de81",
//   "2bcbba",
//   "eb3b5a",
//   "fa8231",
//   "f7b731",
//   "20bf6b",
//   "0fb9b1",
//   "45aaf2",
//   "4b7bec",
//   "a55eea",
//   "d1d8e0",
//   "778ca3",
//   "2d98da",
//   "3867d6",
//   "8854d0",
//   "a5b1c2",
//   "4b6584",
// ];
// const DEFAULT_DATES_BEFORE = 0;
// const INITIAL_DATA_KEYS = [""];
// const LINE_STROKE = "#F6EFEF";
//
// const floor = (n, d = 2) => {
//   const _d = 10 ** d;
//   return Math.floor(n * _d) / _d;
// };
//
//
//
//
//
//
//
// /*
// イベントの流れ
// 1. 物件が決まる（全体 OR 特定の物件）
// 2. 表示できるデータタイプが決まる（物件全体のみ|PCSあり|センサーあり）
// 3. search の値を解析
// 4. 必要なパラメータがなければリダイレクト
// 5. レポート取得
//
// 1と2は物件が変更された時のみ
// */
//
// const Report = ({}) => {
//   const chartRef = useRef();
//   const history = useHistory();
//
//
//   const [availableDataType, setAvaiableDataType] = useState();
//   const [borderStyles, setBorderStyles] = useState();
//   const [chartDataV1, setChartDataV1] = useState();
//   const [chartDataType, setChartDataType] = useState(CHART_DATA_TYPE_POWER);
//   const [chartWidth, setChartWidth] = useState(null);
//   const [dataKeys, setDataKeys] = useState(INITIAL_DATA_KEYS);
//   const [dataKeysV1, setDataKeysV1] = useState();
//
//   const [formValues, setFormValues] = useState({
//     data_type: null,
//     end: null,
//     end_obj: null,
//     plant_id: null,
//     range_type: null,
//     start: null,
//     start_obj: null,
//   });
//   const [hasNoUnitPrice, setHasNoUnitPrice] = useState(false);
//   const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasNoHourly, setHasNoHourly] = useState();
//   const [hasRaw, setHasRaw] = useState(false);
//   const [isSolamame, setIsSolamame] = useState(false);
//   const [isSubscribed, setIsSubscribed] = useState(true);
//   const [isUnitPriceModalClosed, setIsUnitPriceModalClosed] = useState(false);
//   const [isUnitPriceModalClosedDone, setIsUnitPriceModalClosedDone] =
//     useState(false);
//
//   const [plantsMap, setPlantsMap] = useState();
//   const [powerUnit, setPowerUnit] = useState(POWER_UNIT_KWH);
//   const [summaries, setSummaries] = useState(null);
//   const [tableData, setTableData] = useState(null);
//
//   const getReport = async (params) => {
//     try {
//       // init
//       setIsLoading(true);
//       setErrorMessage(null);
//       setChartDataV1(null);
//       setHasNoUnitPrice(false);
//       setIsUnitPriceModalClosed(false);
//       setSummaries(null);
//       setTableData(null);
//       setDataKeys(INITIAL_DATA_KEYS);
//
//       const {
//         idToken: { jwtToken },
//       } = await Auth.currentSession();
//       const headers = { Authorization: jwtToken };
//
//       // const plant = plantsMap[params.plant_id]
//
//       let fields = [
//         "date_time",
//         "power",
//         "power_unit",
//         "revenue",
//         "plant_id",
//         "theoretical_revenue",
//         "insolation",
//       ];
//       if (params.data_type == DATA_TYPE_PCS) {
//         fields.push("pcs_id");
//       } else if (params.data_type == DATA_TYPE_CENSOR) {
//         fields.push("censor_id");
//       }
//
//       let promises = [];
//
//       let q = {
//         data_type: params.data_type,
//         end: params.end, // format(params.end, DATE_FORMAT),
//         fields,
//         range_type: params.range_type,
//         // service_id: plant.service_id,
//         start: params.start, // format(params.start, DATE_FORMAT),
//         power_unit: powerUnit,
//       };
//
//       if (params.plant_id) {
//         q["plant_id"] = params.plant_id;
//
//         promises.push(
//           API.get("solsell", `v0/plants/${params.plant_id}`, {
//             headers,
//             queryStringParameters: {
//               fields: ["pcs", "censor"],
//               limit: 1,
//             },
//           })
//         );
//       } else {
//         q["data_type"] = DATA_TYPE_PLANTS;
//         q["fields"] = q["fields"].filter((item) => item != "plant_id");
//       }
//
//       // promises.unshift(
//       //   API.get('solsell', 'v1/matomete-solar', {headers, queryStringParameters: q})
//       // )
//       const apiType = "matomete.solar";
//       const apiPath = "v0/reports"; // search.indexOf('reports=reports') === -1 ? 'v1/matomete-solar' : 'v0/reports'
//       promises.unshift(
//         API.get(apiType, apiPath, { headers, queryStringParameters: q })
//       );
//       const rs = await Promise.all(promises);
//
//       let pcsNamesMap = {};
//       let censorNamesMap = {};
//       if (rs.length > 1) {
//         if (rs[1].pcs) {
//           rs[1].pcs.map((item) => {
//             if (item.name) {
//               pcsNamesMap[item.id] = item.name;
//             }
//           });
//         }
//         if (rs[1].censor) {
//           rs[1].censor.map((item) => {
//             if (item.name) {
//               censorNamesMap[item.id] = item.name;
//             }
//           });
//         }
//       }
//
//       const r = rs[0].data;
//       const c = r.length;
//
//       if (c == 0) {
//         throw new Error("データがありません。");
//       }
//
//       let newPcsIdList = [];
//       let newCensorIdList = [];
//       let newDataKeys = [];
//       let newDataKeysV1 = [];
//       let newChartDataMap = {};
//       let newChartDataV1 = {
//         [CHART_DATA_TYPE_POWER]: {},
//         [CHART_DATA_TYPE_REVENUE]: {},
//         [CHART_DATA_TYPE_INSOLATION]: {},
//       };
//       let newSummaries = { powerSum: 0, revenueSum: 0, insolationSum: 0 };
//       let newTableData = { headers: [] };
//
//       r.map((item) => {
//         // chartData
//         let digit;
//         if (
//           params.range_type == RANGE_TYPE_RAW ||
//           params.range_type == RANGE_TYPE_HOURLY
//         ) {
//           digit = 16;
//         } else if (params.range_type == RANGE_TYPE_DAILY) {
//           digit = 10;
//         } else if (params.range_type == RANGE_TYPE_MONTHLY) {
//           digit = 7;
//         } else if (params.range_type == RANGE_TYPE_YEARLY) {
//           digit = 4;
//         }
//         const date = item.date_time.slice(0, digit).replace("T", " ");
//
//         let dataKeySuffix = "";
//         let __k;
//
//         if (q["data_type"] == DATA_TYPE_PCS) {
//           const pcsName = pcsNamesMap[item.pcs_id.toString()];
//           dataKeySuffix = pcsName ? pcsName : `PCS ${item.pcs_id}`;
//
//           if (newPcsIdList.indexOf(dataKeySuffix) === -1) {
//             newPcsIdList.push(dataKeySuffix);
//           }
//
//           if (newDataKeys.indexOf(dataKeySuffix) === -1) {
//             newDataKeys.push(dataKeySuffix);
//           }
//           __k = dataKeySuffix;
//         } else if (q["data_type"] == DATA_TYPE_CENSOR) {
//           dataKeySuffix = `${item.censor_id}`;
//           if (newCensorIdList.indexOf(item.censor_id) === -1) {
//             newCensorIdList.push(item.censor_id);
//           }
//
//           if (newDataKeys.indexOf(item.censor_id) === -1) {
//             newDataKeys.push(item.censor_id);
//           }
//
//           const censorName = censorNamesMap[item.censor_id.toString()];
//           __k = censorName ? censorName : `センサー ${item.censor_id}`;
//         } else {
//           __k = "物件全体";
//         }
//
//         if (newDataKeysV1.indexOf(__k) === -1) {
//           newDataKeysV1.push(__k);
//         }
//
//         if (!newChartDataMap.hasOwnProperty(date)) {
//           newChartDataMap[date] = { date };
//         }
//
//         const powerKey = `power${dataKeySuffix}`;
//         const revenueKey = `revenue${dataKeySuffix}`;
//         const insolationKey = `insolation${dataKeySuffix}`;
//
//         const data = {
//           [powerKey]: floor(item.power),
//           [revenueKey]: floor(
//             !item.revenue && item.theoretical_revenue
//               ? item.theoretical_revenue
//               : item.revenue,
//             0
//           ),
//           [insolationKey]: floor(item.insolation || 0),
//         };
//
//         newChartDataMap[date][powerKey] = data[powerKey];
//         newChartDataMap[date][revenueKey] = data[revenueKey];
//         newChartDataMap[date][insolationKey] = data[insolationKey];
//
//         Array.from([
//           CHART_DATA_TYPE_POWER,
//           CHART_DATA_TYPE_REVENUE,
//           CHART_DATA_TYPE_INSOLATION,
//         ]).map((k) => {
//           let key = {
//             [CHART_DATA_TYPE_POWER]: powerKey,
//             [CHART_DATA_TYPE_REVENUE]: revenueKey,
//             [CHART_DATA_TYPE_INSOLATION]: insolationKey,
//           }[k];
//           if (!newChartDataV1[k].hasOwnProperty(date)) {
//             newChartDataV1[k][date] = { date };
//           }
//
//           newChartDataV1[k][date][__k] = data[key];
//         });
//
//         // summaries
//         newSummaries.powerSum += data[powerKey] || 0;
//         newSummaries.revenueSum += data[revenueKey] || 0;
//         newSummaries.insolationSum += data[insolationKey] || 0;
//
//         // tableData
//         if (newTableData.headers.indexOf(date) === -1) {
//           newTableData.headers.push(date);
//         }
//         if (!newTableData.hasOwnProperty(powerKey)) {
//           newTableData[powerKey] = [];
//           newTableData[revenueKey] = [];
//           newTableData[insolationKey] = [];
//         }
//         newTableData[powerKey].push(data[powerKey] || 0);
//         newTableData[revenueKey].push(data[revenueKey] || 0);
//         newTableData[insolationKey].push(data[insolationKey] || 0);
//       });
//
//       if (newSummaries.revenueSum == 0) {
//         setHasNoUnitPrice(true);
//       }
//
//       if (isSubscribed) {
//         newDataKeys = newDataKeys.sort((a, b) => {
//           if (a == b) {
//             return 0;
//           }
//           return a > b ? 1 : -1;
//         });
//         newDataKeysV1 = newDataKeysV1.sort((a, b) => {
//           let _a = Number(a.replace(/(PCS |センサー )/, ""));
//           if (isNaN(_a)) {
//             _a = a;
//           }
//           let _b = Number(b.replace(/(PCS |センサー )/, ""));
//           if (isNaN(_b)) {
//             _b = b;
//           }
//           if (_a == _b) {
//             return 0;
//           }
//           return _a > _b ? 1 : -1;
//         });
//         setDataKeys(newDataKeys.length > 0 ? newDataKeys : INITIAL_DATA_KEYS);
//         setDataKeysV1(newDataKeysV1);
//
//         // setChartData(Object.values(newChartDataMap))
//         setChartDataV1(newChartDataV1);
//
//         newSummaries["powerAvg"] = newSummaries.powerSum / c;
//         newSummaries["revenueAvg"] = newSummaries.revenueSum / c;
//         newSummaries["insolationAvg"] = newSummaries.insolationSum / c;
//         setSummaries(newSummaries);
//
//         setTableData(newTableData);
//       }
//       setIsLoading(false);
//     } catch (err) {
//       setIsLoading(false);
//       setErrorMessage(err);
//     }
//   };
//
//   const handleChange = (name, value) => (e) => {
//     let newFormValues = { ...formValues, [name]: value };
//
//     setFormValues(newFormValues);
//
//     return redirect(newFormValues);
//   };
//
//   const handleChangeDate = (typ) => (date) => {
//     setFormValues({ ...formValues, [typ]: date });
//   };
//
//   const handleClickApplyDates = (e) => {
//     let newFormValues = {
//       ...formValues,
//       ...{
//         end: format(formValues.end_obj, DATE_FORMAT),
//         start: format(formValues.start_obj, DATE_FORMAT),
//       },
//     };
//
//     setFormValues(newFormValues);
//     setIsDatePickerOpen(false);
//     return redirect(newFormValues);
//   };
//
//   const handleClickDateInput = (e) => {
//     setIsDatePickerOpen(true);
//   };
//
//   const handleClickDatePickerOuter = (e) => {
//     setIsDatePickerOpen(false);
//   };
//
//   const handleClickDownload = async (e) => {
//     setIsLoading(true);
//     setErrorMessage(null);
//
//     try {
//       const {
//         idToken: { jwtToken },
//       } = await Auth.currentSession();
//       const headers = { Authorization: jwtToken };
//
//       const _plant = plantsMap[formValues.plant_id];
//
//       let fields = [
//         "date_time",
//         "power",
//         "revenue",
//         "plant_id",
//         "theoretical_revenue",
//       ];
//       if (formValues.data_type == DATA_TYPE_PCS) {
//         fields.push("pcs_id");
//       } else if (formValues.data_type == DATA_TYPE_CENSOR) {
//         fields.push("censor_id");
//       }
//
//       if (isSolamame) {
//         fields.push("insolation");
//       }
//
//       let queryStringParameters = {
//         data_type: formValues.data_type,
//         end: formValues.end,
//         fields,
//         range_type: formValues.range_type,
//         // service_id: plant.service_id,
//         start: formValues.start,
//         power_unit: powerUnit,
//         content_type: "csv",
//       };
//
//       if (formValues.plant_id) {
//         queryStringParameters["plant_id"] = formValues.plant_id;
//       } else {
//         queryStringParameters["fields"] = queryStringParameters[
//           "fields"
//         ].filter((item) => item != "plant_id");
//       }
//
//       const apiPath = "v0/reports"; // search.indexOf('reports=reports') === -1 ? 'v1/matomete-solar' : 'v0/reports'
//       const apiType = "matomete.solar";
//
//       const r = await API.get(apiType, apiPath, {
//         headers,
//         queryStringParameters,
//         responseType: "arraybuffer",
//       });
//
//       // 日本語対応
//       const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
//       const blob = new Blob([bom, r], { type: "text/csv" });
//
//       // ダウンロードさせる
//       const url = (window.URL || window.webkitURL).createObjectURL(blob);
//       const link = document.createElement("a");
//
//       document.body.appendChild(link);
//
//       link.download = `${_plant ? _plant.name : "全ての物件"}.csv`;
//       link.href = url;
//       link.click();
//
//       document.body.removeChild(link);
//     } catch (err) {
//       if (err.response) {
//         const error = JSON.parse(
//           String.fromCharCode.apply(null, new Uint8Array(err.response.data))
//         );
//         setErrorMessage(error);
//       } else {
//         setErrorMessage(err);
//       }
//     }
//
//     setIsLoading(false);
//   };
//
//   const handleClickFilterChartDataType = (typ) => (e) => {
//     setChartDataType(typ);
//   };
//
//   const handleClickToggleUnitPriceModal = (e) => {
//     setIsUnitPriceModalClosed(!isUnitPriceModalClosed);
//   };
//
//   const handleLoadPlantList = (items) => {
//     let pm = {};
//     items.map((item) => {
//       pm[item.id.toString()] = item;
//     });
//
//     setPlantsMap(pm);
//   };
//
//   const redirect = (values) => {
//     let q = {};
//     for (const k of SEARCH_PARAM_KEYS) {
//       const v = values[k];
//       if (v) {
//         q[k] = v;
//       }
//     }
//
//     return history.push({
//       pathname,
//       search: `?${new URLSearchParams(q).toString()}`,
//     });
//   };
//
//
//
//   const updateBorderStyles = (keys) => {
//     let borders = [];
//     keys.map((k, i) => {
//       borders.push(
//         `.${styles.fixedColumnTableRow}:nth-child(${i + 2}) .${
//           styles.fixedColumnTableCellWithBorder
//         }:before {
//           background-color: #${CHART_COLORS[i]};
//         }`
//       );
//     });
//     setBorderStyles(borders.join("\n"));
//   };
//
//   const updateReport = () => {
//     console.log(search);
//     const params = new URLSearchParams(search);
//
//     const plantId = params.get("plant_id"); // 今の物件
//     const plantIdPre = formValues.plant_id; // これまでの物件
//     // let powerUnit = null;
//
//     if (plantIdPre != plantId || typeof plant == "undefined") {
//       // 物件が新しくなった
//       setAvaiableDataType();
//       setHasNoHourly();
//       setPowerUnit(POWER_UNIT_KWH);
//
//       if (plantId == null) {
//         // 全体
//         setPlant(null);
//         setAvaiableDataType(DATA_TYPE_PLANTS);
//         setHasNoHourly(false);
//       } else {
//         let plant = plantsMap[plantId];
//         setPlant(plant);
//
//         if (plant.service_id == SERVICE_ID_SOLAR_REMON) {
//           setPowerUnit(POWER_UNIT_A);
//         } else if (plant.service_id == SERVICE_ID_MYDELTASOLAR) {
//           setPowerUnit(POWER_UNIT_KW);
//         } else {
//           setPowerUnit(POWER_UNIT_KWH);
//         }
//         updateAvailableDataType(plant.id);
//       }
//     }
//
//     if (typeof hasNoHourly == "undefined") {
//       return;
//     }
//
//     let shouldBeRedirected = false;
//
//     let dataType = params.get("data_type");
//     if (!dataType) {
//       shouldBeRedirected = true;
//
//       if (formValues.data_type) {
//         if (formValues.plant_id == null && plantId) {
//           // dataType = availableDataType == DATA_TYPE_PCS ? DATA_TYPE_PCS : DATA_TYPE_CENSOR
//           dataType = availableDataType || DATA_TYPE_PLANTS;
//         } else {
//           dataType = formValues.data_type;
//         }
//       } else if (!plantId) {
//         dataType = DATA_TYPE_PLANTS;
//       } else {
//         // dataType = availableDataType == DATA_TYPE_PCS ? DATA_TYPE_PCS : DATA_TYPE_CENSOR
//         dataType = availableDataType || DATA_TYPE_PLANTS;
//       }
//     } else if (
//       dataType == DATA_TYPE_CENSOR &&
//       availableDataType == DATA_TYPE_PCS
//     ) {
//       shouldBeRedirected = true;
//
//       dataType = plantId ? DATA_TYPE_PCS : DATA_TYPE_PLANTS;
//     } else if (
//       dataType == DATA_TYPE_PCS &&
//       availableDataType == DATA_TYPE_CENSOR
//     ) {
//       shouldBeRedirected = true;
//
//       dataType = plantId ? DATA_TYPE_CENSOR : DATA_TYPE_PLANTS;
//     }
//
//     const now = new Date();
//
//     let end = params.get("end");
//     let endObj = parse(end, DATE_FORMAT, now);
//     if (isNaN(endObj.getTime())) {
//       shouldBeRedirected = true;
//
//       if (formValues.end) {
//         end = formValues.end;
//         endObj = new Date(formValues.end);
//       } else {
//         endObj = new Date(now.getFullYear(), now.getMonth(), now.getDate());
//         end = format(endObj, DATE_FORMAT);
//       }
//     }
//
//     let start = params.get("start");
//     let startObj = parse(start, DATE_FORMAT, now);
//
//     let rangeType = params.get("range_type");
//
//     if (
//       hasNoHourly === true &&
//       (rangeType == RANGE_TYPE_HOURLY || rangeType == null)
//     ) {
//       shouldBeRedirected = true;
//
//       startObj = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
//       start = format(startObj, DATE_FORMAT);
//
//       rangeType = RANGE_TYPE_DAILY;
//     } else if (isNaN(startObj.getTime())) {
//       shouldBeRedirected = true;
//
//       if (formValues.start) {
//         start = formValues.start;
//         startObj = new Date(formValues.start);
//       } else {
//         startObj = new Date(
//           now.getFullYear(),
//           now.getMonth(),
//           now.getDate() - DEFAULT_DATES_BEFORE
//         );
//         start = format(startObj, DATE_FORMAT);
//       }
//     }
//     if (!rangeType) {
//       shouldBeRedirected = true;
//
//       rangeType = formValues.range_type || RANGE_TYPE_HOURLY;
//     }
//
//     const newFormValues = {
//       plant_id: plantId,
//       data_type: dataType,
//       end,
//       end_obj: endObj,
//       start,
//       start_obj: startObj,
//       range_type: rangeType,
//     };
//
//     setFormValues(newFormValues);
//
//     // 必要なパラメータが揃っていなければリダイレクト
//     if (shouldBeRedirected) {
//       return redirect(newFormValues);
//     }
//
//     // 必要なパラメータが全て揃っていればレポート取得
//     getReport(newFormValues);
//   };
//
//   useEffect(() => {
//     setChartWidth(chartRef.current.clientWidth);
//   }, []);
//
//   useEffect(() => {
//     updateBorderStyles(dataKeys);
//   }, [dataKeys]);
//
//   useEffect(() => {
//     // plantsMap が無い場合の対応
//     if (typeof plantsMap == "undefined") {
//       return;
//     }
//
//     setIsUnitPriceModalClosedDone(getUnitPriceModalClosed());
//     updateReport();
//   }, [availableDataType, hasNoHourly, plantsMap, search]);
//
//   useEffect(() => {
//     if (!plant) {
//       return;
//     }
//
//     const newIsSolamame =
//       plant && plant.service_id.toString() == SERVICE_ID_SOLAMAME;
//     setIsSolamame(newIsSolamame);
//
//     if (!newIsSolamame && chartDataType == CHART_DATA_TYPE_INSOLATION) {
//       setChartDataType(CHART_DATA_TYPE_POWER);
//     }
//
//     // updateAvailableDataType(plant.id)
//   }, [plant]);
//
//   return (
//     <Fragment>
//       <Head>
//         <title>発電レポート</title>
//         <style>{`
//           ${borderStyles ? borderStyles : ""}
//         `}</style>
//       </Head>
//       <FilterMenuPlant
//         id={formValues.plant_id}
//         onLoadList={handleLoadPlantList}
//       />
//
//
//         </div>
//         <div className={styles.headline}>発電レポート</div>
//         <Panel>
//           <div className={styles.ctn}>
//             <div className={styles.subFiltersList}>
//               <div className={styles.subFilters}>
//                 <div
//                   className={`${styles.subFilterRadio} ${
//                     chartDataType == CHART_DATA_TYPE_POWER ? styles.checked : ""
//                   }`}
//                   onClick={handleClickFilterChartDataType(
//                     CHART_DATA_TYPE_POWER
//                   )}
//                 >
//                   <div className={styles.subFilterRadioInput}>
//                     <div className={styles.subFilterRadioInputInner} />
//                   </div>
//                   <div>発電量</div>
//                 </div>
//                 {!hasNoUnitPrice && (
//                   <div
//                     className={`${styles.subFilterRadio} ${
//                       chartDataType == CHART_DATA_TYPE_REVENUE
//                         ? styles.checked
//                         : ""
//                     }`}
//                     onClick={handleClickFilterChartDataType(
//                       CHART_DATA_TYPE_REVENUE
//                     )}
//                   >
//                     <div className={styles.subFilterRadioInput}>
//                       <div className={styles.subFilterRadioInputInner} />
//                     </div>
//                     <div>売電収入</div>
//                   </div>
//                 )}
//                 {isSolamame && (
//                   <div
//                     className={`${styles.subFilterRadio} ${
//                       chartDataType == CHART_DATA_TYPE_INSOLATION
//                         ? styles.checked
//                         : ""
//                     }`}
//                     onClick={handleClickFilterChartDataType(
//                       CHART_DATA_TYPE_INSOLATION
//                     )}
//                   >
//                     <div className={styles.subFilterRadioInput}>
//                       <div className={styles.subFilterRadioInputInner} />
//                     </div>
//                     <div>日射強度</div>
//                   </div>
//                 )}
//               </div>
//               <div className={styles.subFilters}>
//                 {formValues.plant_id && (
//                   <Fragment>
//                     <div
//                       className={`${styles.subFilterRadio} ${
//                         formValues.data_type == DATA_TYPE_PLANTS
//                           ? styles.checked
//                           : ""
//                       }`}
//                       onClick={handleChange("data_type", DATA_TYPE_PLANTS)}
//                     >
//                       <div className={styles.subFilterRadioInput}>
//                         <div className={styles.subFilterRadioInputInner} />
//                       </div>
//                       <div>物件全体</div>
//                     </div>
//                     {availableDataType == DATA_TYPE_PCS && (
//                       <div
//                         className={`${styles.subFilterRadio} ${
//                           formValues.data_type == DATA_TYPE_PCS
//                             ? styles.checked
//                             : ""
//                         }`}
//                         onClick={handleChange("data_type", DATA_TYPE_PCS)}
//                       >
//                         <div className={styles.subFilterRadioInput}>
//                           <div className={styles.subFilterRadioInputInner} />
//                         </div>
//                         <div>パワコンごと</div>
//                       </div>
//                     )}
//                     {availableDataType == DATA_TYPE_CENSOR && (
//                       <div
//                         className={`${styles.subFilterRadio} ${
//                           formValues.data_type == DATA_TYPE_CENSOR
//                             ? styles.checked
//                             : ""
//                         }`}
//                         onClick={handleChange("data_type", DATA_TYPE_CENSOR)}
//                       >
//                         <div className={styles.subFilterRadioInput}>
//                           <div className={styles.subFilterRadioInputInner} />
//                         </div>
//                         <div>センサーごと</div>
//                       </div>
//                     )}
//                   </Fragment>
//                 )}
//                 <button
//                   className={styles.btnExport}
//                   onClick={handleClickDownload}
//                 >
//                   <img src={`/matomete-solar/img/btn-export.png`} />
//                 </button>
//               </div>
//             </div>
//             {isLoading && <Loading />}
//             {errorMessage && <Message message={errorMessage} />}
//             <div ref={chartRef}>
//               {chartDataV1 && (
//                 <Fragment>
//                   {chartDataV1[chartDataType] && (
//                     <div className={styles.chartCtn}>
//                       <LineChart
//                         data={Object.values(chartDataV1[chartDataType])}
//                         height={230}
//                         margin={{ left: 0, right: 40 }}
//                         width={chartWidth}
//                       >
//                         <CartesianGrid stroke={LINE_STROKE} />
//                         {dataKeysV1.map((k, i) => {
//                           return (
//                             <Line
//                               dataKey={k /*`${chartDataType}${k}`*/}
//                               key={i}
//                               stroke={`#${
//                                 CHART_COLORS[i % CHART_COLORS.length]
//                               }`}
//                               type="monotone"
//                             />
//                           );
//                         })}
//                         <Tooltip
//                           formatter={(value, name, props) =>
//                             chartDataType == CHART_DATA_TYPE_POWER
//                               ? `${value.toLocaleString()}${powerUnit}`
//                               : `¥${value.toLocaleString()}`
//                           }
//                         />
//                         <XAxis
//                           axisLine={{ stroke: LINE_STROKE }}
//                           dataKey="date"
//                           dy={10}
//                           tickLine={{ stroke: LINE_STROKE }}
//                         />
//                         <YAxis
//                           axisLine={{ stroke: LINE_STROKE }}
//                           tickLine={{ stroke: LINE_STROKE }}
//                         />
//                       </LineChart>
//                     </div>
//                   )}
//                   {tableData && (
//
//                   )}
//                   {summaries && (
//                     <div className={styles.summaries}>
//                       {powerUnit == POWER_UNIT_KWH && (
//                         <div className={`${styles.summary} ${styles.blue}`}>
//                           <div className={styles.summaryIcon}>
//                             <img
//                               className={styles.summaryIconKwhPlus}
//                               src={`/matomete-solar/img/icon-summary-kwh-sum.png`}
//                             />
//                           </div>
//                           <div className={styles.summaryResults}>
//                             <div className={styles.summaryResultsText}>
//                               <div>発電量_合計</div>
//                               <div className={styles.summaryResultsTextNumber}>
//                                 {(
//                                   Math.floor(summaries.powerSum * 100) / 100
//                                 ).toLocaleString()}
//                                 <span className={styles.summaryResultsTextUnit}>
//                                   {powerUnit}
//                                 </span>
//                               </div>
//                             </div>
//                             <div className={styles.summaryResultsBar}>
//                               <div
//                                 className={styles.summaryResultsBarInner}
//                                 style={{ width: "100%" }}
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       )}
//                       <div className={`${styles.summary} ${styles.blue}`}>
//                         <div className={styles.summaryIcon}>
//                           <img
//                             className={styles.summaryIconKwh}
//                             src={`/matomete-solar/img/icon-summary-kwh-avg.png`}
//                           />
//                         </div>
//                         <div className={styles.summaryResults}>
//                           <div className={styles.summaryResultsText}>
//                             <div>発電量_平均</div>
//                             <div className={styles.summaryResultsTextNumber}>
//                               {(
//                                 Math.floor(summaries.powerAvg * 100) / 100
//                               ).toLocaleString()}
//                               <span className={styles.summaryResultsTextUnit}>
//                                 {powerUnit}
//                               </span>
//                             </div>
//                           </div>
//                           <div className={styles.summaryResultsBar}>
//                             <div
//                               className={styles.summaryResultsBarInner}
//                               style={{ width: "100%" }}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                       {powerUnit == POWER_UNIT_KWH &&
//                         summaries.revenueSum > 0 && (
//                           <div className={`${styles.summary} ${styles.green}`}>
//                             <div className={styles.summaryIcon}>
//                               <img
//                                 className={styles.summaryIconRevenuePlus}
//                                 src={`/matomete-solar/img/icon-summary-revenue-sum.png`}
//                               />
//                             </div>
//                             <div className={styles.summaryResults}>
//                               <div className={styles.summaryResultsText}>
//                                 <div>収入_合計</div>
//                                 <div
//                                   className={styles.summaryResultsTextNumber}
//                                 >
//                                   {Math.floor(
//                                     summaries.revenueSum
//                                   ).toLocaleString()}
//                                   <span
//                                     className={styles.summaryResultsTextUnit}
//                                   >
//                                     円
//                                   </span>
//                                 </div>
//                               </div>
//                               <div className={styles.summaryResultsBar}>
//                                 <div
//                                   className={styles.summaryResultsBarInner}
//                                   style={{ width: "100%" }}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         )}
//                       {powerUnit == POWER_UNIT_KWH &&
//                         summaries.revenueAvg > 0 && (
//                           <div className={`${styles.summary} ${styles.green}`}>
//                             <div className={styles.summaryIcon}>
//                               <img
//                                 className={styles.summaryIconRevenue}
//                                 src={`/matomete-solar/img/icon-summary-revenue-avg.png`}
//                               />
//                             </div>
//                             <div className={styles.summaryResults}>
//                               <div className={styles.summaryResultsText}>
//                                 <div>収入_平均</div>
//                                 <div
//                                   className={styles.summaryResultsTextNumber}
//                                 >
//                                   {Math.floor(
//                                     summaries.revenueAvg
//                                   ).toLocaleString()}
//                                   <span
//                                     className={styles.summaryResultsTextUnit}
//                                   >
//                                     円
//                                   </span>
//                                 </div>
//                               </div>
//                               <div className={styles.summaryResultsBar}>
//                                 <div
//                                   className={styles.summaryResultsBarInner}
//                                   style={{ width: "100%" }}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         )}
//                     </div>
//                   )}
//                 </Fragment>
//               )}
//             </div>
//           </div>
//         </Panel>
//       </ContentContainer>
//
//       {hasNoUnitPrice === true && isUnitPriceModalClosedDone === false && (
//         <UnitPriceModal
//           isClosed={isUnitPriceModalClosed}
//           onClick={handleClickToggleUnitPriceModal}
//           plantId={formValues.plant_id}
//         />
//       )}
//     </Fragment>
//   );
// };
//
// export default Report;
//

const searchParamsToObject = (params) =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if (acc.hasOwnProperty(key)) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [acc[key], val];
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }

    return acc;
  }, {});
