import {API, Auth} from 'aws-amplify'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'
import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts'
import {DATA_TYPE_PLANTS, DATE_FORMAT} from '../../constants/reports'
import styles from '../../styles/simulations/Income.less'

const KEYS = ['start', 'end', 'plant_ids']
const LINE_STROKE = '#F6EFEF'

const Income = () => {
  const {search} = useLocation()
  const chartRef = useRef()

  const [chartData, setChartData] = useState()
  const [chartWidth, setChartWidth] = useState()
  const {formValues, setFormValues} = useState()
  
  const getSimulation = async params => {
    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken}

      let queryStringParameters = {
        end: format(params.end, DATE_FORMAT),
        // fields,
        range_type: 'daily',
        start: format(params.start, DATE_FORMAT),
      }

      const dates = eachDayOfInterval(params)

      if(params.plant_ids) {
        queryStringParameters['plant_ids'] = params.plant_ids
      }

      const rs = await Promise.all([
        API.get('solsell', 'v0/simulators/power-generation', {headers, queryStringParameters}),
        API.get('solsell', 'v1/matomete-solar', {headers, queryStringParameters: {
          ...queryStringParameters, ...{
            fields: ['date_time', 'power', 'revenue', 'plant_id'],
            data_type: DATA_TYPE_PLANTS
          }
        }}),
      ])

      // const r = {"data": [{"power": 55.78103999999999, "revenue": 840.5524799999998, "month": 8, "day": 29}, {"power": 134.316, "revenue": 2028.6719999999998, "month": 8, "day": 30}, {"power": 182.21904, "revenue": 2700.7084800000002, "month": 8, "day": 31}, {"power": 182.2794, "revenue": 2701.1628, "month": 9, "day": 1}, {"power": 36.86544, "revenue": 557.94528, "month": 9, "day": 2}, {"power": 122.63387999999999, "revenue": 1836.9165599999997, "month": 9, "day": 3}, {"power": 41.687039999999996, "revenue": 621.20448, "month": 9, "day": 4}]}

      const now = new Date()

      let simulationDatesMap = {}
      rs[0].data.map(item => {
        simulationDatesMap[`${item.month}-${item.day}`] = item
      })

      let reportDatesMap = {}
      rs[1].map(item => {
        const dt = new Date(item.date_time)
        reportDatesMap[`${dt.getMonth() + 1}-${dt.getDate()}`] = item
      })

      let newChartData = []
      dates.map(item => {
        const k = `${item.getMonth() + 1}-${item.getDate()}`

        const target = item < now ? reportDatesMap : simulationDatesMap
        if(target.hasOwnProperty(k)) {
          newChartData.push({...target[k], date: format(item, 'yyyy-MM-dd')})
        }
      })

      setChartData(newChartData)
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setChartWidth(chartRef.current.clientWidth)
  }, [])

  useEffect(() => {
    const now = new Date()
    const sp = new URLSearchParams(search)

    let newFormValues = {}
    KEYS.map(k => {
      let v = sp.get(k)
      if((k == 'start' || k == 'end') && v) {
        v = new Date(v)
        if(v.toString() === 'Invalid Date') {
          v = null
        }
      }
      newFormValues[k] = v
    })

    if(!newFormValues['start']) {
      newFormValues['start'] = startOfWeek(now)
    }

    if(!newFormValues['end']) {
      newFormValues['end'] = endOfWeek(now)
    }
    getSimulation(newFormValues)
  }, [search])

  return (
    <div className={styles.chartCtn}>
      <div ref={chartRef}>
        {(chartData && chartWidth) &&
          <div>
            <LineChart
              data={chartData}
              height={230}
              margin={{left: 0, right: 40}}
              width={chartWidth}
            >
              <CartesianGrid stroke={LINE_STROKE}/>
              <Line
                dataKey={'revenue'}
                stroke={`#fc5c65`}
                type="monotone"
              />
              <Tooltip formatter={(value, name, props) => 
                `¥${value.toLocaleString()}`
              }/>
              <XAxis
                axisLine={{stroke: LINE_STROKE}}
                dataKey="date"
                dy={10}
                tickLine={{stroke: LINE_STROKE}}
              />
              <YAxis
                axisLine={{stroke: LINE_STROKE}}

                tickLine={{stroke: LINE_STROKE}}
              />
            </LineChart>
          </div>
        }
      </div>
    </div>
  )
}

export default Income
