import React from 'react'
import {
  SERVICE_ID_SMARTPV,
  SERVICE_ID_SOLAR_EDGE,
} from '../../constants/services'

const Param1Input = ({onChange, service, serviceId, styles, value}) => {
  if(!service.param_1_in_use) {
    return null
  }

  return (
    <div className={styles.formGroupRowItem}>
      <label className={styles.inlineLabel}>{service.param_1_label}</label>
      <input
        className={`${styles.inputText} ${styles.inputTextInline}`}
        name="param_1"
        onChange={onChange}
        placeholder={`${service.param_1_label} を入力`}
        type="text"
        value={value}
      />
    </div>
  )
}

export default Param1Input
