import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  FIELD_TYPE_POWER_A,
  FIELD_TYPE_POWER_KW,
  FIELD_TYPE_POWER_KWH,
  FIELD_TYPE_REVENUE,
  FIELD_TYPE_INSOLATION,
  FIELD_TYPE_CHARGED_POWER,
  FIELD_TYPE_SOC,
  RANGE_TYPE_RAW,
  RANGE_TYPE_HOURLY,
  RANGE_TYPE_DAILY,
  RANGE_TYPE_MONTHLY,
  RANGE_TYPE_YEARLY,
} from "../../constants/reports";

const CHART_COLORS = [
  "#fc5c65",
  "#fd9644",
  "#fed330",
  "#26de81",
  "#2bcbba",
  "#eb3b5a",
  "#fa8231",
  "#f7b731",
  "#20bf6b",
  "#0fb9b1",
  "#45aaf2",
  "#4b7bec",
  "#a55eea",
  "#d1d8e0",
  "#778ca3",
  "#2d98da",
  "#3867d6",
  "#8854d0",
  "#a5b1c2",
  "#4b6584",
];
const CHART_COLOR_LENGTH = CHART_COLORS.length;
const LINE_STROKE = "#F6EFEF";

export default function Chart({ chartDataType, data, formValues, styles }) {
  if (!chartDataType) return null;

  const [chartData, setChartData] = React.useState([]);
  const [dataKeys, setDataKeys] = React.useState([]);
  const [dataMap, setDataMap] = React.useState(null);

  React.useEffect(() => {
    let newDataMap = {};
    let newDataKeys = [];
    data.forEach((item) => {
      if (!newDataMap.hasOwnProperty(item.date_time)) {
        newDataMap[item.date_time] = {};
      }
      // let dataKey;
      // if (item.hasOwnProperty("pcs_id")) {
      //   let pcsId = item.pcs_id.toString();
      //   dataKey = /\d+/.test(pcsId) ? `PCS ${pcsId}` : pcsId;
      // } else if (item.hasOwnProperty("censor_id")) {
      //   let censorId = item.censor_id.toString();
      //   dataKey = /\d+/.test(censorId) ? `センサー ${censorId}` : censorId;
      // } else {
      //   dataKey = "物件全体";
      // }
      if (!newDataKeys.includes(item.name)) {
        newDataKeys.push(item.name);
      }

      Object.keys(item).forEach((k) => {
        if (!newDataMap[item.date_time].hasOwnProperty(k)) {
          newDataMap[item.date_time][k] = {};
        }
        newDataMap[item.date_time][k][item.name] = item[k];
      });
    });
    setDataMap(newDataMap);
    setDataKeys(newDataKeys);
  }, [data]);

  React.useEffect(() => {
    if (!dataMap || !formValues.range_type) return;

    // let digit;
    // if (
    //   formValues.range_type == RANGE_TYPE_RAW ||
    //   formValues.range_type == RANGE_TYPE_HOURLY
    // ) {
    //   digit = 16;
    // } else if (formValues.range_type == RANGE_TYPE_DAILY) {
    //   digit = 10;
    // } else if (formValues.range_type == RANGE_TYPE_MONTHLY) {
    //   digit = 7;
    // } else if (formValues.range_type == RANGE_TYPE_YEARLY) {
    //   digit = 4;
    // }

    const newData = Object.keys(dataMap).map((k) => {
      // const date = k.slice(0, digit).replace("T", " ");
      return {
        ...dataMap[k][chartDataType],
        // date,
      };
    });
    setChartData(newData);
  }, [chartDataType, dataMap, formValues]);

  if (chartData.length == 0) return null;

  return (
    <>
      <div className={styles.chartCtn}>
        <ResponsiveContainer width="100%" height={230}>
          <LineChart data={chartData} margin={{ left: 0, right: 40 }}>
            <CartesianGrid stroke={LINE_STROKE} />
            {dataKeys.map((k, i) => {
              return (
                <Line
                  dataKey={k}
                  key={i}
                  stroke={CHART_COLORS[i % CHART_COLOR_LENGTH]}
                  type="monotone"
                />
              );
            })}
            <Tooltip
              formatter={(value) => {
                const v = value.toLocaleString();
                if (chartDataType == FIELD_TYPE_POWER_A) {
                  return `${v} A`;
                } else if (chartDataType == FIELD_TYPE_POWER_KW) {
                  return `${v} kW`;
                } else if (chartDataType == FIELD_TYPE_POWER_KWH) {
                  return `${v} kWh`;
                } else if (chartDataType == FIELD_TYPE_REVENUE) {
                  return `¥${v}`;
                } else if (chartDataType == FIELD_TYPE_INSOLATION) {
                  return `${v} kW/㎡`;
                } else if (chartDataType == FIELD_TYPE_CHARGED_POWER) {
                  return `${v} kW`;
                } else if (chartDataType == FIELD_TYPE_SOC) {
                  return `${v} %`;
                }
              }}
            />
            <XAxis
              axisLine={{ stroke: LINE_STROKE }}
              dataKey="date_time"
              dy={10}
              tickLine={{ stroke: LINE_STROKE }}
            />
            <YAxis
              axisLine={{ stroke: LINE_STROKE }}
              tickLine={{ stroke: LINE_STROKE }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
