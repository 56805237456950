import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import styles from '../../styles/ui/Navbar.less'

const Navbar = () => {
  const {pathname} = useLocation()

  return (
    <nav className={styles.navbar}>
      <div className={`${styles.logo} ${styles.beta}`}>
        <img src="/matomete-solar/img/logo-beta.png"/>
      </div>
      <ul>
        <li>
          <NavLink activeClassName={styles.active} exact to="/">
            <img className={styles.on} src="/matomete-solar/img/icon-dashboard-on.png"/>
            <img className={styles.off} src="/matomete-solar/img/icon-dashboard-off.png"/>
            <div>ダッシュボード</div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={styles.active} exact to="/reports">
            <img className={styles.on} src="/matomete-solar/img/icon-report-on.png"/>
            <img className={styles.off} src="/matomete-solar/img/icon-report-off.png"/>
            <div>発電レポート</div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={styles.active} to="/alerts">
            <img className={styles.on} src="/matomete-solar/img/icon-alert-on.png"/>
            <img className={styles.off} src="/matomete-solar/img/icon-alert-off.png"/>
            <div>アラート</div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={styles.active} to="/plants">
            <img className={styles.on} src="/matomete-solar/img/icon-plant-on.png"/>
            <img className={styles.off} src="/matomete-solar/img/icon-plant-off.png"/>
            <div>物件管理</div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName={styles.active} to="/user-groups">
            <img className={styles.on} src="/matomete-solar/img/icon-account-on.png"/>
            <img className={styles.off} src="/matomete-solar/img/icon-account-off.png"/>
            <div>アカウント管理</div>
          </NavLink>
        </li>
        {/*<li>
          <NavLink activeClassName={styles.active} to="/simulations/income">
            <img className={styles.on} src="/matomete-solar/img/icon-account-on.png"/>
            <img className={styles.off} src="/matomete-solar/img/icon-account-off.png"/>
            <div>資産管理</div>
          </NavLink>
        </li>*/}
      </ul>
    </nav>
  )
}

export default Navbar
