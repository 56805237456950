import {API, Auth} from 'aws-amplify'
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Message from '../ui/Message'
import {TYPE_EMAIL, TYPE_LINE} from '../../constants/alert-notification-destinations'
import styles from '../../styles/alert-notification-destinations/List.less'

const List = ({}) => {
  const {state} = useLocation()

  const [alertNotificationDestinationsMap, setAlertNotificationDestinationsMap] = useState({})
  const [formValues, setFormValues] = useState({email: ''})
  const [errorMessage, setErrorMessage] = useState()
  const [successMessage, setSuccessMessage] = useState()

  const listAlertNotificationDestinations = async () => {
    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken}

      const r = await API.get('solsell', 'v0/alert-notification-destinations', {
        headers, queryStringParameters: {
          fields: ['id', 'typ', 'destination'],
          include_line_profile: true,
          limit: -1
        }
      })

      let andMap = {}
      r.data.map(item => {
        if(!andMap.hasOwnProperty(item.typ)) {
          andMap[item.typ] = []
        }

        andMap[item.typ].push(item)
      })

      setAlertNotificationDestinationsMap(andMap)
    }
    catch(err) {
      setErrorMessage(err)
    }
  }

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleClickAddEmail = async e => {
    setErrorMessage(null)
    setSuccessMessage(null)

    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const body = {destination: formValues.email, typ: TYPE_EMAIL}

      const r = await API.post('solsell', 'v0/alert-notification-destinations', {
        body, headers, response: true,
      })

      let emails = alertNotificationDestinationsMap[TYPE_EMAIL] || []
      emails.push({...body, ...r.data})

      setAlertNotificationDestinationsMap({
        ...alertNotificationDestinationsMap, [TYPE_EMAIL]: emails
      })

      setFormValues({...formValues, email: ''})

      setSuccessMessage('メールアドレスの連携が完了しました。')
    }
    catch(err) {
      setErrorMessage(err)
    }
  }

  const handleClickDeleteEmail = id => async e => {
    setErrorMessage(null)
    setSuccessMessage(null)

    try {
      const {idToken: {jwtToken}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const body = {destination: formValues.email, typ: TYPE_EMAIL}

      const r = await API.del('solsell', `v0/alert-notification-destinations/${id}`, {
        body, headers, response: true,
      })

      let emails = alertNotificationDestinationsMap[TYPE_EMAIL].filter(item => 
        item.id != id
      )

      setAlertNotificationDestinationsMap({
        ...alertNotificationDestinationsMap, [TYPE_EMAIL]: emails
      })

      setFormValues({...formValues, email: ''})

      setSuccessMessage('メールアドレスを削除しました。')
    }
    catch(err) {
      setErrorMessage(err)
    }
  }

  useEffect(() => {
    if(!state) {
      return listAlertNotificationDestinations()
    }

    const {success, message} = state

    if(success === true) {
      listAlertNotificationDestinations()
      setSuccessMessage(message)
    }
    else {
      setErrorMessage(message)
    }
  }, [state])

  return (
    <div className={styles.ctn}>
      {errorMessage &&
        <Message message={errorMessage}/>
      }
      {successMessage &&
        <Message message={successMessage} typ="success"/>
      }
      <div className={styles.flex}>
        <div className={styles.flexItem}>
          <div className={styles.flexItemHeadline}>メール</div>
          <ul className={styles.userList}>
            {(alertNotificationDestinationsMap[TYPE_EMAIL] || []).map((item, i) => 
              <li key={i}>
                <div className={styles.userListItemName}>{item.destination}</div>
                <button
                  className={`${styles.userListItemBtn} ${styles.userListItemBtnDelete}`}
                  onClick={handleClickDeleteEmail(item.id)}
                  type="button"
                >
                  削除
                </button>
              </li>
            )}
            <li>
              <input
                className={styles.userListItemInput}
                name="email"
                onChange={handleChange}
                type="email"
                value={formValues.email}
              />
              <button
                className={`${styles.userListItemBtn} ${styles.userListItemBtnAdd}`}
                onClick={handleClickAddEmail}
                type="button"
              >
                追加
              </button>
            </li>
          </ul>
        </div>
        <div className={styles.flexItem}>
          <div className={styles.flexItemHeadline}>LINE</div>
          {alertNotificationDestinationsMap[TYPE_LINE] &&
            <ul className={styles.userList}>
              {alertNotificationDestinationsMap[TYPE_LINE].map((item, i) => 
                <li key={i}>
                  <div className={styles.userListItemImg}>
                    {(item.line_profile.picture_url || item.line_profile.display_name == 'Usk Masaru Hanada') &&
                      <img src={item.line_profile.display_name == 'Usk Masaru Hanada' ? '/matomete-solar/img/icon-line.png' : item.line_profile.picture_url}/>
                    }
                  </div>
                  <div className={styles.userListItemName}>{item.line_profile.display_name == 'Usk Masaru Hanada' ? 'hanada' : item.line_profile.display_name}</div>
                </li>
              )}
            </ul>
          }
            
          <a className={styles.lineBtn} href="https://lin.ee/xr45sQD" target="_blank">
            <img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default List
