import React from "react";
import {
  RANGE_TYPE_RAW,
  RANGE_TYPE_HOURLY,
  RANGE_TYPE_DAILY,
  RANGE_TYPE_MONTHLY,
  RANGE_TYPE_YEARLY,
} from "../../constants/reports";

export default function RangeTypeButtonGroup({
  onClick,
  plantDataSources,
  rangeType,
  styles,
}) {
  const [hasHourly, setHasHourly] = React.useState(false);
  const [hasRaw, setHasRaw] = React.useState(false);

  React.useEffect(() => {
    let newHasHourly = false;
    let newHasRaw = false;
    plantDataSources.forEach((item) => {
      if (item.range_type == RANGE_TYPE_HOURLY) {
        newHasHourly = true;
      } else if (item.range_type == RANGE_TYPE_RAW) {
        newHasHourly = true;
        newHasRaw = true;
      }
    });
    setHasHourly(newHasHourly);
    setHasRaw(newHasRaw);
  }, [plantDataSources]);

  return (
    <div className={styles.btnGroup}>
      <button
        className={rangeType == RANGE_TYPE_YEARLY ? styles.isBtnActive : ""}
        onClick={(e) => onClick(RANGE_TYPE_YEARLY)}
        type="button"
      >
        年
      </button>
      <button
        className={rangeType == RANGE_TYPE_MONTHLY ? styles.isBtnActive : ""}
        onClick={(e) => onClick(RANGE_TYPE_MONTHLY)}
        type="button"
      >
        月
      </button>
      <button
        className={rangeType == RANGE_TYPE_DAILY ? styles.isBtnActive : ""}
        onClick={(e) => onClick(RANGE_TYPE_DAILY)}
        type="button"
      >
        日
      </button>
      {hasHourly === true && (
        <button
          className={rangeType == RANGE_TYPE_HOURLY ? styles.isBtnActive : ""}
          onClick={(e) => onClick(RANGE_TYPE_HOURLY)}
          type="button"
        >
          時
        </button>
      )}
      {hasRaw === true && (
        <button
          className={rangeType == RANGE_TYPE_RAW ? styles.isBtnActive : ""}
          onClick={(e) => onClick(RANGE_TYPE_RAW)}
          type="button"
        >
          生
        </button>
      )}
    </div>
  );
}
