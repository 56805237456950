import React from 'react'
import styles from '../../styles/ui/AdminContainer.less'

const AdminContainer = ({children}) => {
  return (
    <div className={styles.ctn}>{children}</div>
  )
}

export default AdminContainer
