import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Create from "./Create";
import AppContainer from "../ui/AppContainer";
import Loading from "../ui/Loading";
import Main from "../ui/Main";
import Navbar from "../ui/Navbar";
import TrackableRoute from "../ui/TrackableRoute";
import CreateUserModal from "../users/CreateUserModal";
import { USER_TYPE_ADMIN } from "../../constants/auth";

const STATUS_NO_USER_DATA = "no_user_data";
const STATUS_NOT_SIGNED_IN = "not_signed_in";
const STATUS_SIGNED_IN = "signed_in";

const PrivateRoute = ({ children, ...rest }) => {
  const [userStatus, setUserStatus] = useState();
  const location = useLocation();

  useEffect(() => {
    initUserStatus();
  }, [location.pathname]);

  const initUserStatus = async () => {
    try {
      const r = await Auth.currentAuthenticatedUser();

      // const {idToken: {jwtToken, payload}} = await Auth.currentSession()
      // const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      // const r1 = await API.get('solsell', `v1/users/${r.username}`, {
      //   headers, queryStringParameters: {
      //     fields: ['user_sub'],
      //   }
      // })
      setUserStatus(STATUS_SIGNED_IN);
    } catch (err) {
      // if(err.hasOwnProperty('response') && err.response.status == 404) {
      //   return setUserStatus(STATUS_NO_USER_DATA)
      // }
      setUserStatus(STATUS_NOT_SIGNED_IN);
    }
  };

  if (typeof userStatus == "undefined") {
    return <Loading />;
  }

  return (
    <TrackableRoute
      {...rest}
      render={() => {
        if (
          (userStatus == STATUS_SIGNED_IN ||
            userStatus == STATUS_NO_USER_DATA) &&
          (location.pathname.indexOf("/admin/") === -1 ||
            user.signInUserSession.idToken.payload["cognito:groups"].indexOf(
              USER_TYPE_ADMIN
            ) !== -1)
        ) {
          return (
            <AppContainer>
              <Navbar />
              <Main>{children}</Main>
            </AppContainer>
          );
        }

        return (
          <Redirect to={{ pathname: "/signin", state: { next: location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
