import React, {Fragment, useEffect, useState} from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import DatePicker, {CalendarContainer} from 'react-datepicker'
import {Link} from 'react-router-dom'
import Create from './plants/Create'
import Detail from './plants/Detail'
import ContentContainer from './ui/ContentContainer'
import Head from './ui/Head'
import FilterMenuPlant from './ui/FilterMenuPlant'
import Panel from './ui/Panel'

const PlantList = ({plants}) => {
  if(!plants || plants.length == 0) {
    return <div>Loading...</div>
  }
  return <Redirect to={`/plants/${plants[0].id}`}/>
}

const Plant = ({}) => {
  const history = useHistory()

  const [plantId, setPlantId] = useState()
  const [plants, setPlants] = useState([])

  const handleClickBtnCreatePlant = e => {
    // setPlantId(null)
    history.push(`/plants/create`)
  }

  const handleLoadList = plants => {
    setPlants(plants)
  }

  const handleSetPlantId = id => {
    setPlantId(id)
  }

  return (
    <Fragment>
      <Head>
        <title>物件管理</title>
      </Head>
      <FilterMenuPlant
        id={plantId}
        isPlant
        onClickBtnCreatePlant={handleClickBtnCreatePlant}
        onLoadList={handleLoadList}
      />
      <ContentContainer isPlant>
        <Switch>
          <Route exact path="/plants">
            <PlantList plants={plants}/>
          </Route>
          <Route exact path="/plants/create">
            <Create/>
          </Route>
          <Route exact path="/plants/:id">
            <Detail plants={plants} onSetPlantId={handleSetPlantId}/>
          </Route>
        </Switch>
      </ContentContainer>
    </Fragment>
  )
}

export default Plant
