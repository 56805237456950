import {API, Auth} from 'aws-amplify'
import React, {Fragment, useEffect, useRef, useState} from 'react'
import FullHeightContent from '../ui/FullHeightContent'
import Loading from '../ui/Loading'
import Message from '../ui/Message'
import Policy from '../ui/Policy'
import styles from '../../styles/user-groups/Create.less'

const Create = ({setId}) => {
  const [errorMessage, setErrorMessage] = useState(null) 
  const [successMessage, setSuccessMessage] = useState(null) 
  const [userSub, setUserSub] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()

    setErrorMessage(null)
    setSuccessMessage(null)
    
    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const r = await API.post('solsell', 'v0/user-groups', {
        body: {
          user_sub_from: payload['cognito:username'],
          user_sub_to: userSub,
        },
        headers,
      })

      setUserSub('')
      setSuccessMessage('申請を送信しました。')
    }
    catch(err) {
      setErrorMessage(err)
      console.log(err)
    }
  }

  useEffect(() => {
    setId(undefined)
  }, [])

  return (
    <FullHeightContent>
      <div className={styles.panelHeadline}>連携先情報を追加</div>
      {errorMessage &&
        <Message message={errorMessage}/>
      }
      {successMessage &&
        <Message message={successMessage} typ="success"/>
      }
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label}>連携先のアカウントID</label>
          <input
            className={styles.input}
            name="user_sub"
            onChange={e => setUserSub(e.target.value)}
            placeholder="連携先のアカウントIDを入力"
            type="text"
            value={userSub}
          />
        </div>
        {userSub.length > 0 &&
          <Fragment>
            <button className={styles.btn} type="submit">登録する</button>
            <div className={styles.note}>
              連携先に確認の通知が飛びます。 <br/>
              相手側が「連携許可」をしたら連携が完了となります。
            </div>
          </Fragment>
        }
            
      </form>
    </FullHeightContent>
  )
}

export default Create
