import React, {Fragment, useState} from 'react'
import {Link} from 'react-router-dom'
import Head from '../ui/Head'
import Message from '../ui/Message'
import styles from '../../styles/auth/Base.less'

const Wrapper = ({children, errorMessage, title}) => {
  return (
    <div className={styles.wrapper}>
      <Head title={title}/>
      <div className={styles.ctn}>
        <img className={styles.logo} src="/matomete-solar/img/logo-beta.png"/>
        {errorMessage &&
          <Fragment>
            {errorMessage == '既にそのメールアドレスのユーザが存在します。' ? (
              <Message>
                <div>{errorMessage}</div>
                <div>
                  ご登録いただいたメールアドレスに送信されている認証コードを
                  <Link className={styles.linkText} to="/verify">こちら</Link>
                  からご入力ください。
                </div>
                <div style={{textAlign: 'right'}}>
                  <Link className={styles.linkText} to="/verify">&raquo;&nbsp;認証する</Link>
                </div>
              </Message>
            ) : (
              <Message message={errorMessage}/>
            )}
          </Fragment>
        }
        {children}
      </div>
    </div>
  )
}

export default Wrapper
