import {API, Auth} from 'aws-amplify'
import React, {Fragment, useState} from 'react'
import ContentContainer from './ui/ContentContainer'
import FilterMenuPlant from './ui/FilterMenuPlant'
import Head from './ui/Head'
import Message from './ui/Message'
import Panel from './ui/Panel'
import {USER_TYPE_CORPORATION, USER_TYPE_INDIVIDUAL} from '../constants/users'
import styles from '../styles/Mypage.less'

const MODE_EDIT = 'edit'

const Mypage = (props) => {
  const [errorMessage, setErrorMessage] = useState()
  const [formValues, setFormValues] = useState({
    user_sub: '',
    email: '',
    family_name: '',
    family_name_kana: '',
    given_name: '',
    given_name_kana: '',
    phone_number: '',
    user_type: USER_TYPE_INDIVIDUAL,
  })
  const [isReady, setIsReady] = useState(false)
  const [mode, setMode] = useState()
  const [successMessage, setSuccessMessage] = useState()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleClickEdit = async e => {
    if(mode != MODE_EDIT) {
      setMode(MODE_EDIT)
      return
    }

    setErrorMessage()
    setSuccessMessage()

    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()
      const headers = {Authorization: jwtToken, 'Content-Type': 'application/json'}

      const r = await API.put('solsell', `v1/users/${payload['cognito:username']}`, {
        body: formValues,
        headers,
        response: true,
      })

      setSuccessMessage('編集が完了しました。')
      setMode(undefined)
    }
    catch(err) {
      console.log(err)
      setErrorMessage(err)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
  }

  const initUser = async () => {
    try {
      const {idToken: {jwtToken, payload}} = await Auth.currentSession()

      const headers = {Authorization: jwtToken}
      const queryStringParameters = {}
      const r = await API.get(
        'solsell', `v1/users/${payload['cognito:username']}`,
        {headers, queryStringParameters}
      )
      setFormValues(r)
      setIsReady(true)
    }
    catch(err) {
      setErrorMessage(err)
    }
  }

  useState(() => {
    initUser()
  }, [])

  return (
    <Fragment>
      <Head>
        <title>マイページ</title>
      </Head>
      <FilterMenuPlant/>
      <ContentContainer>
        <Panel>
          <div className={styles.ctn}>
            {errorMessage &&
              <Message message={errorMessage}/>
            }
            {successMessage &&
              <Message message={successMessage} typ="success"/>
            }
            {isReady &&
              <form onSubmit={handleSubmit}>
                <div className={styles.nameAndBtn}>
                  <div className={styles.name}>{formValues.family_name} {formValues.given_name} 様</div>
                  <button
                    className={`${styles.btn} ${mode == MODE_EDIT ? styles.btnOutlined : ''}`}
                    onClick={handleClickEdit}
                    type="button"
                  >
                    {mode == MODE_EDIT ? '編集を完了する' : '編集する'}
                  </button>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.formGroupLabel}>
                    アカウント ID
                  </div>
                  <div className={styles.formGroupInput}>
                    <input
                      className={styles.input}
                      disabled={true}
                      name="user_sub"
                      onChange={handleChange}
                      value={formValues.user_sub}
                    />
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.formGroupLabel}>
                    メールアドレス
                  </div>
                  <div className={styles.formGroupInput}>
                    <input
                      className={styles.input}
                      disabled={true}
                      name="email"
                      onChange={handleChange}
                      value={formValues.email}
                    />
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.formGroupLabel}>
                    お名前
                  </div>
                  <div className={styles.formGroupInputGroup}>
                    <div className={`${styles.formGroupInputGroupItem} ${styles.formGroupInputGroupItemMd}`}>
                      <input
                        className={styles.input}
                        disabled={mode != MODE_EDIT}
                        name="family_name"
                        onChange={handleChange}
                        value={formValues.family_name}
                      />
                    </div>
                    <div className={`${styles.formGroupInputGroupItem} ${styles.formGroupInputGroupItemMd}`}>
                      <input
                        className={styles.input}
                        disabled={mode != MODE_EDIT}
                        name="given_name"
                        onChange={handleChange}
                        value={formValues.given_name}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.formGroupLabel}>
                    お名前（カナ）
                  </div>
                  <div className={styles.formGroupInputGroup}>
                    <div className={`${styles.formGroupInputGroupItem} ${styles.formGroupInputGroupItemLg}`}>
                      <input
                        className={styles.input}
                        disabled={mode != MODE_EDIT}
                        name="family_name_kana"
                        onChange={handleChange}
                        value={formValues.family_name_kana}
                      />
                    </div>
                    <div className={`${styles.formGroupInputGroupItem} ${styles.formGroupInputGroupItemLg}`}>
                      <input
                        className={styles.input}
                        disabled={mode != MODE_EDIT}
                        name="given_name_kana"
                        onChange={handleChange}
                        value={formValues.given_name_kana}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.formGroupLabel}>
                    電話番号
                  </div>
                  <div className={styles.formGroupInput}>
                    <input
                      className={styles.input}
                      disabled={mode != MODE_EDIT}
                      name="phone_number"
                      onChange={handleChange}
                      value={formValues.phone_number.replace('+81', '0')}
                    />
                  </div>
                </div>
                
              </form>
            }
          </div>
        </Panel>
      </ContentContainer>
    </Fragment>
  )
}

export default Mypage
