import { API } from "aws-amplify";
import { getHeaders } from "../";

export const readNotifications = async (params) => {
  const headers = await getHeaders();
  const r = await API.get("matomete.solar", "v0/notifications", {
    headers,
    queryStringParameters: params,
  });
  return r;
};
