import { API, Auth } from "aws-amplify";
import format from "date-fns/format";
import React, { Fragment, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import Param1Input from "./Param1Input";
import Param2Input from "./Param2Input";
import PasswordInput from "./PasswordInput";
import ServiceCheckbox from "./ServiceCheckbox";
import ServiceHelpText from "./ServiceHelpText";
import FormErrorMessage from "../ui/FormErrorMessage";
import Loading from "../ui/Loading";
import Message from "../ui/Message";
import Panel from "../ui/Panel";
import Policy from "../ui/Policy";
import { SERVICE_ID_SMARTPV } from "../../constants/services";
import {
  REGISTRATION_TYPE_MATOMETE_SOLAR,
  REGISTRATION_PLATFORM_PC,
  USER_TYPE_CORPORATION,
  USER_TYPE_INDIVIDUAL,
} from "../../constants/users";
import styles from "../../styles/plants/Create.less";

const defaultFormValues = {
  // name: '',
  param_1: "",
  param_2: "",
  password: "",
  service_id: "",
  data_from_jst: format(new Date(), "yyyy-MM-dd"),
};

const Create = ({}) => {
  const [cognitoUser, setCognitoUser] = useState();
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasUserData, setHasUserData] = useState();
  const [isAgreed, setIsAgreed] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [service, setService] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [userFormErrors, setUserFormErrors] = useState();
  const [userFormValues, setUserFormValues] = useState({
    family_name: "",
    given_name: "",
    family_name_kana: "",
    given_name_kana: "",
    phone_number: "",
    // phone_number_0: '',
    // phone_number_1: '',
    // phone_number_2: '',
    user_type: USER_TYPE_INDIVIDUAL,
    corporate_name: "",
  });

  const theme = useTheme();

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeUser = (e) => {
    const {
      target: { name, value },
    } = e;
    if (name.indexOf("phone_number") != -1 && value && !/^\d+$/.test(value)) {
      return;
    }
    setUserFormValues({ ...userFormValues, [name]: value });
  };

  const handleChangeUserType = (v) => (e) => {
    setUserFormValues({ ...userFormValues, user_type: v });
  };

  const handleClickTool = (item) => (e) => {
    // let d = {...defaultFormValues, service_id: item.id}
    // if(!item.password_in_use) {
    //   d['password'] = ''
    // }
    setFormValues({ ...defaultFormValues, service_id: item.id });
    setService(item);
  };

  const handleClickIsAgreed = (e) => {
    setIsAgreed(!isAgreed);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);
    setUserFormErrors();

    const {
      idToken: { jwtToken },
    } = await Auth.currentSession();
    const headers = {
      Authorization: jwtToken,
      "Content-Type": "application/json",
    };

    if (!hasUserData) {
      try {
        const r0 = await API.post("solsell", "v1/users", {
          body: {
            ...userFormValues,
            // phone_number: [
            //   userFormValues.phone_number_0,
            //   userFormValues.phone_number_1,
            //   userFormValues.phone_number_2
            // ].join('-'),
            registration_type: REGISTRATION_TYPE_MATOMETE_SOLAR,
            registration_platform: REGISTRATION_PLATFORM_PC,
          },
          headers,
          response: true,
        });

        setHasUserData(true);
      } catch (err) {
        if (err.hasOwnProperty("response")) {
          if (err.response.status == 500) {
            setErrorMessage(
              "エラーが発生しました。恐れ入りますが、システム担当にお問い合わせくださいませ。"
            );
          } else if (err.response.status == 400) {
            const {
              data: { error },
            } = err.response;
            if (typeof error == "string") {
              console.log(error);
              setErrorMessage(error);
            } else {
              setUserFormErrors(error);
            }
          }
        } else {
          setErrorMessage(err);
        }

        setIsLoading(false);
        return;
      }
    }

    try {
      const r1 = await API.post("solsell", "v0/accounts", {
        body: formValues,
        headers,
        response: true,
      });

      const msg =
        formValues.service_id == SERVICE_ID_SMARTPV
          ? "登録完了まで1週間ほど"
          : "更新までしばらく";
      setSuccessMessage(
        `作成が完了しました。データは自動取得されますので、${msg}お待ちくださいませ。`
      );
      setIsLoading(false);
    } catch (err) {
      if (err.hasOwnProperty("response")) {
        if (typeof err.response == "undefined" || err.response.status == 500) {
          setErrorMessage(
            "エラーが発生しました。恐れ入りますが、システム担当にお問い合わせくださいませ。"
          );
        } else if (err.response.status == 400) {
          const {
            data: { error },
          } = err.response;
          // if(typeof error == 'string') {
          //   setErrorMessage(error)
          // }
          setErrorMessage(error);
        }
      }
      setIsLoading(false);
    }
  };

  const initHasUserData = async () => {
    try {
      const {
        idToken: { jwtToken, payload },
      } = await Auth.currentSession();

      const headers = {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      };

      const r1 = await API.get(
        "solsell",
        `v1/users/${payload["cognito:username"]}`,
        {
          headers,
          queryStringParameters: {
            fields: [
              "user_sub",
              "email",
              "family_name",
              "family_name_kana",
              "given_name",
              "given_name_kana",
              "phone_number",
            ],
          },
        }
      );

      let ud = {};
      for (let k of Object.keys(r1)) {
        if (r1[k] === null) {
          continue;
        }
        ud[k] = r1[k];
      }

      setUserFormValues({ ...userFormValues, ...ud });
      setHasUserData(r1.family_name_kana != null);
    } catch (err) {
      const r = await Auth.currentAuthenticatedUser();
      console.log(r);

      const ud = {
        user_sub: r.username,
        given_name: r.attributes.given_name,
        family_name: r.attributes.family_name,
        phone_number: (r.attributes.phone_number || "").replace("+081", "0"),
      };

      setUserFormValues({ ...userFormValues, ...ud });
      setHasUserData(false);
    }
  };

  const isValid = () => {
    if (!service) {
      return false;
    }

    if (service.param_1_in_use && formValues.param_1.length == 0) {
      return false;
    }

    if (service.param_2_in_use && formValues.param_2.length == 0) {
      return false;
    }

    if (service.password_in_use && formValues.password.length == 0) {
      return false;
    }

    return true;
  };

  // const validate = () => {
  //   let isFilled = true

  //   service.param_1_in_use
  //   if(formValues.service_id == SERVICE_ID_SMARTPV) {
  //     isFilled = formValues.param_1.length > 0
  //   }
  //   else {
  //     for(const k of Object.keys(formValues)) {
  //       if(k == 'param_2') {
  //         continue
  //       }

  //       const v = k == name ? value : formValues[k]
  //       if(v.length == 0) {
  //         isFilled = false
  //         break
  //       }
  //     }

  //     if([
  //       SERVICE_ID_ECO_MEGANE,
  //       SERVICE_ID_LEYE,
  //       SERVICE_ID_SOLARVIEW,
  //     ].some(item => item === formValues.service_id)) {
  //       isFilled = isFilled && formValues.param_2.length > 0
  //     }
  //   }

  //   setIsBtnActive(isFilled && isAgreed)
  // }

  useEffect(() => {
    initHasUserData();
  }, []);

  useEffect(() => {
    setIsBtnActive(isValid() && isAgreed);
  }, [formValues, isAgreed]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Panel>
      <div className={styles.panelCtn}>
        {errorMessage && <Message message={errorMessage} />}
        {successMessage && <Message message={successMessage} typ="success" />}
        <ServiceCheckbox
          onClick={handleClickTool}
          serviceId={formValues.service_id}
          styles={styles}
        />
        <hr className={styles.hr} />
        {service && (
          <>
            <div className={styles.formGroup}>
              <div className={styles.blockLabel}>ログイン情報</div>
              <div className={styles.formGroupRow}>
                <Param1Input
                  onChange={handleChange}
                  service={service}
                  styles={styles}
                  value={formValues.param_1}
                />
                <Param2Input
                  onChange={handleChange}
                  service={service}
                  styles={styles}
                  value={formValues.param_2}
                />
                <PasswordInput
                  onChange={handleChange}
                  service={service}
                  styles={styles}
                  value={formValues.password}
                />
              </div>

              <ServiceHelpText service={service} />
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formGroupRowItem}>
                <label className={styles.inlineLabel}>データ取得</label>
                <input
                  className={`${styles.inputText} ${styles.inputTextInline}`}
                  name="data_from_jst"
                  onChange={handleChange}
                  type="date"
                  value={formValues.data_from_jst}
                />
                <span className={styles.inlineLabelSuffix}>分から</span>
                <span className={styles.inlineLabelSuffixRemark}>
                  ※本日以前のデータ反映には数日かかる場合があります。
                </span>
              </div>
            </div>
          </>
        )}

        <div className={styles.policyCtn}>
          <Policy />
        </div>
        <div className={styles.formGroup}>
          <div
            className={`${styles.checkboxCtn} ${styles.checkboxCtnIsAgreed}`}
            onClick={handleClickIsAgreed}
          >
            <div
              className={`${styles.checkbox} ${isAgreed ? styles.checked : ""}`}
            />
            <div>利用規約に同意して</div>
          </div>
        </div>
        {hasUserData === false && (
          <Fragment>
            <div className={styles.formGroup} style={{ marginTop: "4vw" }}>
              <div className={styles.blockLabel}>ユーザ情報の登録</div>
              <div className={styles.formGroupRow}>
                <div className={styles.formGroupRowItem}>
                  <label className={styles.inlineLabelFixedWidth}>お名前</label>
                  <input
                    className={`${styles.inputText} ${styles.inputTextInline}`}
                    name="family_name"
                    onChange={handleChangeUser}
                    placeholder="姓"
                    type="text"
                    value={userFormValues.family_name}
                  />
                  <input
                    className={`${styles.inputText} ${styles.inputTextInline}`}
                    name="given_name"
                    onChange={handleChangeUser}
                    placeholder="名"
                    type="text"
                    value={userFormValues.given_name}
                  />
                  {userFormErrors?.family_name && (
                    <div className={styles.formErrorCtn}>
                      <FormErrorMessage message={userFormErrors.family_name} />
                    </div>
                  )}
                  {userFormErrors?.given_name && (
                    <div className={styles.formErrorCtn}>
                      <FormErrorMessage message={userFormErrors.given_name} />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.formGroupRow}>
                <div className={styles.formGroupRowItem}>
                  <label className={styles.inlineLabelFixedWidth}>
                    お名前（カナ）
                  </label>
                  <input
                    className={`${styles.inputText} ${styles.inputTextInline}`}
                    name="family_name_kana"
                    onChange={handleChangeUser}
                    placeholder="セイ"
                    type="text"
                    value={userFormValues.family_name_kana}
                  />
                  <input
                    className={`${styles.inputText} ${styles.inputTextInline}`}
                    name="given_name_kana"
                    onChange={handleChangeUser}
                    placeholder="メイ"
                    type="text"
                    value={userFormValues.given_name_kana}
                  />
                  {userFormErrors?.family_name_kana && (
                    <div className={styles.formErrorCtn}>
                      <FormErrorMessage
                        message={userFormErrors.family_name_kana}
                      />
                    </div>
                  )}
                  {userFormErrors?.given_name_kana && (
                    <div className={styles.formErrorCtn}>
                      <FormErrorMessage
                        message={userFormErrors.given_name_kana}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.formGroupRow}>
                <div className={styles.formGroupRowItem}>
                  <label className={styles.inlineLabelFixedWidth}>
                    電話番号
                  </label>
                  <input
                    className={`${styles.inputText}`}
                    name="phone_number"
                    onChange={handleChangeUser}
                    pattern="[0-9]+"
                    placeholder="0312345678"
                    type="text"
                    value={userFormValues.phone_number.replace("+81", "0")}
                  />
                  {/* <input
                    className={`${styles.inputText} ${styles.inputTextInlineOneThird}`}
                    name="phone_number_1"
                    onChange={handleChangeUser}
                    placeholder="1234"
                    type="text"
                    value={userFormValues.phone_number_1}
                  />
                  <input
                    className={`${styles.inputText} ${styles.inputTextInlineOneThird}`}
                    name="phone_number_2"
                    onChange={handleChangeUser}
                    placeholder="5678"
                    type="text"
                    value={userFormValues.phone_number_2}
                  /> */}
                  {userFormErrors?.phone_number && (
                    <div className={styles.formErrorCtn}>
                      <FormErrorMessage message={userFormErrors.phone_number} />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.formGroupRow}>
                <div className={styles.formGroupRowItem}>
                  <label className={styles.inlineLabelFixedWidth}>
                    法人・個人
                  </label>
                  <button
                    className={`${styles.btn} ${
                      userFormValues.user_type == USER_TYPE_INDIVIDUAL
                        ? ""
                        : styles.btnOutlined
                    }`}
                    onClick={handleChangeUserType(USER_TYPE_INDIVIDUAL)}
                    type="button"
                  >
                    個人
                  </button>
                  <button
                    className={`${styles.btn} ${
                      userFormValues.user_type == USER_TYPE_CORPORATION
                        ? ""
                        : styles.btnOutlined
                    }`}
                    onClick={handleChangeUserType(USER_TYPE_CORPORATION)}
                    type="button"
                  >
                    法人
                  </button>
                  {userFormErrors?.user_type && (
                    <div className={styles.formErrorCtn}>
                      <FormErrorMessage message={userFormErrors.user_type} />
                    </div>
                  )}
                </div>
              </div>
              {userFormValues.user_type == USER_TYPE_CORPORATION && (
                <div className={styles.formGroupRow}>
                  <div className={styles.formGroupRowItem}>
                    <label className={styles.inlineLabelFixedWidth}>
                      法人名
                    </label>
                    <input
                      className={`${styles.inputText} ${styles.inputTextFullWidth}`}
                      name="corporate_name"
                      onChange={handleChangeUser}
                      placeholder="〇〇株式会社"
                      type="text"
                      value={userFormValues.corporate_name}
                    />
                    {userFormErrors?.corporate_name && (
                      <div className={styles.formErrorCtn}>
                        <FormErrorMessage
                          message={userFormErrors.corporate_name}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <hr className={styles.hr} />
          </Fragment>
        )}
        <div className={styles.formGroup}>
          <button
            className={`${styles.btnSubmit} ${
              isBtnActive ? "" : styles.disabled
            }`}
            disabled={!isBtnActive}
            onClick={handleSubmit}
          >
            登録完了
          </button>
        </div>
      </div>
    </Panel>
  );
};

export default Create;
