import React from 'react'
import {ThemeProvider} from 'styled-components'

const vw = 19.2

const theme = {
  fontSize: {
    xs: `${12 / vw}vw`,
    sm: `${14 / vw}vw`,
    md: `${16 / vw}vw`,
    lg: `${18 / vw}vw`,
    xl: `${20 / vw}vw`,
  },
  palette: {
    default: {
      light: '#F5F5F5',
      main: '#CFCFCF',
      dark: '#363732'
    },
    primary: {
      light: '#2994D9',
      main: '#085BC2',
    },
    secondary: {
      light: '#0BC373',
    },
    tertiary: {
      main: '#F3BB00'
    }
  },
  spacing: [0, `${8 / vw}vw`, `${16 / vw}vw`, `${24 / vw}vw`, `${32 / vw}vw`, `${40 / vw}vw`, `${48 / vw}vw`],
  radius: [0, `${3 / vw}vw`, `${6 / vw}vw`, `${9 / vw}vw`, `${12 / vw}vw`],
  vw
}

const Theme = ({children}) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
