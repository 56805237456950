import React from 'react'
import styled, {useTheme} from 'styled-components'

const Container = styled.div`
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #D2D2D7;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  margin-top: 16px;
  padding: 12px 17px;
  width: 100%;
`

const Img = styled.img`
  width: 20px;
  height: 25px;
`

const Text = styled.div`
  color: #1E1E1E;
  font-size: 13px;
  margin-left: 24px;
  padding-left: 16px;
  position: relative;
  &::before {
    content:"";
    display:inline-block;
    width:1px;
    height:40px;
    background-color:#D2D2D7;
    position:absolute;
    top:-8px;
    left:-12px;
  }
`

const SocialLoginContainer = ({ctnId, provider, iconSrc, onClick, signInFunc}) => {
  return(
    <Container id={ctnId} onClick={onClick}>
      <Img src={iconSrc} />
      <Text>{provider}でログイン</Text>
    </Container>
  )
}

export default SocialLoginContainer;