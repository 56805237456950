import React from "react";
import {
  CHART_DATA_TYPE_INSOLATION,
  CHART_DATA_TYPE_POWER,
  CHART_DATA_TYPE_REVENUE,
  FIELD_TYPE_INSOLATION,
  FIELD_TYPE_REVENUE,
  FIELD_TYPE_THEORETICAL_REVENUE,
  FIELD_TYPES_JA,
  RANGE_TYPE_RAW,
  RANGE_TYPE_HOURLY,
  RANGE_TYPE_DAILY,
} from "../../constants/reports";

export default function SubFilterChartDataType({
  chartDataType,
  formValues,
  onClick,
  plantDataSources,
  styles,
}) {
  const [fieldTypes, setFieldTypes] = React.useState([]);

  React.useEffect(() => {
    let newFieldTypes = [];
    for (let pds of plantDataSources) {
      if (pds.data_type != formValues.data_type) continue;

      let matched = false;
      if (pds.range_type == RANGE_TYPE_RAW) {
        matched = true;
      } else if (pds.range_type == RANGE_TYPE_HOURLY) {
        matched = formValues.range_type != RANGE_TYPE_RAW;
      } else if (pds.range_type == RANGE_TYPE_DAILY) {
        matched =
          formValues.range_type != RANGE_TYPE_RAW &&
          formValues.range_type != RANGE_TYPE_HOURLY;
      }
      // monthly, yearly が plant_data_source なことはない
      if (matched && !newFieldTypes.includes(pds.field_type)) {
        newFieldTypes.push(pds.field_type);
      }
    }
    setFieldTypes(newFieldTypes);
  }, [formValues]);

  return (
    <div className={styles.subFilters}>
      {fieldTypes.map((item) => (
        <div
          className={`${styles.subFilterRadio} ${
            chartDataType == item ? styles.checked : ""
          }`}
          key={item}
          onClick={onClick(item)}
        >
          <div className={styles.subFilterRadioInput}>
            <div className={styles.subFilterRadioInputInner} />
          </div>
          <div>{FIELD_TYPES_JA[item]}</div>
        </div>
      ))}
      {/* <div
        className={`${styles.subFilterRadio} ${
          chartDataType == CHART_DATA_TYPE_POWER ? styles.checked : ""
        }`}
        onClick={onClick(CHART_DATA_TYPE_POWER)}
      >
        <div className={styles.subFilterRadioInput}>
          <div className={styles.subFilterRadioInputInner} />
        </div>
        <div>発電量</div>
      </div>
      {hasRevenues === true && (
        <div
          className={`${styles.subFilterRadio} ${
            chartDataType == CHART_DATA_TYPE_REVENUE ? styles.checked : ""
          }`}
          onClick={onClick(CHART_DATA_TYPE_REVENUE)}
        >
          <div className={styles.subFilterRadioInput}>
            <div className={styles.subFilterRadioInputInner} />
          </div>
          <div>売電収入</div>
        </div>
      )}
      {hasInsolations === true && (
        <div
          className={`${styles.subFilterRadio} ${
            chartDataType == CHART_DATA_TYPE_INSOLATION ? styles.checked : ""
          }`}
          onClick={onClick(CHART_DATA_TYPE_INSOLATION)}
        >
          <div className={styles.subFilterRadioInput}>
            <div className={styles.subFilterRadioInputInner} />
          </div>
          <div>日射強度</div>
        </div>
      )} */}
    </div>
  );
}
