import { API, Auth } from "aws-amplify";
import format from "date-fns/format";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Message from "../ui/Message";
import Modal from "../ui/Modal";
import Panel from "../ui/Panel";
import {
  WEATHER_ID_ICHIJI,
  WEATHER_ID_TOKIDOKI,
  WEATHER_ID_NOCHI,
} from "../../constants/plants";
import {
  DATA_TYPE_PLANTS,
  DATE_FORMAT,
  RANGE_TYPE_DAILY,
  RANGE_TYPE_MONTHLY,
} from "../../constants/reports";
import styles from "../../styles/plants/Detail.less";

const INITIAL_DATA_PLANT_DETAIL = {
  area_id: "",
  price: "",
  estimated_income: "",
  yield_rate: "",
  unit_price: "",
  connected_at: "",
  power_type: "",
  deal_type: "",
  panel_manufacturer: "",
  pv_manufacturer: "",
  power_generation_yearly: "",
  over_loading_rate: "",
  fit_income: "",
  has_maintenance: "",
  maintenance_company: "",
  maintenance_cost: "",
  has_insurance: "",
  insurance_company: "",
  insurance_cost: "",
  rent: "",
  observation_point: "",
  yearly_income: "",
  insolation: "",
  azimuth_angle: "",
  loss_rate: "",
  module_manufacturer: "",
  module_power_generation: "",
  module_panel_count: "",
  module_panel_capacity: "",
  tilt_angle: "",
  module_model: "",
  module_output_guarantee: "",
  module_product_guarantee: "",
  pv_model: "",
  pv_capacity: "",
  pv_conversion_efficiency: "",
  pv_product_guarantee: "",
  co2_emission_reduction: "",
  plant_id_text: "",
  credit_company: "",
  bank_loan: "",
};
const INITIAL_SUMMARIES = { power: 0, revenue: 0 };
const MODE_EDIT = "edit";
const SIMULATION_KEYS_REQUIRED = [
  "area_id",
  "elapsed_years",
  "elapsed_months",
  "yearly_income",
];
const SELL_CONTACT_CHOICES = [
  "すぐに現金化したい",
  "時間がかかっても高く売りたい",
  "金額次第では売りたい",
  "まずは査定額を知りたい",
];

const toTwoDigits = (n) => {
  return n >= 10 ? n.toString() : `0${n}`;
};

const yesNo = (v) => {
  if (v == null) {
    return "";
  }

  let vs = v.toString();

  if (vs == "0") {
    return "なし";
  } else if (vs == "1") {
    return "あり";
  }

  return "";
};

const getAreaAsync = async (id) => {
  // const {idToken: {jwtToken}} = await Auth.currentSession()
  // const headers = {Authorization: jwtToken}

  // const queryStringParameters = {
  //   fields: ['id', 'name', 'parent_id'],
  // }
  // return await API.get('solsell', `v0/simulators/areas/${id}`, {
  //   headers, queryStringParameters
  // })

  return await API.get("solsell", `v0/simulators/nedo-areas/${id}`);
};

const listAreasAsync = async (parentId = null) => {
  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();
  const headers = { Authorization: jwtToken };

  const queryStringParameters = {
    fields: ["id", "name", "parent_id"],
    limit: -1,
    parent_id: parentId,
  };
  const r = await API.get("solsell", "v0/simulators/areas", {
    headers,
    queryStringParameters,
  });

  return r.data;
};

const WeatherIcon = ({ classNames, weatherId }) => {
  return (
    <img
      className={classNames.join(" ")}
      src={`/matomete-solar/img/icon-weather-${weatherId}.png`}
    />
  );
};

const Detail = ({ plants, onSetPlantId }) => {
  const { id } = useParams();

  const [area, setArea] = useState();
  const [areas, setAreas] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [jmaForecasts, setJmaForecasts] = useState();
  const [jmaMessage, setJmaMessage] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [parentAreaId, setParentAreaId] = useState();
  const [parentAreas, setParentAreas] = useState();
  const [plant, setPlant] = useState();
  const [plantDetail, setPlantDetail] = useState();
  const [plantsMap, setPlantsMap] = useState();
  const [sellContactSending, setSellContactSending] = useState(false);
  const [sellContactSent, setSellContactSent] = useState(false);
  const [sellContactValues, setSellContactValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [simulationData, setSimulationData] = useState();
  const [simulationResult, setSimulationResult] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [summaries, setSummaries] = useState(null);

  const getArea = async (areaId) => {
    try {
      const r = await getAreaAsync(areaId);
      setArea(r);
    } catch (err) {
      setErrorMessage(err);
    }
  };

  const getReport = async () => {
    try {
      // init
      setIsLoading(true);
      setErrorMessage(null);

      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = { Authorization: jwtToken };

      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const tomorrow = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1
      );
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

      let queryStringParametersBase = {
        data_type: DATA_TYPE_PLANTS,
        fields: ["date_time", "power", "revenue", "plant_id"],
        plant_id: plant.id,
        service_id: plant.service_id,
      };

      const promises = [
        {
          start: format(today, DATE_FORMAT),
          end: format(tomorrow, DATE_FORMAT),
          range_type: RANGE_TYPE_DAILY,
        },
        {
          start: format(firstDay, DATE_FORMAT),
          end: format(nextMonth, DATE_FORMAT),
          range_type: RANGE_TYPE_MONTHLY,
        },
      ].map((item) => {
        const apiType = "matomete.solar";
        return API.get(apiType, "v0/reports", {
          headers,
          queryStringParameters: { ...queryStringParametersBase, ...item },
        });
      });
      const rs = await Promise.all(promises);

      const newSummaries = rs.map((r) => {
        let sm = { ...INITIAL_SUMMARIES };
        r.data.map((item) => {
          sm.power += item.power;
          sm.revenue +=
            !item.revenue && item.theoretical_revenue
              ? item.theoretical_revenue
              : item.revenue;
        });

        return sm;
      });

      setSummaries(newSummaries);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(err);
    }
  };

  const getPlantDetail = async () => {
    try {
      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = { Authorization: jwtToken };

      const r = await API.get("solsell", `v0/plant-details/${id}`, {
        headers,
      });
      let pd = {};
      Object.keys(r).map((k) => {
        const v = r[k];
        if (k == "connected_at") {
          if (v) {
            const d = new Date(v);
            pd[k] = `${d.getFullYear()}-${toTwoDigits(
              d.getMonth() + 1
            )}-${toTwoDigits(d.getDate())}`;
          } else {
            pd[k] = "";
          }
        } else {
          pd[k] = v == null ? "" : v.toString();
        }
      });
      setPlantDetail(pd);

      if (pd.area_id) {
        getArea(pd.area_id);
      } else {
        setArea();
      }

      // listParentAreas(plantDetail.area_id)
    } catch (err) {
      if (err.response && err.response.status == 404) {
        setArea();
        setPlantDetail(INITIAL_DATA_PLANT_DETAIL);
      } else {
        setErrorMessage(err);
      }
    }
  };

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setPlantDetail({ ...plantDetail, [name]: value });
  };

  const handleChangeArea = (e) => {
    const {
      target: { value },
    } = e;
    setPlantDetail({ ...plantDetail, area_id: value });
    setArea(areas.filter((item) => item.id == value)[0]);
  };

  const handleChangeParentArea = async (e) => {
    const {
      target: { value },
    } = e;

    setPlantDetail({ ...plantDetail, area_id: "" });

    if (!value) {
      setParentAreaId();
      setAreas();
      return;
    }

    setParentAreaId(value);

    try {
      const r = await getAreaAsync(value);
      setAreas(r.children);
    } catch (err) {
      setErrorMessage(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      };

      const r = await API.put("solsell", `v0/plant-details/${id}`, {
        body: plantDetail,
        headers,
        response: true,
      });

      setSuccessMessage("編集が完了しました。");
    } catch (err) {
      if (err.hasOwnProperty("response")) {
        if (err.response.status == 500) {
          setErrorMessage(
            "エラーが発生しました。恐れ入りますが、システム担当にお問い合わせくださいませ。"
          );
        } else if (err.response.status == 400) {
          const {
            data: { error },
          } = err.response;
          setErrorMessage(error);
        }
      }
    }

    setIsSubmitting(false);
    setMode();
  };

  const getJmaForecasts = async (areaId) => {
    if (!areaId) {
      return setJmaForecasts();
    }

    try {
      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = { Authorization: jwtToken };

      const r = await API.get("solsell", `v0/jma-forecasts/${areaId}`, {
        headers,
      });
      setJmaForecasts({
        ...r,
        weather_ids: r.weather_ids.map((item) => item.slice(0, 3)),
      });
    } catch (err) {
      setJmaMessage("天気情報はまだありません。"); // tmp
      // setErrorMessage(err)
    }
  };

  const getParentAreas = async () => {
    try {
      const r = await getAreaAsync("root");
      setParentAreas(r);

      if (area && area.parent_id) {
        setParentAreaId(area.parent_id);

        const r = await getAreaAsync(area.parent_id);
        setAreas(r.children);
      }
    } catch (err) {
      setErrorMessage(err);
    }
  };

  const handleChangeSellContact = (e) => {
    const {
      target: { checked, value },
    } = e;

    const newSellContactValues = checked
      ? sellContactValues.concat([value])
      : sellContactValues.filter((item) => item != value);

    setSellContactValues(newSellContactValues);
  };

  const handleSubmitSellContact = async (e) => {
    setSellContactSending(true);

    try {
      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      };

      const r = await API.post("solsell", `v0/contacts/matomete-solar-sell`, {
        body: {
          plant_id: id,
          values: sellContactValues,
        },
        headers,
        response: true,
      });

      setSellContactSent(true);
    } catch (err) {
      setModalIsOpen(false);
      setErrorMessage(err);
    }

    setSellContactSending(false);
  };

  const simulate = async (data) => {
    try {
      const {
        idToken: { jwtToken },
      } = await Auth.currentSession();
      const headers = {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      };

      const r = await API.post("solsell", `v0/simulators/sell/used-v1`, {
        body: data,
        headers,
        response: true,
      });

      setSimulationResult(r.data.data);
    } catch (err) {
      setErrorMessage(err);
    }
  };

  useEffect(() => {
    onSetPlantId(id);
  }, []);

  useEffect(() => {
    if (typeof plant == "undefined") {
      return;
    }

    setPlant(plantsMap[id]);
    setSummaries(null);

    getReport();
    getPlantDetail();
  }, [id]);

  useEffect(() => {
    if (mode != MODE_EDIT) {
      return;
    }

    if (!parentAreas) {
      getParentAreas();
    }
  }, [mode]);

  useEffect(() => {
    if (typeof plant == "undefined") {
      return;
    }

    getReport();
    getPlantDetail();
  }, [plant]);

  useEffect(() => {
    if (!plantDetail || mode == MODE_EDIT) {
      return;
    }

    // シミュレーション
    let newSimulationDataList = [];
    for (let i = 0; i < SIMULATION_KEYS_REQUIRED.length; i++) {
      const k = SIMULATION_KEYS_REQUIRED[i];
      const v = plantDetail[k];
      if (!v) {
        setSimulationResult();
        break;
      }

      newSimulationDataList.push(v);
    }

    if (newSimulationDataList.length == SIMULATION_KEYS_REQUIRED.length) {
      const newSimulationData = newSimulationDataList.join(",");
      if (simulationData != newSimulationData) {
        simulate(plantDetail);
      }
      setSimulationData(newSimulationData);
    }

    // 天気
    getJmaForecasts(plantDetail.area_id);
  }, [mode, plantDetail]);

  useEffect(() => {
    let pm = {};
    plants.map((item) => {
      pm[item.id.toString()] = item;
    });

    setPlantsMap(pm);
    setPlant(pm[id]);
  }, [plants]);

  return (
    <Fragment>
      <Panel>
        <div className={styles.panelCtn}>
          <div className={styles.panelHeadlineCtn}>
            {plant && <div className={styles.panelHeadline}>{plant.name}</div>}
            {mode == MODE_EDIT ? (
              <button
                className={styles.btnSubmit}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                編集完了
              </button>
            ) : (
              <button
                className={styles.btnEdit}
                onClick={(e) => setMode(MODE_EDIT)}
              >
                情報の編集
              </button>
            )}
          </div>
          {errorMessage && <Message message={errorMessage} />}
          {successMessage && <Message message={successMessage} typ="success" />}
          {jmaMessage && <div>{jmaMessage}</div>}
          {jmaForecasts && (
            <ul className={styles.jmaForecasts}>
              {jmaForecasts.weather.map((item, i) => (
                <li key={i}>
                  <div className={styles.jmaForecastsItemContent}>
                    {jmaForecasts.dates[i].replace("-", ".").replace("-", ".")}
                  </div>
                  <div className={styles.jmaForecastsItemContent}>
                    <div className={styles.jmaForecastsItemContentIconList}>
                      {jmaForecasts.weather_ids[i].map((item, ii) => {
                        const w = jmaForecasts.weather_ids[i];
                        if (w.length == 1) {
                          return (
                            <WeatherIcon
                              classNames={[
                                styles.jmaForecastsItemContentIconListItem,
                              ]}
                              key={ii}
                              weatherId={item}
                            />
                          );
                        }

                        if (
                          [
                            WEATHER_ID_ICHIJI,
                            WEATHER_ID_TOKIDOKI,
                            WEATHER_ID_NOCHI,
                          ].indexOf(item) != -1
                        ) {
                          return null;
                        }

                        // 時々は左が大きい、後は同じ大きさ
                        const cls =
                          w.indexOf(WEATHER_ID_NOCHI) == -1 && ii > 1
                            ? styles.jmaForecastsItemContentIconListItemHalfSm
                            : styles.jmaForecastsItemContentIconListItemHalf;

                        return (
                          <WeatherIcon
                            classNames={[
                              styles.jmaForecastsItemContentIconListItem,
                              cls,
                            ]}
                            key={ii}
                            weatherId={item}
                          />
                        );
                      })}
                    </div>
                    <div>{item}</div>
                  </div>
                  <div className={styles.jmaForecastsItemContent}>
                    <span className={styles.jmaForecastsItemContentTemperature}>
                      {jmaForecasts.temperature[i][0]}°
                    </span>
                    <span
                      className={styles.jmaForecastsItemContentTemperatureSep}
                    >
                      /
                    </span>
                    <span
                      className={`${styles.jmaForecastsItemContentTemperature} ${styles.min}`}
                    >
                      {jmaForecasts.temperature[i][1]}°
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {summaries && (
            <div className={styles.summaries}>
              <div className={styles.summaryCtn}>
                <div className={`${styles.summaryHeadline} ${styles.blue}`}>
                  発電量
                  <span className={styles.summaryHeadlineUnit}>（kWh）</span>
                </div>
                <div className={`${styles.summary} ${styles.blue}`}>
                  <div className={styles.summaryIcon}>
                    <img
                      className={styles.summaryIconKwh}
                      src={`/matomete-solar/img/icon-summary-kwh-avg.png`}
                    />
                  </div>
                  <div className={styles.summaryResults}>
                    <div className={styles.summaryResultsHalf}>
                      <div className={styles.summaryResultsRange}>Daily</div>
                      <div className={styles.summaryResultsValue}>
                        {(
                          Math.floor(summaries[0].power * 100) / 100
                        ).toLocaleString()}
                        <span className={styles.summaryResultsValueUnit}>
                          kWh
                        </span>
                      </div>
                    </div>
                    <div className={styles.summaryResultsHalf}>
                      <div className={styles.summaryResultsRange}>Monthly</div>
                      <div className={styles.summaryResultsValue}>
                        {(
                          Math.floor(summaries[1].power * 100) / 100
                        ).toLocaleString()}
                        <span className={styles.summaryResultsValueUnit}>
                          kWh
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.summaryCtn}>
                <div className={`${styles.summaryHeadline} ${styles.green}`}>
                  売電収入
                  <span className={styles.summaryHeadlineUnit}>（円）</span>
                </div>
                <div className={`${styles.summary} ${styles.green}`}>
                  <div className={styles.summaryIcon}>
                    <img
                      className={styles.summaryIconRevenue}
                      src={`/matomete-solar/img/icon-summary-revenue-avg.png`}
                    />
                  </div>
                  <div className={styles.summaryResults}>
                    <div className={styles.summaryResultsHalf}>
                      <div className={styles.summaryResultsRange}>Daily</div>
                      <div className={styles.summaryResultsValue}>
                        {Math.floor(summaries[0].revenue).toLocaleString()}
                        <span className={styles.summaryResultsValueUnit}>
                          円
                        </span>
                      </div>
                    </div>
                    <div className={styles.summaryResultsHalf}>
                      <div className={styles.summaryResultsRange}>Monthly</div>
                      <div className={styles.summaryResultsValue}>
                        {Math.floor(summaries[1].revenue).toLocaleString()}
                        <span className={styles.summaryResultsValueUnit}>
                          円
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.summaries}>
            {simulationResult && (
              <div className={styles.summaryCtn}>
                <div className={styles.summaryHeadline}>
                  評価額
                  <span className={styles.summaryHeadlineUnit}>（円）</span>
                </div>
                <div className={`${styles.summary} ${styles.lightBlue}`}>
                  <div className={styles.valuation}>
                    {(simulationResult[1] * 10000).toLocaleString()}
                  </div>
                </div>
              </div>
            )}
            <div
              className={styles.summaryCtn}
              style={{ alignSelf: "flex-end" }}
            >
              {/*<div className={styles.summaryHeadline}>
                購入希望者<span className={styles.summaryHeadlineUnit}>（人）</span>
              </div>
              <div className={`${styles.summary} ${styles.lightGrey}`}>
                物件データを入力後に表示されます
              </div>*/}
              <button
                className={`${styles.btn} ${styles.btnSell}`}
                onClick={(e) => setModalIsOpen(true)}
              >
                売却相談
              </button>
            </div>
          </div>
          {/*<hr/>
          <div className={styles.summaries}>
            <div className={styles.summaryCtn}>
              <div className={styles.summaryHeadline}>
                遠隔監視ツール
              </div>
              <img className={styles.tool} src={`/matomete-solar/img/tool-mielooop.png`}/>
            </div>
            <div className={styles.summaryCtn}>
              <div className={styles.summaryHeadline}>
                メンテナンス会社
              </div>
              <div className={`${styles.summary} ${styles.lightBlue}`}>
                ABCメンテナンス
              </div>
            </div>
          </div>
          <hr/>
          <div className={styles.summaries}>
            <div className={styles.summaryCtn}>
              <div className={styles.summaryHeadline}>
                所在地
              </div>
              <div className={`${styles.summary} ${styles.lightBlue} ${styles.wide}`}>
                ●●都道府県●●市区町村●●番地●●-●●
              </div>
            </div>
          </div>
          <div className={styles.map}>
          </div>*/}
          {plantDetail && (
            <Fragment>
              <hr />
              <div
                className={`${styles.spec} ${
                  mode == MODE_EDIT ? styles.isEdit : ""
                }`}
              >
                <div
                  className={`${styles.summaryHeadline} ${styles.summaryHeadlineMb}`}
                >
                  物件データ一覧
                </div>
                <table>
                  <tbody>
                    <tr>
                      <th rowSpan="5">数値データ</th>
                      <td>物件価格{mode == MODE_EDIT ? "（万円）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="price"
                            onChange={handleChange}
                            placeholder="例）2000"
                            type="number"
                            value={plantDetail.price}
                          />
                        ) : (
                          <span>
                            {plantDetail.price
                              ? `${Number(
                                  plantDetail.price
                                ).toLocaleString()} 万円`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>推定投資収入{mode == MODE_EDIT ? "（万円）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="estimated_income"
                            onChange={handleChange}
                            placeholder="例）4000"
                            type="number"
                            value={plantDetail.estimated_income}
                          />
                        ) : (
                          <span>
                            {plantDetail.estimated_income
                              ? `${Number(
                                  plantDetail.estimated_income
                                ).toLocaleString()} 万円`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>利回り{mode == MODE_EDIT ? "（%）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="yield_rate"
                            onChange={handleChange}
                            placeholder="例）13.01"
                            type="number"
                            step="0.01"
                            value={plantDetail.yield_rate}
                          />
                        ) : (
                          <span>
                            {plantDetail.yield_rate
                              ? `${Number(
                                  plantDetail.yield_rate
                                ).toLocaleString()} %`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>売電単価{mode == MODE_EDIT ? "（円）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="unit_price"
                            onChange={handleChange}
                            placeholder="例）30"
                            type="number"
                            value={plantDetail.unit_price}
                          />
                        ) : (
                          <span>
                            {plantDetail.unit_price
                              ? `${Number(
                                  plantDetail.unit_price
                                ).toLocaleString()} 円`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>連系日（予定日）</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="connected_at"
                            onChange={handleChange}
                            placeholder="例）2020-01-01"
                            type="date"
                            value={plantDetail.connected_at}
                          />
                        ) : (
                          <span>{plantDetail.connected_at}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan="16">発電所データ</th>
                      <td>経過年数</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <div style={{ display: "flex" }}>
                            <select
                              className={styles.select}
                              name="elapsed_years"
                              onChange={handleChange}
                              value={plantDetail.elapsed_years}
                            >
                              <option value="">選択</option>
                              {[...Array(20)].map((_, i) => (
                                <option key={i} value={i}>
                                  {i}年
                                </option>
                              ))}
                            </select>
                            <select
                              className={styles.select}
                              name="elapsed_months"
                              onChange={handleChange}
                              value={plantDetail.elapsed_months}
                            >
                              <option value="">選択</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i} value={i}>
                                  {i}カ月
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div>
                            {plantDetail.elapsed_years && (
                              <div>
                                <span>{plantDetail.elapsed_years}年</span>
                                <Fragment>
                                  {plantDetail.elapsed_months && (
                                    <span>
                                      {plantDetail.elapsed_months}カ月
                                    </span>
                                  )}
                                </Fragment>
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>発電区分</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <select
                            className={styles.select}
                            name="power_type"
                            onChange={handleChange}
                            value={plantDetail.power_type}
                          >
                            <option value="">選択</option>
                            <option value="低圧">低圧</option>
                            <option value="高圧">高圧</option>
                            <option value="特別高圧">特別高圧</option>
                          </select>
                        ) : (
                          <span>{plantDetail.power_type}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>土地形態</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <select
                            className={styles.select}
                            name="deal_type"
                            onChange={handleChange}
                            value={plantDetail.deal_type}
                          >
                            <option value="">選択</option>
                            <option value="賃貸">賃貸</option>
                            <option value="売買">売買</option>
                            <option value="賃貸一括">賃貸一括</option>
                          </select>
                        ) : (
                          <span>{plantDetail.deal_type}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>パネルメーカー</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="panel_manufacturer"
                            onChange={handleChange}
                            placeholder="例）ソーラーフロンティア"
                            type="text"
                            value={plantDetail.panel_manufacturer}
                          />
                        ) : (
                          <span>{plantDetail.panel_manufacturer}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>パワコンメーカー</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="pv_manufacturer"
                            onChange={handleChange}
                            placeholder="例）SUNNY BOY"
                            type="text"
                            value={plantDetail.pv_manufacturer}
                          />
                        ) : (
                          <span>{plantDetail.pv_manufacturer}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>年間発電量{mode == MODE_EDIT ? "（kWh）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="power_generation_yearly"
                            onChange={handleChange}
                            placeholder="例）86400"
                            type="number"
                            value={plantDetail.power_generation_yearly}
                          />
                        ) : (
                          <span>
                            {plantDetail.power_generation_yearly
                              ? `${Number(
                                  plantDetail.power_generation_yearly
                                ).toLocaleString()} kWh`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>過積載率{mode == MODE_EDIT ? "（%）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="over_loading_rate"
                            onChange={handleChange}
                            placeholder="例）136"
                            type="number"
                            value={plantDetail.over_loading_rate}
                          />
                        ) : (
                          <span>
                            {plantDetail.over_loading_rate
                              ? `${Number(
                                  plantDetail.over_loading_rate
                                ).toLocaleString()} %`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        FIT期間の売電収入{mode == MODE_EDIT ? "（万円）" : ""}
                      </td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="fit_income"
                            onChange={handleChange}
                            placeholder="例）4000"
                            type="number"
                            value={plantDetail.fit_income}
                          />
                        ) : (
                          <span>
                            {plantDetail.fit_income
                              ? `${Number(
                                  plantDetail.fit_income
                                ).toLocaleString()} 万円`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>年間投資収入{mode == MODE_EDIT ? "（万円）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="yearly_income"
                            onChange={handleChange}
                            placeholder="例）300"
                            type="number"
                            value={plantDetail.yearly_income}
                          />
                        ) : (
                          <span>
                            {plantDetail.yearly_income
                              ? `${Number(
                                  plantDetail.yearly_income
                                ).toLocaleString()} 万円`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>メンテナンス契約</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <select
                            className={styles.select}
                            name="has_maintenance"
                            onChange={handleChange}
                            value={plantDetail.has_maintenance}
                          >
                            <option value="">選択</option>
                            <option value="0">なし</option>
                            <option value="1">あり</option>
                          </select>
                        ) : (
                          <span>{yesNo(plantDetail.has_maintenance)}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>メンテナンス会社</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="maintenance_company"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.maintenance_company}
                          />
                        ) : (
                          <span>{plantDetail.maintenance_company}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>メンテナンス費用</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="maintenance_cost"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.maintenance_cost}
                          />
                        ) : (
                          <span>{plantDetail.maintenance_cost}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>保険</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <select
                            className={styles.select}
                            name="has_insurance"
                            onChange={handleChange}
                            value={plantDetail.has_insurance}
                          >
                            <option value="">選択</option>
                            <option value="0">なし</option>
                            <option value="1">あり</option>
                          </select>
                        ) : (
                          <span>{yesNo(plantDetail.has_insurance)}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>保険会社</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="insurance_company"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.insurance_company}
                          />
                        ) : (
                          <span>{plantDetail.insurance_company}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>保険費用</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="insurance_cost"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.insurance_cost}
                          />
                        ) : (
                          <span>{plantDetail.insurance_cost}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        土地代・賃料{mode == MODE_EDIT ? "（万円/年）" : ""}
                      </td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="rent"
                            onChange={handleChange}
                            placeholder="例）20"
                            type="number"
                            value={plantDetail.rent}
                          />
                        ) : (
                          <span>
                            {plantDetail.rent
                              ? `${Number(
                                  plantDetail.rent
                                ).toLocaleString()} 万円/年`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan="7">発電・売電データ</th>
                      <td>観測地点</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <div>
                            <div className={styles.selectGroup}>
                              <select
                                className={styles.select}
                                name="parent_area_id"
                                onChange={handleChangeParentArea}
                                value={parentAreaId || ""}
                              >
                                <option value="">エリア1を選択</option>
                                {parentAreas && (
                                  <Fragment>
                                    {Object.keys(parentAreas).map((k) => (
                                      <option key={k} value={k}>
                                        {parentAreas[k]}
                                      </option>
                                    ))}
                                  </Fragment>
                                )}
                              </select>
                            </div>
                            {parentAreaId && (
                              <div className={styles.selectGroup}>
                                <select
                                  className={styles.select}
                                  name="area_id"
                                  onChange={handleChangeArea}
                                  value={plantDetail.area_id || ""}
                                >
                                  <option value="">エリア2を選択</option>
                                  {areas && (
                                    <Fragment>
                                      {areas.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Fragment>
                                  )}
                                </select>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>{area && <span>{area.name}</span>}</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        FIT期間の売電収入{mode == MODE_EDIT ? "（万円）" : ""}
                      </td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="fit_income"
                            onChange={handleChange}
                            placeholder="例）4000"
                            type="number"
                            value={plantDetail.fit_income}
                          />
                        ) : (
                          <span>
                            {plantDetail.fit_income
                              ? `${Number(
                                  plantDetail.fit_income
                                ).toLocaleString()} 万円`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>年間投資収入{mode == MODE_EDIT ? "（万円）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="yearly_income"
                            onChange={handleChange}
                            placeholder="例）300"
                            type="number"
                            value={plantDetail.yearly_income}
                          />
                        ) : (
                          <span>
                            {plantDetail.yearly_income
                              ? `${Number(
                                  plantDetail.yearly_income
                                ).toLocaleString()} 万円`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        年間想定発電量{mode == MODE_EDIT ? "（kWh）" : ""}
                      </td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="power_generation_yearly"
                            onChange={handleChange}
                            placeholder="例）86400"
                            type="number"
                            value={plantDetail.power_generation_yearly}
                          />
                        ) : (
                          <span>
                            {plantDetail.power_generation_yearly
                              ? `${Number(
                                  plantDetail.power_generation_yearly
                                ).toLocaleString()} kWh`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>日射量{mode == MODE_EDIT ? "（kWh/㎡）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="insolation"
                            onChange={handleChange}
                            placeholder="例）4.47"
                            type="number"
                            value={plantDetail.insolation}
                          />
                        ) : (
                          <span>
                            {plantDetail.insolation
                              ? `${Number(
                                  plantDetail.insolation
                                ).toLocaleString()} kWh/㎡`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>パネル方位角{mode == MODE_EDIT ? "（度）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <select
                            className={styles.select}
                            name="azimuth_angle"
                            onChange={handleChange}
                            value={plantDetail.azimuth_angle}
                          >
                            <option value="">選択</option>
                            {[
                              "0",
                              "15",
                              "30",
                              "45",
                              "60",
                              "75",
                              "90",
                              "105",
                              "120",
                              "135",
                              "150",
                              "165",
                              "180",
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}度
                              </option>
                            ))}
                          </select>
                        ) : (
                          <span>
                            {plantDetail.azimuth_angle
                              ? `${plantDetail.azimuth_angle} 度`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>ロス係数</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="loss_rate"
                            onChange={handleChange}
                            placeholder="例）82.34"
                            type="number"
                            value={plantDetail.loss_rate}
                          />
                        ) : (
                          <span>{plantDetail.loss_rate}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan="8">モジュールデータ</th>
                      <td>メーカー</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="module_manufacturer"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.module_manufacturer}
                          />
                        ) : (
                          <span>{plantDetail.module_manufacturer}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        パネル1枚の発電量{mode == MODE_EDIT ? "（W）" : ""}
                      </td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="module_power_generation"
                            onChange={handleChange}
                            placeholder="例）150"
                            type="number"
                            value={plantDetail.module_power_generation}
                          />
                        ) : (
                          <span>
                            {plantDetail.module_power_generation
                              ? `${Number(
                                  plantDetail.module_power_generation
                                ).toLocaleString()} W`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>パネル枚数{mode == MODE_EDIT ? "（枚）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="module_panel_count"
                            onChange={handleChange}
                            placeholder="例）400"
                            type="number"
                            value={plantDetail.module_panel_count}
                          />
                        ) : (
                          <span>
                            {plantDetail.module_panel_count
                              ? `${Number(
                                  plantDetail.module_panel_count
                                ).toLocaleString()} 枚`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>パネル総容量{mode == MODE_EDIT ? "（kw）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="module_panel_capacity"
                            onChange={handleChange}
                            placeholder="例）66.6"
                            type="number"
                            value={plantDetail.module_panel_capacity}
                          />
                        ) : (
                          <span>
                            {plantDetail.module_panel_capacity
                              ? `${Number(
                                  plantDetail.module_panel_capacity
                                ).toLocaleString()} kw`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>パネル設置角{mode == MODE_EDIT ? "（度）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <select
                            className={styles.select}
                            name="tilt_angle"
                            onChange={handleChange}
                            value={plantDetail.tilt_angle}
                          >
                            <option value="">選択</option>
                            {[
                              "10",
                              "20",
                              "30",
                              "40",
                              "50",
                              "60",
                              "70",
                              "80",
                              "90",
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}度
                              </option>
                            ))}
                          </select>
                        ) : (
                          <span>
                            {plantDetail.tilt_angle
                              ? `${plantDetail.tilt_angle} 度`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>型式</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="module_model"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.module_model}
                          />
                        ) : (
                          <span>{plantDetail.module_model}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>出力保証</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="module_output_guarantee"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.module_output_guarantee}
                          />
                        ) : (
                          <span>{plantDetail.module_output_guarantee}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>製品保証</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="module_product_guarantee"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.module_product_guarantee}
                          />
                        ) : (
                          <span>{plantDetail.module_product_guarantee}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan="5">パワコンデータ</th>
                      <td>メーカー</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="pv_manufacturer"
                            onChange={handleChange}
                            placeholder="例）SUNNY BOY"
                            type="text"
                            value={plantDetail.pv_manufacturer}
                          />
                        ) : (
                          <span>{plantDetail.pv_manufacturer}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>型式</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="pv_model"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.pv_model}
                          />
                        ) : (
                          <span>{plantDetail.pv_model}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>パワコン総容量{mode == MODE_EDIT ? "（kw）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="pv_capacity"
                            onChange={handleChange}
                            placeholder="例）55.5"
                            type="number"
                            value={plantDetail.pv_capacity}
                          />
                        ) : (
                          <span>
                            {plantDetail.pv_capacity
                              ? `${Number(
                                  plantDetail.pv_capacity
                                ).toLocaleString()} kw`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>変換効率{mode == MODE_EDIT ? "（%）" : ""}</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="pv_conversion_efficiency"
                            onChange={handleChange}
                            placeholder="例）96"
                            type="number"
                            value={plantDetail.pv_conversion_efficiency}
                          />
                        ) : (
                          <span>
                            {plantDetail.pv_conversion_efficiency
                              ? `${Number(
                                  plantDetail.pv_conversion_efficiency
                                ).toLocaleString()} %`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>製品保証</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="pv_product_guarantee"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.pv_product_guarantee}
                          />
                        ) : (
                          <span>{plantDetail.pv_product_guarantee}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan={mode == MODE_EDIT ? 1 : 2}>CO2削減効果</th>
                      <td>
                        CO2排出削減量{mode == MODE_EDIT ? "（kg-CO2）" : ""}
                      </td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            min="0"
                            name="co2_emission_reduction"
                            onChange={handleChange}
                            placeholder="例）30"
                            type="number"
                            value={plantDetail.co2_emission_reduction}
                          />
                        ) : (
                          <span>
                            {plantDetail.co2_emission_reduction
                              ? `${Number(
                                  plantDetail.co2_emission_reduction
                                ).toLocaleString()} kg-CO2`
                              : ""}
                          </span>
                        )}
                      </td>
                    </tr>
                    {mode != MODE_EDIT && (
                      <tr>
                        <td>杉の木に換算</td>
                        <td>
                          {plantDetail.power_generation_yearly
                            ? `約 ${(
                                Math.round(
                                  plantDetail.power_generation_yearly *
                                    0.0423 *
                                    10
                                ) / 10
                              ).toLocaleString()} 本`
                            : ""}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th rowSpan="3">融資関連</th>
                      <td>物件ID</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="plant_id_text"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.plant_id_text}
                          />
                        ) : (
                          <span>{plantDetail.plant_id_text}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>信販会社</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="credit_company"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.credit_company}
                          />
                        ) : (
                          <span>{plantDetail.credit_company}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>銀行融資</td>
                      <td>
                        {mode == MODE_EDIT ? (
                          <input
                            className={styles.input}
                            name="bank_loan"
                            onChange={handleChange}
                            placeholder=""
                            type="text"
                            value={plantDetail.bank_loan}
                          />
                        ) : (
                          <span>{plantDetail.bank_loan}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}
        </div>
      </Panel>
      <Modal isOpen={modalIsOpen}>
        {sellContactSent ? (
          <div>
            <p>申請ありがとうございます。</p>
            <p>
              担当者よりご連絡いたしますので、
              <br />
              今しばらくお待ちくださいませ。
            </p>
          </div>
        ) : (
          <div>
            <div className={styles.sellContactHeadline}>ご相談内容</div>
            <ul className={styles.sellContactChoicesList}>
              {SELL_CONTACT_CHOICES.map((item, i) => (
                <li key={i}>
                  <div className={styles.checkCircleBase}>
                    <input
                      checked={sellContactValues.includes(item)}
                      id={`sell-contact-choice-${i}`}
                      name="sell_contact_chilces"
                      onChange={handleChangeSellContact}
                      type="checkbox"
                      value={item}
                    />
                    <label htmlFor={`sell-contact-choice-${i}`}>{item}</label>
                  </div>
                </li>
              ))}
            </ul>
            <button
              className={`${styles.btn} ${styles.btnSell}  ${styles.btnSellSubmit}`}
              disabled={sellContactValues.length == 0 || sellContactSending}
              onClick={handleSubmitSellContact}
            >
              売却相談
            </button>
          </div>
        )}
      </Modal>
    </Fragment>
  );
};

export default Detail;
