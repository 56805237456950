import React from 'react'
import styles from '../../styles/ui/Panel.less'

const Panel = ({children}) => {
  return (
    <div className={styles.panel}>{children}</div>
  )
}

export default Panel
