import React from "react";
import { Link } from "react-router-dom";
import Panel from "../ui/Panel";
import styles from "../../styles/users/CreateUserModal.less";

const CreateUserModal = ({}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClick = (e) => {
    if (e.target.className.includes("click-to-close")) {
      setIsOpen(false);
    }
  };

  if (isOpen === false) return null;

  return (
    <div
      className={`click-to-close ${styles.modalOverlay}`}
      onClick={handleClick}
    >
      <div className={styles.modal}>
        <div className={`click-to-close ${styles.close}`}></div>
        <div className={styles.ctn}>
          <div>
            <div className={styles.msg}>
              現在表示できるデータがありません。
              <br />
              まずは物件のご登録をお願いいたします。
            </div>
            <Link className={styles.btn} to="/plants/create">
              新規物件を登録
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUserModal;
