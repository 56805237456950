import React from 'react'
import {Link} from 'react-router-dom'
import Panel from '../ui/Panel'
import styles from '../../styles/reports/UnitPriceModal.less'
import {updateUnitPriceModalClosed} from '../../utils/reports'

const UnitPrice = ({isClosed, onClick, plantId}) => {
  const handleClick = e => {
    updateUnitPriceModalClosed(true)
    onClick(e)
  }

  return (
    <div className={`${styles.modalOverlayWithLeftPadding} ${isClosed ? styles.closed : ''}`}>
      <div className={styles.modal}>
        <button className={styles.modalButtonClose} onClick={handleClick}>&times;</button>
        <div className={styles.ctn}>
          <div>
            <div className={styles.msg}>売電単価が登録されていません。<br/>まずは売電単価のご登録をお願いいたします。</div>
            <Link className={styles.btn} to={`/plants/${plantId}`}>売電単価を登録</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitPrice
