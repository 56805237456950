import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import React, {useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import Wrapper from './Wrapper'
import Message from '../ui/Message'
import styles from '../../styles/auth/SignIn.less'

const ForgotPassword = () => {
  const history = useHistory()
  const {pathname, state} = useLocation()

  const [errorMessage, setErrorMessage] = useState()
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    code: '', email: '', password: ''
  })
  const [successMessage, setSuccessMessage] = useState()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setErrorMessage()
    setSuccessMessage()
    setIsLoading(true)
    
    try {
      const headers = {'Content-Type': 'application/json'}
      const r = await API.post('solsell', 'v1/users/forgot-password', {
        body: {email: formValues.email},
        headers,
        response: true,
      })
      // const user = await Auth.forgotPassword(formValues.email)
      setSuccessMessage('メールアドレス宛にコードを送信しました。以下のフォームにコードと新しいパスワードをご入力いただき、ボタンを押下してください。')
      setIsCodeSent(true)
    }
    catch (err) {
      console.log(err)
      if(err.code == 'NotAuthorizedException') {
        setErrorMessage('権限がありません。')
      }
      else if(err.code == 'UserNotFoundException') {
        setErrorMessage('ユーザ名が存在しません。')
      }
      else {
        const msg = err.hasOwnProperty('message') ? err.message : err
        setErrorMessage(`エラーが発生しました。システム担当にお問い合わせください。Error: ${msg}`)
      }
    }

    setIsLoading(false)
  }

  const handleSubmitResetPassword = async e => {
    e.preventDefault()

    setErrorMessage()
    setSuccessMessage()
    setIsLoading(true)
    
    try {
      const user = await Auth.forgotPasswordSubmit(
        formValues.email, formValues.code, formValues.password
      )
      history.push({
        pathname: '/signin', 
        state: {error: 'お手数ですが、再度ログインしてください。'}
      })
      return
    }
    catch (err) {
      console.log(err)
      if(err.code == 'NotAuthorizedException') {
        setErrorMessage('権限がありません。')
      }
      else if(err.code == 'UserNotFoundException') {
        setErrorMessage('ユーザ名が存在しません。')
      }
      else if(err.message.indexOf('\'password\' failed to satisfy constraint') !== -1) {
        setErrorMessage('パスワードは8文字以上必要で英大文字、小文字、数字の全てを含む必要があります。')
      }
      else {
        const msg = err.hasOwnProperty('message') ? err.message : err
        setErrorMessage(`エラーが発生しました。システム担当にお問い合わせください。Error: ${msg}`)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if(state && state.hasOwnProperty('error')) {
      setErrorMessage(state.error)
    }
  }, [])

  return (
    <Wrapper errorMessage={errorMessage} title="パスワード再設定">
      {successMessage &&
        <Message message={successMessage} typ="success"/>
      }
      {isCodeSent !== true ? (
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label className={styles.label}>メールアドレス</label>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="email"
                onChange={handleChange}
                type="email"
                value={formValues.email}
              />
            </div>
          </div>
          <button className={styles.btn} disabled={isLoading} type="submit">コードを送信</button>
          <Link className={styles.link} to="/signin">ログインはこちら</Link>
        </form>
      ) : (
        <form onSubmit={handleSubmitResetPassword}>
          <div className={styles.formGroup}>
            <label className={styles.label}>メールアドレス</label>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="email"
                onChange={handleChange}
                type="email"
                value={formValues.email}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>コード</label>
            <div className={styles.inputCtn}>
              <input
                disabled={isLoading}
                name="code"
                onChange={handleChange}
                type="text"
                value={formValues.code}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>新パスワード</label>
              <div className={styles.inputCtn}>
                <input
                  disabled={isLoading}
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formValues.password}
                />
              </div>
          </div>
          <button className={styles.btn} disabled={isLoading} type="submit">パスワード再設定</button>
          <Link className={styles.link} to="/signin">ログインはこちら</Link>
        </form>
      )}
        
    </Wrapper>
  )
}

export default ForgotPassword
