import React from "react";

export default function DateRangePickerDisplay({
  end,
  onClick,
  start,
  styles,
}) {
  return (
    <div className={styles.dates}>
      <div className={styles.datesInner}>
        <div className={styles.txtComparing}>比較</div>
        <div className={styles.inputCtn}>
          <input
            onClick={onClick}
            readOnly
            type="text"
            value={toDatetime(start)}
          />
        </div>
        <div>〜</div>
        <div className={styles.inputCtn}>
          <input
            onClick={onClick}
            readOnly
            type="text"
            value={toDatetime(end)}
          />
        </div>
        {/*<Switch isOn={isSwitchOn} onClick={handleClickSwitch}/>*/}
      </div>
    </div>
  );
}

const toTwoDigits = (s) => {
  return s.length == 1 ? `0${s}` : s;
};

const toDatetime = (date) => {
  if (!date) {
    return "";
  }

  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();
  return `${y}.${toTwoDigits(m)}.${toTwoDigits(d)}`;
};
