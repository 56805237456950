import React from "react";
import {
  DATA_TYPE_CENSOR,
  DATA_TYPE_PCS,
  DATA_TYPE_PLANTS,
  FIELD_TYPES_JA,
  RANGE_TYPE_RAW,
  RANGE_TYPE_HOURLY,
  RANGE_TYPE_DAILY,
} from "../../constants/reports";

export default function Table({
  data,
  formValues,
  onClickToggleUnitPriceModal,
  plantDataSources,
  styles,
}) {
  const [dataKeys, setDataKeys] = React.useState([]);
  const [dataMap, setDataMap] = React.useState(null);
  const [fieldTypes, setFieldTypes] = React.useState([]);
  const [hasRevenue, setHasRevenue] = React.useState(null);
  const [tableHeaders, setTableHeaders] = React.useState([]);

  React.useEffect(() => {
    let newDataKeys = [];
    let newDataMap = {};
    let newDates = [];

    let newHasRevenue = false;
    data.forEach((item) => {
      if (!newDataMap.hasOwnProperty(item.date_time)) {
        newDataMap[item.date_time] = {};
      }

      if (!newDataMap.hasOwnProperty(item.date_time)) {
        newDataMap[item.date_time] = {};
      }
      if (!newDates.includes(item.date_time)) {
        newDates.push(item.date_time);
      }

      if (!newDataKeys.includes(item.name)) {
        newDataKeys.push(item.name);
      }

      if (item.revenue != null) {
        newHasRevenue = true;
      }

      newDataMap[item.date_time][item.name] = item;
    });
    setDataKeys(newDataKeys);
    setDataMap(newDataMap);
    setTableHeaders(Object.keys(newDataMap));
  }, [data]);

  React.useEffect(() => {
    let newFieldTypes = [];
    for (let pds of plantDataSources) {
      if (pds.data_type != formValues.data_type) continue;

      let matched = false;
      if (pds.range_type == RANGE_TYPE_RAW) {
        matched = true;
      } else if (pds.range_type == RANGE_TYPE_HOURLY) {
        matched = formValues.range_type != RANGE_TYPE_RAW;
      } else if (pds.range_type == RANGE_TYPE_DAILY) {
        matched =
          formValues.range_type != RANGE_TYPE_RAW &&
          formValues.range_type != RANGE_TYPE_HOURLY;
      }
      // monthly, yearly が plant_data_source なことはない
      if (matched && !newFieldTypes.includes(pds.field_type)) {
        newFieldTypes.push(pds.field_type);
      }
    }
    setFieldTypes(newFieldTypes);
  }, [formValues, plantDataSources]);

  return (
    <div className={styles.fixedColumnTable}>
      <div className={styles.fixedColumnTableFixed}>
        <div className={styles.fixedColumnTableRow}>
          {formValues.data_type != DATA_TYPE_PLANTS && (
            <div className={styles.fixedColumnTableColumn}>
              <div className={styles.fixedColumnTableCell} />
            </div>
          )}
          <div className={styles.fixedColumnTableColumn}>
            <div className={styles.fixedColumnTableCell} />
          </div>
        </div>
        {dataKeys.map((k, i) => (
          <div className={styles.fixedColumnTableRow} key={i}>
            {formValues.data_type != DATA_TYPE_PLANTS && (
              <div className={styles.fixedColumnTableColumn}>
                <div
                  className={`${styles.fixedColumnTableCell} ${
                    styles[`fixedColumnTableCell${fieldTypes.length}x`]
                  } ${styles.fixedColumnTableCellWithBorder}`}
                >
                  {(formValues.data_type == DATA_TYPE_PCS ||
                    formValues.data_type == DATA_TYPE_CENSOR) && (
                    <div className={styles.fixedColumnTableCellContent}>
                      {k}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.fixedColumnTableColumn}>
              {fieldTypes.map((item, i) => {
                return (
                  <div
                    className={`${styles.fixedColumnTableCell} ${
                      i % 2 == 0
                        ? styles.fixedColumnTableCellBlue
                        : styles.fixedColumnTableCellBeige
                    }`}
                    key={i}
                  >
                    <div className={styles.fixedColumnTableCellContent}>
                      {FIELD_TYPES_JA[item]}
                      {hasRevenue === false && (
                        <div
                          className={styles.fixedColumnTableCellContentAlert}
                          onClick={onClickToggleUnitPriceModal}
                        >
                          <div>！</div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {/* <div
                className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBlue}`}
              >
                <div className={styles.fixedColumnTableCellContent}>
                  発電量（{powerUnit}）
                </div>
              </div>
              {powerUnit == POWER_UNIT_KWH && (
                <div
                  className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBeige}`}
                >
                  <div className={styles.fixedColumnTableCellContent}>
                    売電収入（円）
                    {hasNoRevenue && (
                      <div
                        className={styles.fixedColumnTableCellContentAlert}
                        onClick={onClickToggleUnitPriceModal}
                      >
                        <div>！</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {serviceId == SERVICE_ID_SOLAMAME && (
                <div className={`${styles.fixedColumnTableCell} `}>
                  <div className={styles.fixedColumnTableCellContent}>
                    日射強度（kW/m<sup>2</sup>）
                  </div>
                </div>
              )} */}
            </div>
          </div>
        ))}
      </div>
      <div
        className={`${styles.fixedColumnTableScrollable} ${
          formValues.data_type == DATA_TYPE_PLANTS
            ? styles.fixedColumnTableScrollableDataTypePlants
            : ""
        }`}
      >
        <div className={styles.fixedColumnTableRow}>
          {tableHeaders.map((item, i) => (
            <div className={styles.fixedColumnTableColumn} key={i}>
              <div className={styles.fixedColumnTableCell}>
                <div className={styles.fixedColumnTableCellContent}>{item}</div>
              </div>
            </div>
          ))}
        </div>
        {dataKeys.map((dk, i) => {
          return (
            <div className={styles.fixedColumnTableRow} key={i}>
              {tableHeaders.map((date, ii) => {
                const data = dataMap[date];
                if (!data.hasOwnProperty(dk)) {
                  return null;
                }
                return (
                  <div className={styles.fixedColumnTableColumn} key={ii}>
                    {fieldTypes.map((fieldType, i) => {
                      return (
                        <div
                          className={`${styles.fixedColumnTableCell} ${
                            i % 2 == 0
                              ? styles.fixedColumnTableCellBlue
                              : styles.fixedColumnTableCellBeige
                          }`}
                          key={fieldType}
                        >
                          <div className={styles.fixedColumnTableCellContent}>
                            {data[dk][fieldType]}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );

                // const { insolation, power, revenue, theoretical_revenue } =
                //   data[dk];
                // let rev = "";
                // if (!hasNoRevenue) {
                //   const v = revenue != null ? revenue : theoretical_revenue;
                //   rev = (v || 0).toLocaleString();
                // }
                // return (
                //   <div className={styles.fixedColumnTableColumn} key={ii}>
                //     {fieldTypes.map(fieldType => {
                //       return (
                //         <div
                //           className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBlue}`}
                //         >
                //           <div className={styles.fixedColumnTableCellContent}>
                //             {power.toLocaleString()}
                //           </div>
                //         </div>
                //       )
                //     })}
                //     <div
                //       className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBlue}`}
                //     >
                //       <div className={styles.fixedColumnTableCellContent}>
                //         {power.toLocaleString()}
                //       </div>
                //     </div>
                //     {powerUnit == POWER_UNIT_KWH && (
                //       <div
                //         className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBeige}`}
                //       >
                //         <div className={styles.fixedColumnTableCellContent}>
                //           {rev}
                //         </div>
                //       </div>
                //     )}
                //     {serviceId == SERVICE_ID_SOLAMAME && (
                //       <div className={`${styles.fixedColumnTableCell} `}>
                //         <div className={styles.fixedColumnTableCellContent}>
                //           {insolation.toLocaleString()}
                //         </div>
                //       </div>
                //     )}
                //   </div>
                // );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

/**
 * 
                      
                        
                          
                        {dataKeys.map((k, i) => (
                          <div className={styles.fixedColumnTableRow} key={i}>
                            {formValues.data_type != DATA_TYPE_PLANTS && (
                              <div className={styles.fixedColumnTableColumn}>
                                <div
                                  className={`${styles.fixedColumnTableCell} ${
                                    powerUnit == POWER_UNIT_KWH
                                      ? styles.fixedColumnTableCell2x
                                      : ""
                                  } ${styles.fixedColumnTableCellWithBorder}`}
                                >
                                  {formValues.data_type == DATA_TYPE_PCS && (
                                    <div
                                      className={
                                        styles.fixedColumnTableCellContent
                                      }
                                    >
                                      {k}
                                    </div>
                                  )}
                                  {formValues.data_type == DATA_TYPE_CENSOR && (
                                    <div
                                      className={
                                        styles.fixedColumnTableCellContent
                                      }
                                    >
                                      {k}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className={styles.fixedColumnTableColumn}>
                              <div
                                className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBlue}`}
                              >
                                <div
                                  className={styles.fixedColumnTableCellContent}
                                >
                                  発電量（{powerUnit}）
                                </div>
                              </div>
                              {powerUnit == POWER_UNIT_KWH && (
                                <div
                                  className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBeige}`}
                                >
                                  <div
                                    className={
                                      styles.fixedColumnTableCellContent
                                    }
                                  >
                                    売電収入（円）
                                    {hasNoUnitPrice && (
                                      <div
                                        className={
                                          styles.fixedColumnTableCellContentAlert
                                        }
                                        onClick={
                                          handleClickToggleUnitPriceModal
                                        }
                                      >
                                        <div>！</div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {isSolamame && (
                                <div
                                  className={`${styles.fixedColumnTableCell} `}
                                >
                                  <div
                                    className={
                                      styles.fixedColumnTableCellContent
                                    }
                                  >
                                    日射強度（kW/m<sup>2</sup>）
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className={`${styles.fixedColumnTableScrollable} ${
                          formValues.data_type == DATA_TYPE_PLANTS
                            ? styles.fixedColumnTableScrollableDataTypePlants
                            : ""
                        }`}
                      >
                        <div className={styles.fixedColumnTableRow}>
                          {tableData.headers.map((item, i) => (
                            <div
                              className={styles.fixedColumnTableColumn}
                              key={i}
                            >
                              <div className={styles.fixedColumnTableCell}>
                                <div
                                  className={styles.fixedColumnTableCellContent}
                                >
                                  {item}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {dataKeys.map((k, i) => (
                          <div className={styles.fixedColumnTableRow} key={i}>
                            {tableData[`power${k}`].map((item, ii) => {
                              const ins = tableData[`insolation${k}`][ii];
                              return (
                                <div
                                  className={styles.fixedColumnTableColumn}
                                  key={ii}
                                >
                                  <div
                                    className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBlue}`}
                                  >
                                    <div
                                      className={
                                        styles.fixedColumnTableCellContent
                                      }
                                    >
                                      {item.toLocaleString()}
                                    </div>
                                  </div>
                                  {powerUnit == POWER_UNIT_KWH && (
                                    <div
                                      className={`${styles.fixedColumnTableCell} ${styles.fixedColumnTableCellBeige}`}
                                    >
                                      <div
                                        className={
                                          styles.fixedColumnTableCellContent
                                        }
                                      >
                                        {tableData[`revenue${k}`][
                                          ii
                                        ].toLocaleString()}
                                      </div>
                                    </div>
                                  )}
                                  {isSolamame && (
                                    <div
                                      className={`${styles.fixedColumnTableCell} `}
                                    >
                                      <div
                                        className={
                                          styles.fixedColumnTableCellContent
                                        }
                                      >
                                        {tableData[`insolation${k}`][
                                          ii
                                        ].toLocaleString()}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </div>
 */
