export const SERVICE_ID_MIELOOOP = "1";
export const SERVICE_ID_ECO_MEGANE = "2";
export const SERVICE_ID_ENAVISION = "4";
export const SERVICE_ID_LEYE = "5";
export const SERVICE_ID_SOLAR_MONITOR = "6";
export const SERVICE_ID_B_NUSS = "7";
export const SERVICE_ID_SOLAMAME = "8";
export const SERVICE_ID_ECO_MEGANE_INDIVIDUAL = "9";
export const SERVICE_ID_SMARTPV = "10";
export const SERVICE_ID_TERU2 = "11";
export const SERVICE_ID_SUNNY_PORTAL = "12";
export const SERVICE_ID_KINKEI = "13";
export const SERVICE_ID_FISTER = "14";
export const SERVICE_ID_SOLARVIEW = "15";
export const SERVICE_ID_LUFY = "16";
export const SERVICE_ID_SANIX_EYE = "17";
export const SERVICE_ID_ITSUDOKO_MONITOR = "18";
export const SERVICE_ID_PVSAFETY = "19";
export const SERVICE_ID_OZUCLOUD = "20";
export const SERVICE_ID_TABUCHI_CLOUD = "21";
export const SERVICE_ID_SOLAR_EDGE = "22";
export const SERVICE_ID_SOLAR_REMON = "23";
export const SERVICE_ID_HAWKSYSTEM = "24";
export const SERVICE_ID_SOLAR_POWER_MONITOR = "25";
export const SERVICE_ID_HIDAMARI_EYES = "26";
export const SERVICE_ID_MYDELTASOLAR = "27";
export const SERVICE_ID_MIHARIBAN = "28";
export const SERVICE_ID_MIELOOOP_ADMIN = "29";
export const SERVICE_ID_FUSIONSOLAR = "30";

export const SERVICES_IDS_JA = {
  [SERVICE_ID_MIELOOOP]: "みえるーぷ",
  [SERVICE_ID_ECO_MEGANE_INDIVIDUAL]: "エコめがね",
  [SERVICE_ID_ECO_MEGANE]: "エコめがね（販売会社様向け）",
  [SERVICE_ID_ENAVISION]: "エナビジョン",
  [SERVICE_ID_LEYE]: "L eye",
  [SERVICE_ID_B_NUSS]: "Venus Solar",
  [SERVICE_ID_SOLAMAME]: "ソラマメ",
  [SERVICE_ID_SMARTPV]: "SmartPV",
  [SERVICE_ID_TERU2]: "てるてるモニタ",
  [SERVICE_ID_SUNNY_PORTAL]: "SUNNY PORTAL",
  [SERVICE_ID_KINKEI]: "近計システム",
  [SERVICE_ID_FISTER]: "Firster",
  [SERVICE_ID_SOLARVIEW]: "SolarView Air（コンテック）",
  [SERVICE_ID_SOLAR_MONITOR]: "SolarMonitor",
  [SERVICE_ID_LUFY]: "発電所長",
  [SERVICE_ID_SANIX_EYE]: "SANIX EYE",
  [SERVICE_ID_ITSUDOKO_MONITOR]: "いつどこモニタ",
  [SERVICE_ID_PVSAFETY]: "PVSAFETY",
  [SERVICE_ID_OZUCLOUD]: "TERASOL",
  [SERVICE_ID_TABUCHI_CLOUD]: "TABUCHI クラウド",
  [SERVICE_ID_SOLAR_EDGE]: "Solar Edge",
  [SERVICE_ID_SOLAR_REMON]: "Solar Remon",
  [SERVICE_ID_MYDELTASOLAR]: "My Delta Solar Cloud",
  [SERVICE_ID_MIHARIBAN]: "発電見張り番",
  [SERVICE_ID_MIELOOOP_ADMIN]: "みえるーぷ（管理ツール）",
  [SERVICE_ID_FUSIONSOLAR]: "FusionSolar",
};
