import React, {useEffect, useState} from 'react'
import styled, {useTheme} from 'styled-components'
import ButtonLink from '../ui/ButtonLink'
import Panel from '../ui/Panel'
import {listData} from '../../api/matomete-solar'
import {listData as listDataReports} from '../../api/reports'
import {DATA_TYPE_PLANTS, RANGE_TYPE_DAILY, RANGE_TYPE_MONTHLY, RANGE_TYPE_YEARLY} from '../../constants/reports'

// tmp
import {useLocation} from 'react-router-dom'

const DatasetPanel = () => {
  const [dataset, setDataset] = useState([{}, {}, {}])
  const [dataD, setDataD] = useState()
  const [dataM, setDataM] = useState()
  const [dataY, setDataY] = useState()
  const [isSubscribed, setIsSubscribed] = useState(true)

  const {pathname, search} = useLocation()

  const theme = useTheme()

  const updateData = async (q, callback) => {
    const r = await listDataReports(q)
    
    let d = {power: 0, revenue: 0}
    r.map(item => {
      d.power += item.power
      d.revenue += (!item.revenue && item.theoretical_revenue) ? item.theoretical_revenue : item.revenue
    })

    d.power = d.power.toFixed(2)
    d.revenue = Math.floor(d.revenue)

    callback(d)
  }

  useEffect(() => {
    let q = {
      data_type: DATA_TYPE_PLANTS,
      fields: ['date_time', 'power', 'revenue', 'theoretical_revenue'],
    }
    
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()

    const qd = {
      start: new Date(year, month, day),
      end: new Date(year, month, day),
      range_type: RANGE_TYPE_DAILY,
      ...q
    }
    updateData(qd, setDataD)

    const qm = {
      start: new Date(year, month, 1),
      end: new Date(year, month + 1, 0),
      range_type: RANGE_TYPE_MONTHLY,
      ...q
    }
    updateData(qm, setDataM)

    const qy = {
      start: new Date(year - 28, 0, 1),
      end: new Date(year, 11, 31),
      range_type: RANGE_TYPE_YEARLY,
      ...q
    }
    updateData(qy, setDataY)

    // (async () => {
    //   let q = {
    //     data_type: DATA_TYPE_PLANTS,
    //     fields: ['date_time', 'power', 'revenue', 'theoretical_revenue'],
    //   }

    //   const f = listDataReports // search.indexOf('reports=reports') === -1 ? listData : listDataReports

    //   const now = new Date()
    //   const year = now.getFullYear()
    //   const month = now.getMonth()
    //   const day = now.getDate()

    //   const qd = {
    //     start: new Date(year, month, day),
    //     end: new Date(year, month, day),
    //     range_type: RANGE_TYPE_DAILY,
    //     ...q
    //   }

    //   const qm = {
    //     start: new Date(year, month, 1),
    //     end: new Date(year, month + 1, 0),
    //     range_type: RANGE_TYPE_MONTHLY,
    //     ...q
    //   }

    //   const qy = {
    //     start: new Date(1970, 0, 1),
    //     end: new Date(year, 11, 31),
    //     range_type: RANGE_TYPE_YEARLY,
    //     ...q
    //   }

    //   const rs = await Promise.all([
    //     f(qd),
    //     f(qm),
    //     f(qy),
    //   ])

    //   let dataset = []
    //   rs.map(r => {
    //     let data = {power: 0, revenue: 0}
    //     r.map(item => {
    //       data.power += item.power
    //       data.revenue += (!item.revenue && item.theoretical_revenue) ? item.theoretical_revenue : item.revenue
    //     })

    //     data.power = data.power.toFixed(2)
    //     data.revenue = Math.floor(data.revenue)

    //     dataset.push(data)
    //   })

    //   setDataset(dataset)
    // })()
    return () => {
      return setIsSubscribed(false)
    }
  }, [])

  return (
    <Panel>
      <Container>
        <FlexContainer>
          <FlexItemWithBorder>
            <Data main>
              <div><Icon src={`/matomete-solar/img/icon-kwh.png`}/></div>
              <div>
                <Label>今日の発電量</Label>
                <ValueText k="power" v={dataD}/>
              </div>
            </Data>
            <FlexContainer>
              <FlexItem>
                <Data>
                  <div>
                    <Label>今月の発電量</Label>
                    <ValueText k="power" v={dataM}/>
                  </div>
                </Data>
              </FlexItem>
              <FlexItem>
                <Data>
                  <div>
                    <Label>総発電量</Label>
                    <ValueText k="power" v={dataY}/>
                  </div>
                </Data>
              </FlexItem>
            </FlexContainer>
          </FlexItemWithBorder>
          <FlexItem>
            <Data main>
              <div><Icon src={`/matomete-solar/img/icon-kwh-yen.png`}/></div>
              <div>
                <Label>今日の売電収入</Label>
                <ValueText color={theme.palette.tertiary.main} k="revenue" v={dataD}/>
              </div>
            </Data>
            <FlexContainer>
              <FlexItem>
                <Data>
                  <div>
                    <Label>今月の売電収入</Label>
                    <ValueText color={theme.palette.tertiary.main} k="revenue" v={dataM}/>
                  </div>
                </Data>
              </FlexItem>
              <FlexItem>
                <Data>
                  <div>
                    <Label>総売電収入</Label>
                    <ValueText color={theme.palette.tertiary.main} k="revenue" v={dataY}/>
                  </div>
                </Data>
              </FlexItem>
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
        <PlantCreateButtonLink to="/plants/create">新規物件を登録</PlantCreateButtonLink>
      </Container>
    </Panel>
  )
}

const Container = styled.div`
  padding: ${props => props.theme.spacing[6]} 0;
`

const FlexContainer = styled.div`
  display: flex;
`

const FlexItem = styled.div`
  box-sizing: border-box;
  flex-basis: 50%;
`

const FlexItemWithBorder = styled(FlexItem)`
  border-right: solid ${props => `${1 / props.theme.vw}vw ${props.theme.palette.default.main}`};
`

const Icon = styled.img`
  margin-right: ${props => 35 / props.theme.vw}vw;
  width: ${props => 94 / props.theme.vw}vw;
`

const Label = styled.div`
  font-size: ${props => 24 / props.theme.vw}vw;
`

const Data = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin-bottom: ${props => props.main ? props.theme.spacing[6] : 0};
`

const PlantCreateButtonLink = styled(ButtonLink)`
  margin: ${props => props.theme.spacing[6]} auto 0;
  padding: ${props => 17 / props.theme.vw}vw;
  width: ${props => 240 / props.theme.vw}vw;
`

const Value = styled.div`
  color: ${props => props.color ? props.color : props.theme.palette.primary.light};
  font-size: ${props => 40 / props.theme.vw}vw;
`

const Unit = styled.span`
  font-size: ${props => 24 / props.theme.vw}vw;
  margin-left: ${props => props.theme.spacing[1]};
`

const ValueText = ({k, v, ...rest}) => {
  let t = '…'

  if(v && (v[k] >= 0)) {
    t = v[k].toLocaleString()
  }

  let u = ''
  if(k == 'power') {
    u = 'kWh'
  }
  else if(k == 'revenue') {
    u = '円'
  }

  return (
    <Value {...rest}>{t}{u && <Unit>{u}</Unit>}</Value>
  )
}

export default DatasetPanel
