import {API} from 'aws-amplify'
import React, {Fragment, useEffect, useState} from 'react'
import styles from '../../../styles/Base.less'


const Select = ({onChange, value: defaultValue}) => {
  const [isSubscribed, setIsSubscribed] = useState(true)
  const [items, setItems] = useState()
  const [value, setValue] = useState('')

  const listCategories = async () => {
    try {
      const r = await API.get('solsell', `v0/alert-code-categories`, {
        queryStringParameters: {
          fields: ['id', 'name'],
          limit: -1
        }
      })
      setItems(r.data)
    }
    catch(err) {
      console.log(err)
    }
  }

  const handleChange = e => {
    const {target: {name, value}} = e
    setValue(value)

    if(onChange) {
      onChange(value)
    }
  }

  useEffect(() => {
    listCategories()
    return () => {
      return setIsSubscribed(false)
    }
  }, [])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <div className={styles.select}>
      <select name="alert_code_category_id" onChange={handleChange} value={value}>
        {typeof items != 'undefined' ? (
          <Fragment>
            <option value="">選択</option>
            <option value="-1">未分類</option>
            {items.map(item =>
              <option key={item.id} value={item.id}>{item.name}</option>
            )}
          </Fragment>
        ) : (
          <option value="">読み込み中…</option>
        )}
      </select>
    </div>
  )
}

export default Select
