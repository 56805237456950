import { API, Auth } from "aws-amplify";
import React, { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import SocialLoginContainer from "./SocialLoginContainer";
import Message from "../ui/Message";
import styles from "../../styles/auth/SignIn.less";

const Button = styled.button`
  background-color: #50aaca;
  border-radius: 120px;
  box-sizing: border-box;
  border: none;
  color: #ffffff;
  display: block;
  height: 50px;
  margin: 0 auto;
  width: 220px;
  &:disabled {
    background-color: #afd4e2;
  }
`;

const FormGroup = styled.div`
  margin-top: 20px;
`;

const Input = styled.input`
  border: 1px solid #d2d2d7;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 12px 15px;
  width: 100%;
`;

const Label = styled.label`
  color: #858585;
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
`;

const SignInWithEmailOpened = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { pathname, state } = useLocation();

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage();
    setIsLoading(true);

    try {
      const r = await API.post("solsell", `v1/users/migrate`, {
        body: formValues,
        headers: { "Content-Type": "application/json" },
        response: true,
      });

      const user = await Auth.signIn(formValues.email, formValues.password);
      console.log(user);

      const next =
        state && state.hasOwnProperty("next") ? state.next : { pathname: "/" };
      return history.replace(next);
    } catch (err) {
      if (err.code == "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        setErrorMessage(
          "ログインできませんでした。パスワードをご確認ください。"
        );
      } else if (err.code == "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        setErrorMessage(
          "ログインできませんでした。ユーザ名とパスワードをご確認ください。"
        );
      } else if (err.code == "UserNotConfirmedException") {
        history.replace("/verify");
        return;
      } else {
        const msg = err.hasOwnProperty("message") ? err.message : err;
        setErrorMessage(
          `エラーが発生しました。システム担当にお問い合わせください。Error: ${msg}`
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <Fragment>
      {errorMessage && <Message message={errorMessage} />}
      <FormGroup>
        <Label htmlFor="email">メールアドレス</Label>
        <Input
          disabled={isLoading}
          id="email"
          name="email"
          onChange={handleChange}
          type="email"
          value={formValues.email}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="password">パスワード</Label>
        <Input
          disabled={isLoading}
          id="password"
          name="password"
          onChange={handleChange}
          type="password"
          value={formValues.password}
        />
      </FormGroup>
      <FormGroup>
        <Button disabled={isLoading} onClick={handleSubmit}>
          ログイン
        </Button>
      </FormGroup>
    </Fragment>
  );
};

export default SignInWithEmailOpened;
