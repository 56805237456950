import React from "react";
import styles from "../../styles/ui/Message.less";

const Message = ({ children, message, typ }) => {
  let classNames = [styles.message, typ ? styles[typ] : styles.error];

  let msg = message;
  if (typeof msg == "object") {
    if (msg.hasOwnProperty("response")) {
      if (
        typeof msg.response != "undefined" &&
        msg.response.hasOwnProperty("data")
      ) {
        if (msg.response.data) {
          msg = msg.response.data;
        } else {
          msg = msg.response.statusText;
        }
      }
    }
    if (msg.hasOwnProperty("message")) {
      msg = msg.message;
    }

    if (msg.hasOwnProperty("error")) {
      msg = msg.error;
    }

    if (msg.hasOwnProperty("error")) {
      if (Array.isArray(msg.errors)) {
        const msgs = msg.errors.map((item) =>
          item.msg ? item.msg : item.toString()
        );
        msg = msgs.join("\n");
      }
    }

    if (msg.hasOwnProperty("errors")) {
      if (Array.isArray(msg.errors)) {
        const msgs = msg.errors.map((item) => {
          if (item.hasOwnProperty("msg")) {
            return item.msg;
          } else if (item.hasOwnProperty("message")) {
            return item.message;
          } else {
            item.toString();
          }
        });
        msg = msgs.join("\n");
      }
    }
  }

  return (
    <div className={classNames.join(" ")}>
      {children}
      {typeof msg == "object" ? (
        <div>
          {Object.keys(msg).map((k) => (
            <div key={k}>{msg[k]}</div>
          ))}
        </div>
      ) : (
        <div>{msg}</div>
      )}
    </div>
  );
};

export default Message;
