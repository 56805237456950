import {Auth} from 'aws-amplify'
import React from 'react'
import styled from 'styled-components'
import SocialLoginContainer from './SocialLoginContainer'

const Container = styled.div`
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  margin-top: 24px;
  padding: 0 8px;
  width: 100%;
`

const Img = styled.img`
  display: block;
`

const Text = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
  margin-left: 24px;
`

const SignInWithApple = () => {
  return (
    <SocialLoginContainer
      ctnId={"button-apple-login"}
      iconSrc={"/matomete-solar/img/apple.png"}
      onClick={e => Auth.federatedSignIn({provider: 'SignInWithApple'})}
      provider={"Apple"}
    />
  )
}

export default SignInWithApple
