import React from "react";

const SummaryItem = ({ color, label, src, styles, unit, value }) => {
  return (
    <div className={`${styles.summary} ${styles[color]}`}>
      <div className={styles.summaryIcon}>
        <img className={styles.summaryIconKwhPlus} src={src} />
      </div>
      <div className={styles.summaryResults}>
        <div className={styles.summaryResultsText}>
          <div>{label}</div>
          <div className={styles.summaryResultsTextNumber}>
            {value}
            <span className={styles.summaryResultsTextUnit}>{unit}</span>
          </div>
        </div>
        <div className={styles.summaryResultsBar}>
          <div
            className={styles.summaryResultsBarInner}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default function Summary({ data, styles }) {
  const [powerAvg, setPowerAvg] = React.useState(0);
  const [powerSum, setPowerSum] = React.useState(0);
  const [revenueAvg, setRevenueAvg] = React.useState(0);
  const [revenueSum, setRevenueSum] = React.useState(0);
  const [powerUnit, setPowerUnit] = React.useState("");

  React.useEffect(() => {
    let dataMap = {
      power_a: [],
      power_kw: [],
      power_kwh: [],
      revenue: [],
    };

    data.forEach((item) => {
      Object.keys(dataMap).forEach((k) => {
        const v = item[k];
        if (v != null) {
          dataMap[k].push(v);
        }
      });
    });

    if (dataMap["power_kwh"].length > 0) {
      const s = dataMap["power_kwh"].reduce((sum, el) => sum + (el || 0), 0);
      setPowerAvg(s / dataMap["power_kwh"].length);
      setPowerSum(s);
      setPowerUnit("kWh");
    } else if (dataMap["power_kw"].length > 0) {
      const s = dataMap["power_kw"].reduce((sum, el) => sum + (el || 0), 0);
      setPowerAvg(s / dataMap["power_kw"].length);
      setPowerUnit("kW");
    } else if (dataMap["power_a"].length > 0) {
      const s = dataMap["power_a"].reduce((sum, el) => sum + (el || 0), 0);
      setPowerAvg(s / dataMap["power_a"].length);
      setPowerUnit("A");
    }

    if (dataMap["revenue"].length > 0) {
      const s = dataMap["revenue"].reduce((sum, el) => sum + (el || 0), 0);
      setRevenueAvg(s / dataMap["revenue"].length);
      setRevenueSum(s);
    }
  }, [data]);

  return (
    <div className={styles.summaries}>
      {powerSum > 0 && (
        <SummaryItem
          color="blue"
          label="発電量_合計"
          src="/matomete-solar/img/icon-summary-kwh-sum.png"
          styles={styles}
          unit={powerUnit}
          value={(Math.floor(powerSum * 100) / 100).toLocaleString()}
        />
      )}
      {powerAvg > 0 && (
        <SummaryItem
          color="blue"
          label="発電量_平均"
          src="/matomete-solar/img/icon-summary-kwh-avg.png"
          styles={styles}
          unit={powerUnit}
          value={(Math.floor(powerAvg * 100) / 100).toLocaleString()}
        />
      )}
      {revenueSum > 0 && (
        <SummaryItem
          color="green"
          label="収入_合計"
          src="/matomete-solar/img/icon-summary-revenue-sum.png"
          styles={styles}
          unit="円"
          value={Math.floor(revenueSum).toLocaleString()}
        />
      )}
      {revenueAvg > 0 && (
        <SummaryItem
          color="green"
          label="収入_平均"
          src="/matomete-solar/img/icon-summary-revenue-avg.png"
          styles={styles}
          unit="円"
          value={Math.floor(revenueAvg).toLocaleString()}
        />
      )}
    </div>
  );
}
