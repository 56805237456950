import {Auth} from 'aws-amplify'
import jwt_decode from 'jwt-decode'
import React from 'react'
import styled, {useTheme} from 'styled-components'
import SocialLoginContainer from './SocialLoginContainer'

const SignInWithGoogle = () => {
  // const createScript = () => {
  //   // load the Google SDK
  //   const script = document.createElement('script')
  //   script.src = 'https://accounts.google.com/gsi/client'
  //   script.async = true
  //   script.onload = handleLoadScript
  //   document.body.appendChild(script)
  // }

  // const handleCredentialResponse = async r => {
  //   console.log(r)
  //   const d = jwt_decode(r.credential)

  //   console.log(d)
  //   const user = {email: d.email}

  //   const credentials = await Auth.federatedSignIn(
  //     'google', {
  //       token: r.credential,
  //       expires_at: d.exp
  //     },
  //     user
  //   )
  //   console.log(credentials)

  //   const u = await Auth.currentAuthenticatedUser()
  //   console.log(u)
  // }

  // const handleLoadScript = () => {
  //   google.accounts.id.initialize({
  //     client_id: '127555243198-qblomhr358j0c8mupnvnent0itkmb7l4.apps.googleusercontent.com',
  //     callback: handleCredentialResponse
  //   })
  //   google.accounts.id.renderButton(
  //     document.getElementById('button-google-login'), {
  //       theme: 'outline',
  //       width: 400
  //     }
  //   )
  //   // google.accounts.id.prompt() // also display the One Tap dialog
  // }

  // useEffect(() => {
  //   const ga = window.google && window.google.accounts && window.google.accounts.auth2 && window.google.accounts.id

  //   if (!ga) createScript()
  // }, [])

  return (
    <SocialLoginContainer
      ctnId={"button-google-login"}
      iconSrc={"/matomete-solar/img/google.png"}
      onClick={e => Auth.federatedSignIn({provider: 'Google'})}
      provider={"Google"}
    />
  )
}

export default SignInWithGoogle
