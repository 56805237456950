import React from 'react'
import styles from '../../styles/ui/Policy.less'

const Policy = () => {
  return (
    <div className={styles.policy}>
      <div className={styles.policyHeadline}>「まとめてソーラー」利用規約</div>

      <p>
        本規約は、エレビスタ株式会社（以下「当社」といいます）が提供する、太陽光発電所情報管理サービス「まとめてソーラー」（以下「本サービス」といいます）の利用条件を定めたものです。<br/>
        本サービスの利用開始にあたっては、本規約の内容を事前に確認の上、ご承諾いただくものといたします。具体的には、利用者様にて、本規約の「同意」ボタンを押し、本サービスの利用を開始した時点において、当該利用者と当社との間で本規約に基づく本サービスの利用契約（以下「利用契約」といいます）が成立するものとします（以下当該利用者を「利用者」といいます）。
      </p>

      <p>
        第1条（サービス内容）<br/>
        本サービスは、利用者が所有する太陽光発電所（以下「所有発電所」といいます）の発電実績等の情報を一元管理できるサービスです。当社は利用者から提供を受けた情報を蓄積、加工、編集等の上、本サービス上に表示いたします。
      </p>

      <p>
        第2条（情報提供）<br/>
        本サービスの利用にあたり、利用者は以下の情報を当社に提供します。<br/>
        利用者の基本情報（住所、氏名（社名）、電話番号等）<br/>
        所有発電所の情報<br/>
        設備基本情報（所在地、名称、FIT単価等）<br/>
        上記の他、当社がサービスの中で求める登録情報<br/>
        その他、本サービス提供のため当社が必要と認める情報
      </p>

      <p>
        第3条（利用期間）<br/>
        本サービスの利用期間は、利用契約が成立した日から1年間とします。ただし、利用期間満了日の1か月前までに、利用者から当社に対して、契約を終了する旨の申し出がなかった場合には、同一の内容及び条件で、利用契約が1年間更新されるものとし、その後も同様とします。<br/>
        利用者は、当社の指定する方法により通知することにより、利用契約をいつでも自由に解除することができるものとします。
      </p>

      <p>
        第4条（了承事項）<br/>
        利用者は、本サービスの利用にあたり以下の事項を確認の上、承諾します。<br/>
        本サービスの利用ならびに利用に伴う自らの行為（情報提供、登録、閲覧、削除または送信等）および本サービスにて提供された情報に基づく自らの行為の結果については、利用者にて一切の責任を負うこと。<br/>
        第2条に基づく当社への情報提供および情報登録（遠隔監視ツール・システム等の利用権限付与等を含む）は、利用者自身が本サービスを利用するために自らの意思で行う行為であり、利用者は、これらの行為により生ずる結果の全てについて責任を負うこと。また、当社は、これらの行為により生ずる結果について一切責任を負わないこと。<br/>
        利用者から提供された情報に基づき、当社にて遠隔監視ツール・システム等へログインを行い、情報確認、収集、利用を行うこと。<br/>
        第6条もしくは第7条に規定する事情による本サービスの提供の中断、停止、終了等があること。またこの場合に当社が一切の責任を負わず、当社に対し損害賠償の請求を行わないこと。<br/>
        本サービスの利用に関して利用者に生じた、間接損害、派生損害、逸失利益、特別の事情から生じた損害（予見の有無を問いません）、事業機会の損失およびデータの滅失、損壊、漏えいなどによる損害について、当社が一切の責任を負わないこと。<br/>
        本サービスの利用または提供した情報に関して、当社が第三者からクレーム等を受けた場合には、利用者にて対応し、自己の責任と費用でこれを解決すること。もし当社に損害や不利益を生じさせた場合には、当社に対して賠償すること。<br/>
        本サービスの利用にあたり、利用者が第三者に損害や不利益を生じさせた場合や、第三者からクレーム等を受けた場合には、自己の責任と費用でこれを解決すること。<br/>
        当社が提供を受けた情報（個人情報を除く）を本サービスの利用向上や再生可能エネルギーの有効活用のために利用すること。また当該目的のために第三者に情報を提供すること。<br/>
        当社が提供を受けた情報及び派生して取得した情報に基づき、利用者に対して所有発電所の保守や改修、売買、買替え等のサービス提案を行うこと。また当該目的のために当社が委託する第三者に利用者の情報提供を行うこと。<br/>
        本サービスの利用にあたり提供した一切の情報に関し、当社が返還または破棄する義務を負わないこと。
      </p>

      <p>
        第5条（禁止事項）<br/>
        利用者は、本サービスの利用にあたり、以下に定める行為を行ってはなりません。当社にて、利用者が以下の事項のいずれかに抵触すると判断した場合には、利用者に対する本サービスの提供を停止させていただくことがあります。<br/>
        法令または本利用規約もしくは公序良俗に違反する行為、または違反するおそれのある行為<br/>
        犯罪行為に関連する行為<br/>
        故意過失を問わず当社サービスの提供に支障をきたす行為、またはおそれのある行為<br/>
        当社サービスの運営を妨害する行為<br/>
        利用者以外の第三者に本サービスを利用させる行為<br/>
        不正に第三者に不利益もしくは損害を与える行為<br/>
        不正に情報を取得、作成、改変、譲渡等する行為<br/>
        第三者のプライバシーを侵害する行為<br/>
        不正アクセスをする行為、またはこれを試みる行為<br/>
        本サービス及び記載情報に関する無断転載、改変、二次的著作物の作成、リバースエンジニアリング等の行為<br/>
        当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為<br/>
        その他、当社が不適当と判断する行為
      </p>

      <p>
        第6条（本サービスの中断等）<br/>
        当社は、以下のいずれかの事由に該当する場合、利用者に事前に通知することなく本サービスの一部または全部を一時中断または停止することがあります。<br/>
        本サービスにかかるシステムの保守点検や更新を行う場合（緊急に限りません）<br/>
        本サービスの提供に必要な機器やサーバーの故障、ネットワーク回線の不具合等、やむを得ない事由により本サービスの提供が困難となった場合<br/>
        利用者が前条に掲げる禁止事項のいずれかに該当した場合<br/>
        地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合<br/>
        その他、当社業務の遂行上支障があるなど、当社が本サービスの提供が困難と判断した場合
      </p>

      <p>
        第7条（本サービスの終了等）<br/>
        当社は、1か月前までに利用者に通知することにより、本サービスの一部または全部を終了することができるものとします。ただし、不可抗力などにより通知が不可能な場合には、通知をすることなく終了させることができるものとします。<br/>
        2.当社は、利用者に通知することなく、いつでも本サービスの内容を変更することができるものとします。但し、利用者の便益を考慮して、当社が重要な変更と判断する場合には、利用者に事前に通知を行い、また必要に応じて変更の承諾を求めることとします。<br/>
        3.当社が前項但し書のとおりサービスの変更に伴い利用者の承諾を求める場合、当社は、承諾をしない利用者のサービス利用を停止し、アカウントを削除することができるものとします。当社から一定の期間を定めた通知後、返答がない利用者についても同様とします。<br/>
        4.当社は、本サービスの終了、本サービス提供の中断または停止、サービスの変更およびサービス利用の停止措置により、利用者または第三者が被ったいかなる不利益または損害について、一切の責任を負わないものとします。
      </p>

      <p>
        第8条（免責事項）<br/>
        当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。<br/>
        2.当社は、本サービスに起因して利用者に生じたあらゆる損害について一切の責任を負いません。
      </p>

      <p>
        第9条（権利譲渡等の禁止）<br/>
        利用者は、当社の書面による事前の同意無しに、利用者の権利や地位もしくは義務を第三者に譲渡もしくは貸与し、承継させ、または第三者のために担保に供することはできません。
      </p>

      <p>
        第10条（業務委託）<br/>
        当社は、本サービスに関する業務の全部もしくは一部を、第三者に委託することができるものとします。
      </p>

      <p>
        第11条（利用規約の変更）<br/>
        当社は、必要と判断した場合には、利用者への通知および承諾を得ることなく、いつでも本利用規約を変更することができるものとします。なお、本利用規約の変更後に本サービスにログインした場合には、当該利用者は、変更後の規約に同意したものとみなします。
      </p>

      <p>
        第12条（知的財産権等）<br/>
        本サービスに関する著作権等の知的財産権、本サービスに関して利用者より提供を受けたデータに関する権利（著作権法27条および28条に規定される権利を含みます）、その他本サービスに関する有形もしくは無形の権利および情報に関する権利の一切（以下「知的財産権等」という。）は、当社または当社が許諾した者に帰属します。<br/>
        2．利用者は、本サービスに関する知的財産権等について何らの権利も有しないことを確認承諾します。
      </p>

      <p>
        第13条（個人情報の取り扱い）<br/>
        当社は、利用者から提供を受けた個人情報について、個人情報保護法その他の適用法令および当社の個人情報保護方針にしたがい、適切に管理します。
      </p>

      <p>
        第14条（反社会的勢力の排除）<br/>
        利用者は、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等その他これらに準ずる者（以下これらを「暴力団員等」という）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを表明し、保証するものとします。<br/>
        暴力団員等が経営を支配していると認められる関係を有すること<br/>
        暴力団員等が経営に実質的に関与していると認められる関係を有すること<br/>
        自己又は第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること<br/>
        暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること<br/>
        役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること<br/>
        2.利用者は、自らまたは第三者を利用して次の各号のいずれかに該当する行為を行ってはならないものとします。<br/>
        暴力的な要求行為<br/>
        法的な責任を超えた不当な要求行為<br/>
        取引に関して、脅迫的な言動をし、または暴力を用いる行為<br/>
        風説を流布し、偽計を用い又は威力を用いて当社その他第三者の信用を毀損し、または当社その他第三者の業務を妨害する行為<br/>
        その他前各号に準ずる行為<br/>
        当社は、利用者が第1項のいずれかに違反すると疑われる合理的な事情がある場合には、当該違反の有無につき、利用者の調査を行うことができ、利用者はこれに協力するものとするものとします。また、利用者は、自己が、第1項のいずれかに違反し、またはそのおそれがあることが判明した場合には、当社に対し、直ちにその旨を通知するものとするとします。<br/>
        当社は、利用者が前三項のいずれかに違反した場合は、通知または催告等何らの手続を要しないで直ちに利用契約を解除することができるものとします。<br/>
        当社は、前項に基づく解除により利用者が被った損害につき、一切の義務および責任を負わないものとします。
      </p>

      <p>
        第15条（準拠法・裁判管轄）<br/>
        本規約および本サービスの効力や履行、解釈にあたっては、日本法を準拠法とします。<br/>
        2.本規約または本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を第一審の専属的合意管轄裁判所とします。
      </p>

      <p>
        第16条（協議）<br/>
        本規約に定めのない事項および本規約の解釈について疑義を生じた事項は、当社および利用者の間で誠実に協議の上、解決するものとします。
      </p>

      <p style={{textAlign: 'right'}}>以上</p>
    </div>
  )
}

export default Policy
